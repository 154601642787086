import React from 'react';
import { Navigate} from 'react-router-dom';


const PublicAdmin=({token, children}:any)=>{   
    if (token) {
        return <Navigate to="/Dashboard" replace />;
      }
    return children;
}

export default PublicAdmin;