import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../components/customModal';
import ILaboratoires from '../../../interfaces/ILaboratoires';
import { LaboratoireService } from './../../../services/laboratoireService';
import Caroussel from './../../../components/caroussel';

const AllLaboratoires = () => {
  const history = useNavigate();
  const[data, setData] = useState([]);
  const[isLoad,setLoader]=useState(false);
  const[config,setConfig]=useState({ "display":"none"} as any);
  const[toogleChange, setToggele] = useState<boolean>(false);
  const[search,setSearch]=useState("");
  let baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://api.medindexapps.com/public' ;

  function openModalDelete(laboratoire:ILaboratoires){
    let configs = {
        "type":"confirmation",
        "title":"Attention",
        "action":"SUPRESSION",
        "msgConfirm":"Ce laboratoire et les examens liés vont etres definitivements supprimé !",
        "close": ()=>{closeModal()},
        "confirm": ()=>{deleteData(laboratoire.id)},
        "display":"",
    };
    setConfig(configs);
  }

  function openModalDetail(laboratoire:ILaboratoires){
    let configs = {
        "type":"",
        "title":"Detail du laboratoire : "+laboratoire.name,
        "action":"DETAIL",
        "content":viewDetail(laboratoire),
        "close": ()=>{closeModal()},
        "display":"",
    };
    setConfig(configs);
  }

 
  const deleteData = (laboratoireId:any) => {
      LaboratoireService.deleteLaboratoire(laboratoireId).then(
        (response)=>{
          if(response.status === 200){
            setToggele((prev:boolean) => prev = !prev)
          }else{
            alert('Impossible de supprimer cette pharmacie une erreur c\'est produite');
          }
        }
      ).catch(
        (error)=>{
          alert('erreur systeme');
        }
      )
      closeModal()
  }
  //useMemo()
  function closeModal(){
      console.log(config);
      setConfig({ "display":"none"});
  }

  function navigate(to:string){
    history(to);
  }

  useEffect(() => {
    console.log('is loader')
    getLaboratoires();
    // eslint-disable-next-line
  }, [toogleChange]);

  const getLaboratoires = () =>{
    setLoader(true);
    LaboratoireService.getLaboratoires().then(
        (response)=>{
            setData(response.data);
            setLoader(false);
        }
    ).catch(
        (error: any)=>{
            console.log(error);
            setLoader(true);
        }
    )
  }

  function viewDetail(labo: ILaboratoires){
    let pictures:any;
    if(labo.pictures != null){
      pictures = labo.pictures.split(";");
    }
    
    console.log(labo.allwayOpen);
    return (
      <>
        <Caroussel images={pictures}></Caroussel>
        <div className="grid grid-cols-2 gap-2 ">
          <div className='flex border-2 p-2'>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <div>
              <h2 className='font-bold'>{labo.ville}</h2>
              <p className='text-sm'>{labo.emplacement}</p>
            </div>
          </div>

          <div className='flex border-2 p-2 '>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>
            <div>
              <h2 className='font-bold'>Contact</h2>
              <p className='text-sm'>{labo.contact}</p>
            </div>
          </div>

          <div className='flex border-2 p-2 '>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
            </svg>
            <div>
              <h2 className='font-bold'>Site web</h2>
              <a className='text-sm' href={labo.siteWeb} target={'_blank'}>{labo.siteWeb}</a>
            </div>
          </div>

          <div className='flex border-2 p-2 '>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <div>
              <h2 className='font-bold'>Ouverture</h2>
              <p className='text-sm'>{labo.allwayOpen === 1? "24h/24":labo.ouverture+"-"+labo.fermeture}</p>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="bg-white p-3 rounded-md w-full">
          <h1 className="text-2xl border-b-2">Laboratoires</h1>
          <p className="text-sm text-slate-500 mt-2">Listes des laboratoires disponibles</p>
      </div>

      {/* barre de recherche et filtre */}
      <div className="flex flex-wrap justify-between min-w-full bg-white p-3 rounded-md my-3">
          <div className="flex items-center border-2 hover:border-blue-500 hover:text-blue-500 focus-within:border-blue-500 rounded-md focus-within:text-blue-500 text-slate-400 my-1 md:w-auto w-full">
              <input type="text" className="form-control-search rounded-md mt-0 text-slate-400 w-full "  onChange={(e)=>setSearch(e.target.value)} placeholder="Rechecher de laboratoire" id="" />
              <button className="px-2 ">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
              </button>
          </div>  

          <div className="flex justify-between my-1 md:w-auto w-full">
              <button className="px-2 " title="Tri en croissant">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
                  </svg>
              </button>
              <button className="px-2 " title="Tri en décroissant">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
                  </svg>
              </button>
              
              <button className="px-2 " title="Ajouter un medicament" onClick={()=>navigate("Add")}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
              </button>
          </div>              
        </div>
        {/* barre de recherche fin */}

        <div className={`${isLoad? "flex flex-col justify-center items-center h-2/3 w-full": "lg:grid lg:grid-cols-4 lg:gap-4 mt-3"}`}>

        {
          isLoad && 
          <>
              
              <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-blue-400 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
              </svg>
              
              
              <p className="mt-3">Chargement des Labiratoires ...</p>
          </>
            
        }
        {
          !isLoad && data.length > 0 &&
          data.filter((laboratoire:ILaboratoires)=>laboratoire.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
          .map((laboratoire:ILaboratoires,i:number) => {
              return(
                <div key={i} className="w-full border-2 rounded-md bg-white">
                  <img className='rounded-t-md ' src={baseUrl+laboratoire.urlLogo} alt="centre pasteur"  />
                  <div className="bg-white rounded-b-md p-2">
                    <h1 className='font-bold mb-2 text-xl mx-2'>{laboratoire.name} </h1>
                    <p className='mx-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mb-2 mr-2 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg> 
                      {laboratoire.emplacement} 
                    </p>
                    <p className='mx-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mb-2 mr-2 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                      </svg> 
                      {laboratoire.contact} 
                    </p>
                    <div className="flex justify-end">
                      <button className='mb-2 text-blue-500 mx-2' title='Detail du laboratoire' onClick={()=>{openModalDetail(laboratoire)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                      </button>
                      <button className='mb-2 text-green-500 mx-2' onClick={()=>{navigate("Edit/"+laboratoire.id)}} title='Modifier le laboratoire'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                      </button>
                      <button className='mb-2 text-gray-500 mx-2' title='Examen de ce laboratoire' onClick={()=>{navigate(laboratoire.id+"/Examen")}}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                        </svg>
                      </button>
                      <button className='mb-2 text-red-500 mx-2' onClick={()=>{openModalDelete(laboratoire)}} title='Supprimer le laboratoire'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                      </button>
                    </div>
                    
                  </div>
                </div>
              );
          })
              
        }
        <CustomModal Classe='lg:w-1/4 w-3/4' config={config}/>    
        </div>
    </>
  )
}

export {AllLaboratoires}
