import React from 'react'
import { useState } from 'react';

const Caroussel = ({images}:any) => {
  const[activeIndex,setActiveIndex] = useState(0);  

//   setInterval(()=>{
//       if(activeIndex < images.length - 1){
//         setActiveIndex(activeIndex + 1);
//       }else{
//           setActiveIndex(0)
//       }
//       console.log(activeIndex);
//   },10000);

  function changeIndex(action:any){
      if (action === "next") {
        if(activeIndex < images.length - 1){
            setActiveIndex(activeIndex + 1);
        }else{
            setActiveIndex(0)
        }
      }else{
        if(activeIndex === 0){
            setActiveIndex(images.length - 1);
        }else{
            setActiveIndex(activeIndex - 1)
        }
      }
  }

  let baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://api.medindexapps.com/public' ;
  return (
    <div className='caroussel relative mb-2'>
        {
            images != null &&
            <div className="inner " style={{transform: `translateX(-${activeIndex * 100}%)`}}>
                {
                    images.map((elt:any,i :number) => {
                        return <img key={i} className='w-full h-full caroussel-item' src={baseUrl+elt} alt="test" />
                    })
                }
                
            </div>
        }
        
        <div className='absolute h-full w-full top-0 flex justify-between items-center z-1000'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 shadow-md shadow-black bg-slate-300 rounded-full p-2 cursor-pointer" onClick={()=>changeIndex("prev")} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 shadow-md shadow-black bg-slate-300 rounded-full p-2 cursor-pointer" onClick={()=>changeIndex("next")} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                </svg>
            </div>
    </div>
  )
}

export default Caroussel
