import { ApiProvider } from "../providers/apiProvider";

export const PersonnelService ={
    getProfessionnal,
    deleteProfessionnal,
    activeProfessionnal,
    deactiveProfessionnal,
    savePersonnel
};


async function getProfessionnal(){
    let res = await ApiProvider.get("all-professionnal");
    return res;
}

async function savePersonnel(personnel: any) {
    let res = await ApiProvider.post("create-professionnal", personnel);
    return res;
}

async function deleteProfessionnal(prodessionnelId:any){
    let res = await ApiProvider.get("delete-account/" + prodessionnelId);
    return res;
}

async function activeProfessionnal(prodessionnelId:any,type:string){
    let data = {
        "type":type
    };
    let res = await ApiProvider.post("activate-account/" + prodessionnelId,data);
    return res;
}

async function deactiveProfessionnal(prodessionnelId:any){
    let res = await ApiProvider.get("desactivate-account/" + prodessionnelId);
    return res;
}