import { FicheLaboratoireModel } from "../model/ficheLaboratoireModel";
import { ApiProvider } from "../providers/apiProvider";


export const PharmacoService = {
    getParmacos
};

async function getParmacos(){
    let res = await ApiProvider.get("all-pharmacovigilence");
    return res;
}