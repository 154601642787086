import React from 'react';
import BGAUTH from "../../assets/img/bg-auth.png";
import { useState } from 'react';
import { AuthService } from '../../services/authService';
import { UserModel } from '../../model/userModel';
import { useNavigate } from 'react-router-dom';

const Login = () => {

  const history = useNavigate();
  const[formData,setFormDate] = useState({} as any);
  const[error,setError] = useState({} as any);

  function handlerInput(e:any){
    const {name, value}:any = e.target;

    switch (name) {
      case "username":
        setFormDate({...formData,username:value});
        break;
      case "password":
        setFormDate({...formData,password:value});
        break;
     
      default:
        throw new Error("input not allow");
    }
  }

  const login = (e:any) =>{
    e.preventDefault();

    if (formData.username == null || formData.username == "" || formData.password == null || formData.password == "") {
      setError({isError: true,msg:"Identifiant ou mot de passe pas renseigné"});
    }else{
      let user: UserModel = new UserModel(formData);
      AuthService.login(user).then(
        (response)=>{
          if(response.status === 200){
            if(response.data.success === true){
              console.log("authentification ok");
              localStorage.setItem("token",response.data.token);
              window.location.reload();
            }else{
              setError({isError: true,msg:"Identifiant ou mot de passe incorrect"});
            }
          }else{
            setError({isError: true,msg:"une erreur c'est produite"});
          }
        }
      ).catch(
        (error)=>{
          setError({isError: true,msg:"erreur systeme"});
          throw new Error(error);
          
        }
      )
    }
  }

  return (
    <div className='md:flex md:flex-row h-screen' style={{backgroundImage:"url("+BGAUTH+")",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPosition:"center"}}>
      <div className="basis-3/4"></div>
      <div className="basis-1/4 h-full bg-gradient-to-b from-blue-600 via-blue-500 to-blue-300 flex flex-col justify-center items-center px-12">
          <div className="w-full mb-10 uppercase px-4">
            <h1 className='font-medium text-2xl w-full text-white '>Bienvenus sur medindex !</h1>
            <span className='w-full text-slate-200  text-sm'> CONNEXION</span>
          </div>
        
          {
            error.isError && 
            <small className='text-red-400 mb-2 shadow-xl drop-shadow-md' >{error.msg}</small>
          }
          
          <form onSubmit={(e:any)=>login(e)} method='POST' className='w-flull px-4' style={{width:"100%"}}>
            <label className="block w-flull mb-3 uppercase">
              <span className="font-medium text-base text-white" >
                Identifiant :
              </span>
              <input type="text" name="username" required className="peer form-control-auth shadow-xl drop-shadow-md " onChange={(e)=>{handlerInput(e)}} placeholder="Votre identifiant" />
            </label>

            <label className="block w-flull uppercase">
              <span className="font-medium text-base text-white" >
                Mot de passe :
              </span>
              <input type="password" name="password" required className="peer form-control-auth shadow-xl drop-shadow-md" onChange={(e)=>{handlerInput(e)}} placeholder="Votre mot de passe" />
            </label>
            <div className='float-right mb-3 mt-'>
              <a className='text-xs text-slate-100' href="#">Mot de passe oublié ?</a>
            </div>

            <button className='text-blue-500 font-medium w-full outline-2 shadow-xl drop-shadow-md py-2 mt-2 bg-slate-50 rounded-md hover:bg-slate-100'>CONNEXION</button>
          </form>

          <small className='mt-3 mb-2 text-slate-100'>Suivez nous sur les reseaux sociaux</small>
          <div className="flex justtify-center">
            <svg xmlns="http://www.w3.org/2000/svg" style={{fill:"white", width:25}} className="mx-2 shadow-xl drop-shadow-md cursor-pointer" viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" style={{fill:"white", width:25}} className="mx-2 shadow-xl drop-shadow-md cursor-pointer" viewBox="0 0 448 512"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM164 356c-55.3 0-100-44.7-100-100s44.7-100 100-100c27 0 49.5 9.8 67 26.2l-27.1 26.1c-7.4-7.1-20.3-15.4-39.8-15.4-34.1 0-61.9 28.2-61.9 63.2 0 34.9 27.8 63.2 61.9 63.2 39.6 0 54.4-28.5 56.8-43.1H164v-34.4h94.4c1 5 1.6 10.1 1.6 16.6 0 57.1-38.3 97.6-96 97.6zm220-81.8h-29v29h-29.2v-29h-29V245h29v-29H355v29h29v29.2z"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" style={{fill:"white", width:25}} className="mx-2 shadow-xl drop-shadow-md cursor-pointer" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
          </div>
      </div>
    </div>
  )
}

export default Login
