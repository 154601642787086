import React, { useState,useRef, useEffect, useMemo } from 'react';
import { MedicamentService } from '../../../services/medicamentService';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { FileService } from '../../../services/filesServices';

const AddAnonce = () => {
  const history = useNavigate();
  const [steps, setSteppe] = useState({step1:true,step2:false,step3:false});
  const searchInput:any = useRef();
  const[request,setRequest] = useState({} as any);
  const[data, setData] = useState({} as any);
  const[Disciplines, setOptionDiscipline] = useState([] as any);
  const[Classes, setOptionClasse] = useState([]);
  const[SousClasses, setOptionSousClasse] = useState([]);
  const[format, setformat] = useState([]);
  const[codeAct, setcodeAct] = useState([]);
  const[laboratoires, setLaboratoire] = useState([]);
  const[fabricants, setFabricants] = useState([]);
  const[disabledStatus, setDisabledStatus] = useState(true);
  const[load, setLoad] = useState(false);
  const[isValidDure, setIsValidDure] = useState(false);
  const[uniteAnnnonce, setUniteAnnnonce] = useState("");
  const[dureeA, setDureeA] = useState("");
  const [array, setArray] = useState([] as Array<string>);
  const[pictures, setPicture] = useState([]);
  const location = useLocation();

  const {medicamentId} = useParams();
  console.log(medicamentId);

  useEffect(() => {
      console.log('is mount')
      getPropMedicament();
      console.log(request);
      handleValidateForm();
      // eslint-disable-next-line
  }, [request]);

  

  const activeAnnonce = async (e: any) => {
    e.preventDefault();
    setLoad(true);

    var result;
    console.log(disabledStatus);
    if(pictures.length > 0) {
        result = await FileService.uploadLotPictures(pictures, "annonce");
        console.log(result);

        if(result.status == 201) {
            let paths : Array<string> = result.data.paths;
            let urls = paths.join(",");
            var data = {...request, urls: urls, id: medicamentId};
            console.log(data);
            var res = await MedicamentService.saveAnnonce(data);
            console.log(res);
            setDisabledStatus(true);
            setLoad(false);
            history(-1);
            
            if(res.status == 201) {
                setRequest({});
                alert("Enregistré avec succès");
            } else {
                alert(res.data.message);
            }

        } else {
            alert(result.data.message);
        }    
    } else {
        alert("Vous devez charger les images de l'annonce")
    }
    
  }

  const getPropMedicament = () =>{
    MedicamentService.getPropsMedicament().then(
        (response)=>{
            setData(response.data);
            let disciplines = response.data.disciplines.map((elt:any)=>{
              return elt;
            });
            let classes = response.data.classes.map((elt:any)=>{
              return elt;
            });
            let sousClasses = response.data.sousClasses.map((elt:any)=>{
              return elt;
            });
            let formats = response.data.formats.map((elt:any)=>{
              return elt;
            });
            let labos = response.data.laboratoires.map((elt:any)=>{
              return elt;
            });
            let fabric = response.data.fabricants.map((elt:any)=>{
              return elt;
            });
            let codeAtcs = response.data.codeATC.map((elt:any)=>{
              return elt;
            });
            setOptionDiscipline(disciplines);
            setOptionClasse(classes);
            setOptionSousClasse(sousClasses);
            setformat(formats);
            setcodeAct(codeAtcs);
            setLaboratoire(labos);
            setFabricants(fabric);
        }
    ).catch(
        (error: any)=>{
            console.log(error);
        }
    )
}


  const handleValidateForm = () => {
    /*if(request.nom_commercial != undefined && request.dci_principe_actif != undefined && request.forme_galenique_dosage != undefined &&
      request.conditionnement != undefined && request.icone_conditionnement != undefined &&
      request.laboratoire != undefined && request.prix_cession != undefined && request.prix_public != undefined 
    ) {*/
    if(request.duree_annonce != undefined) {
        setDisabledStatus(true);
      
      /*if(request.disponibilite != undefined && request.indications != undefined && request.contre_indications != undefined &&
        request.posologie_et_mode_d_administration != undefined && request.effets_secondaires != undefined 
      ) {
          setDisabledStatus(2);
          setDisabledStatus(3);
          console.log(request);

        if(request.duree_annonce != undefined) {
          setDisabledStatus(3);
        } else {
          setDisabledStatus(2);
        }
      } else {
        setDisabledStatus(1);
      }  */
    } else {
      setDisabledStatus(false);
    }
  }

  async function handlerInput(e:any){
    const {name, value,files}:any = e.target;

    switch (name) {
      case "unite_annonce":
        console.log(value);
        if(value != null && value != "") {
          console.log(value);
          setIsValidDure(true);
          setRequest({...request, ...{unite_annonce: value, duree_annonce:0}});
        } else {
          setIsValidDure(false);
          setRequest({...request, ...{unite_annonce: null, duree_annonce:0}});
        }
        setDureeA("");
        console.log(request);
        break;
      case "duree_annonce":
        let duree;
        let intValue = parseInt(value);
        console.log(typeof intValue)
        if(value != null && value != "") {
          setDureeA(value);
          if(request.unite_annonce == 'heur') {
            duree = intValue
            console.log(intValue)
            setRequest({...request,duree_annonce: duree});
          } else if(request.unite_annonce == 'jour') {
            console.log("jour")
            duree = intValue * 24
            setRequest({...request,duree_annonce: duree});
          } else if(request.unite_annonce == 'semaine') {
            duree = intValue * 24 * 7
            setRequest({...request,duree_annonce: duree});
          }
          console.log(duree);
          
        } else {
          setRequest({...request,duree_annonce:value});
        }
        break;   
      case "annonce_status":
        setRequest({...request,annonce_status:value});
        break;      
      case "is_new":
        setRequest({...request,is_new:value});
        break;
      case "mot_labo":
        setRequest({...request,mot_labo:value});
        break;
      case "images":
        let images:any = [];
        let maxImage = (files.length > 5)? 5: files.length;
        if (files.length > 5) {
          alert("Seulement les 5 premieres images vont être importées ")
        } 
        for (let i = 0; i < maxImage; i++) {
          images.push(files[i]);
        };
        setPicture(images);
        break; 
      case "mot_labo":
        setRequest({...request,mot_labo:value});
        break;     
      default:
        throw new Error("input not allow");
    }

    console.log(request);
    
  }
  
  console.log(disabledStatus);

  return (

    <>
  
      <div className="bg-white py-3 px-6 mb-3 rounded-md w-full">
          <h1 className="text-2xl border-b-2">Médicaments</h1>
          <p className="text-sm text-slate-500 mt-2">Ajouts des médicaments </p>
      </div>
     

      <form>
          <div className="bg-white rounded-md px-4 py-2">
            

          <div className="mt-3 col-span-3 ">
                <h3 className='text-base mb-3'>Marquer comme une annonce</h3>

                <div className="md:grid md:grid-cols-3 md:gap-4 md:grid-flow-row-dense">
                  
                  <label className="block">
                    <span className="form-label-o">
                      Unité de l'annonce
                    </span>
                    <select onChange = {(e)=>handlerInput(e)} name="unite_annonce" className="form-control" id="">
                      <option selected value="">Selectionner une valeur</option>
                      <option value="heur">Heure</option>
                      <option value="jour">Jour</option>
                      <option value="semaine">Semaine</option>
                    </select>
                  </label>

                  <label className="block">
                    <span className="form-label-o">
                      Durée de l'annonces
                    </span>
                    <input onChange = {(e)=>handlerInput(e)} value={dureeA} readOnly={false} type="number" min={0} name="duree_annonce" required className="peer form-control" placeholder="Durée de l'annonces" />
                    <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                      la durée de l'annonces est obligatoires
                    </p>
                  </label>

                  <label className="block">
                    <span className="form-label">
                      Nouvelle annonce ou pas
                    </span>
                    <select onChange = {(e)=>handlerInput(e)} name="is_new" className="form-control" id="">
                      <option selected value="">Selectionner une valeur</option>
                      <option value="Oui">Oui</option>
                      <option value="Non">Non</option>
                    </select>
                  </label>

                  <label className="block">
                    <span className="form-label">
                      Publication de annonce
                    </span>
                    <select onChange = {(e)=>handlerInput(e)} name="annonce_status" className="form-control" id="">
                      <option selected value="">Selectionner une valeur</option>
                      <option value="Oui">Oui</option>
                      <option value="Non">Non</option>
                    </select>
                  </label>

                  <label className="block">
                    <span className="form-label-o">
                      Mot du laboratoire
                    </span>
                    <input onChange = {(e)=>handlerInput(e)} readOnly={false} type="text" min={0} name="mot_labo" className="form-control" placeholder="Mot du laboratoir" />
                    
                  </label>
                </div>
                
                <label className="block mt-2">
                    <span className="form-label">
                      Telecharger les images de l'annonce (max 5)
                    </span>
                    <input type="file" multiple={true} onChange= {(e)=>handlerInput(e)} name="images" id="" />
                </label>
              </div>        


          </div>
          

          <div className="flex pb-3 justify-end  mt-3">

            <button className='rounded-md bg-blue-500 px-2 text-white mx-3 w-32 hover:bg-blue-400' onClick = {(e) => activeAnnonce(e)} disabled = {!disabledStatus} >
                    
                {
                    load &&
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block animate-spin mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                }

                Enregistrer</button>
            
          </div>

      </form>
      
    </>
  )
}

export {AddAnonce}
