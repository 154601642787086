import { ApiProvider } from "../providers/apiProvider";
import { UserModel } from "../model/userModel";


export const AuthService = {
    login,
    getUser,
    logout,
    register,

};

async function login(user:UserModel){
    let res = await ApiProvider.post("login",user);
    return res;
}

async function logout(){
    let res = await ApiProvider.post("logout");
    return res;
}

async function register(user:UserModel){
    let res = await ApiProvider.post("register",user);
    return res;
}

async function getUser(){
    let res = await ApiProvider.post("user-detail");
    return res;
}