import React, { useEffect, useState } from "react";
import CustomModal from "../../../components/customModal";
import { useNavigate } from "react-router-dom";
import { AMPOULES,COLLUTOIRE,COLLYRE,COMPRIMES,FLACON,GELULES,GOUTTES_AURICULAIRES,INHALER,LOTION,NASAL,OVULES,PERFUSION,POT,SACHETS,SERINGUE,SPRAY,SUPPOSITOIRES,TUBE } from '../../../components/Formats';
import { MedicamentService } from "../../../services/medicamentService";
import IMedicament from "../../../interfaces/IMedicaments";

const AllMedicament = () =>{
    const history = useNavigate();
    const[isOpen,setOpen]=useState(false);
    const[config,setConfig]=useState({ "display":"none","defaultType":"medicament",} as any);
    const[toogleChange, setToggele] = useState<boolean>(false);
    const[data, setData] = useState([]);
    const[isLoad,setLoader]=useState(false);
    const[search,setSearch]=useState("");


    useEffect(() => {
        console.log('is loader')
        getMedicaments();
        // getAnnonces();
        // eslint-disable-next-line
    }, [toogleChange]);

   
    function openModalInport(){
        let configs = {
            "type":"popup",
            "title":"Importation des medicaments",
            "action":"IMPORT_MEDICAMENT",
            "close": ()=>{closeModal()},
            "success": ()=>{reloadMedicament()},
            "display":"",
            "defaultType":"Pharmacie",
        };

        setConfig(configs);
        setOpen(false);
    }

    function openModalDetail(medicament:IMedicament){
        let configs = {
            "type":"",
            "title":"Detail sur le medicament :"+medicament.commercial_name,
            "action":"DETAIL",
            "content":viewDetail(medicament),
            "close": ()=>{closeModal()},
            "display":"",
        };
        setConfig(configs);
      }

    function openModalDelete( medicament: IMedicament){
        let configs = {
            "type":"confirmation",
            "title":"Attention",
            "action":"SUPRESSION",
            "msgConfirm": "Vous allez supprimer le \"" + medicament.commercial_name + "\"",
            "close": () => {setConfig({ "display":"none"});},
            "confirm": () => {deleteMedicament(medicament.id)},
            "display":"",
        };

        setConfig(configs);
    }

    // function openModalDeleteAnnonce( annonce: IAnnonce){
    //     let configs = {
    //         "type":"confirmation",
    //         "title":"Attention",
    //         "action":"SUPRESSION",
    //         "msgConfirm": "Vous allez supprimer le \"" + annonce.medicament.commercial_name + "\"",
    //         "close": () => {setConfig({ "display":"none"});},
    //         "confirm": () => {deleteAnnonce(annonce.id)},
    //         "display":"",
    //     };

    //     setConfig(configs);
    // }


    const deleteMedicament = async (medicamentId: any) => {
        var res = await MedicamentService.deleteMedicament(medicamentId);
        setConfig({ "display":"none"});
        
        if(res.status === 200) {
            alert('Supprimé avec succès');
            getMedicaments();
        } else {
            alert('Impossible de supprimer ce médicament. Une erreur s\'est produite ');
        }
    }

    // const deleteAnnonce = async (annonceId: any) => {
    //     var res = await MedicamentService.deleteAnnonce(annonceId);
    //     setConfig({ "display":"none"});
        
    //     if(res.status === 200) {
    //         alert('Supprimé avec succès');
    //         getMedicaments();
    //     } else {
    //         alert('Impossible de supprimer cette annonce. Une erreur s\'est produite ');
    //     }
    // }

    function reloadMedicament(){
        setToggele((prev:boolean) => prev = !prev)
    }
    //useMemo()
    function closeModal(){
        console.log(config);
        setConfig({ "display":"none"});
        let inputFile:any = document.querySelector("#fileXls");
        inputFile.value = "";
        setOpen(false);
    }

    function navigate(to:string){
        history(to);
    }
    
    const getMedicaments = () => {
        setLoader(true);
        MedicamentService.getMedicaments().then(
            (response)=>{
                setData(response.data);
                setLoader(false);
            }
        ).catch(
            (error: any)=>{
                console.log(error);
                setData([]);
                setLoader(true);
            }
        )
    }

    // const getAnnonces = () => {
    //     setLoader(true);
    //     MedicamentService.getAnnonces().then(
    //         (response)=>{
    //             setAnnonceData(response.data);
    //             setLoader(false);
    //         }
    //     ).catch(
    //         (error: any)=>{
    //             console.log(error);
    //             setAnnonceData([]);
    //             setLoader(true);
    //         }
    //     )
    // }

    const formUpdateMedicament = (medicament: any) => {
        history('update',{state:medicament});
    }

    function getIcons(icon:string){
        switch (icon) {
            case "COMPRIMES":
                return COMPRIMES;
            case "AMPOULES":
                return AMPOULES;
            case "SERINGUE":
                return SERINGUE;
            case "FLACON":
                return FLACON;
            case "TUBE":
                return TUBE;
            case "GELULES":
                return GELULES;
            case "COLLUTOIRE":
                return COLLUTOIRE;
            case "OVULES":
                return OVULES;
            case "PERFUSION":
                return PERFUSION;
            case "SACHETS":
                return SACHETS;
            case "INHALER":
                return INHALER;
            case "NASAL":
                return NASAL;
            case "GOUTTES AURICULAIRES":
                return GOUTTES_AURICULAIRES;
            case "COLLYRE":
                return COLLYRE;
            case "SPRAY":
                return SPRAY;
            case "LOTION":
                return LOTION;
            case "POT":
                return POT;
            case "COMPRIM'eS":
                return COMPRIMES;
            case "AMPOULE BUVABLE":
                return AMPOULES;
            case "G'eLULES":
                return GELULES;
            case "FLACON SIROP":
                return FLACON;
            case "COMPRIM'e EFFERVESCENT":
                return COMPRIMES;
            case "SERINGUE AMPOULE":
                return SERINGUE;
            case "SERINGUE FLACON":
                return SERINGUE;
            default:
                return COMPRIMES;
        }
    }

    function viewDetail(medox: IMedicament){
        return (
            <div className="p-2 max-h-80 md:max-h-100 overflow-y-auto">
                <div className="mb-4">
                    <h2 className="text-lg font-medium">Principe actif</h2>
                    <p className="text-sm text-gray-500">{medox.dci}</p>
                </div>

                <div className="mb-4">
                    <h2 className="text-lg font-medium">Fabricant</h2>
                    <p className="text-sm text-gray-500">{medox.Fabricant}</p>
                </div>
                
                <div className="md:grid md:grid-cols-3 md:gap-4 mb-2">
                    <div className="mb-2">
                        <h2 className="text-lg font-medium">Discipline</h2>
                        <p className="text-sm text-gray-500">{medox.Discipline}</p>
                    </div>

                    <div className="mb-2">
                        <h2 className="text-lg font-medium">Conditionnement</h2>
                        <p className="text-sm text-gray-500">{medox.conditionnement}</p>
                    </div>

                    <div className="mb-2">
                        <h2 className="text-lg font-medium">Format</h2>
                        <p className="text-sm text-gray-500">{medox.Format === null?"-/-":medox.Format }</p>
                    </div>
                   
                </div>

                <div className="mb-4">
                    <h2 className="text-lg font-medium">Classe medicamenteuse</h2>
                    <p className="text-sm text-gray-500">{medox.classe_medicamenteuse === null?"-/-":medox.classe_medicamenteuse}</p>
                </div>

                <div className="md:grid md:grid-cols-3 md:gap-4 mb-2">
                    <div className="mb-2">
                        <h2 className="text-lg font-medium">Code ATC</h2>
                        <p className="text-sm text-gray-500">{medox.CodeAtc === null?"-/-":medox.CodeAtc}</p>
                    </div>

                    <div className="mb-2">
                        <h2 className="text-lg font-medium">Classe</h2>
                        <p className="text-sm text-gray-500">{medox.Classe === null?"-/-":medox.Classe}</p>
                    </div>

                    <div className="mb-2">
                        <h2 className="text-lg font-medium">Sous classe</h2>
                        <p className="text-sm text-gray-500">{medox.sousClasse === null?"-/-":medox.sousClasse}</p>
                    </div>
                   
                </div>

                <div className="mb-4">
                    <h2 className="text-lg font-medium">Indication</h2>
                    <p className="text-sm text-gray-500">{medox.indication === null? "-/-":medox.indication}</p>
                </div>

                <div className="mb-4">
                    <h2 className="text-lg font-medium">Contre indication</h2>
                    <p className="text-sm text-gray-500">{medox.contre_indication === null?"-/-":medox.contre_indication}</p>
                </div>

                <div className="mb-4">
                    <h2 className="text-lg font-medium">Posologie</h2>
                    <p className="text-sm text-gray-500">{medox.posologie === null?"-/-":medox.posologie}</p>
                </div>

                <div className="mb-4">
                    <h2 className="text-lg font-medium">Interaction medicamenteuse</h2>
                    <p className="text-sm text-gray-500">{medox.interation_medoc === null?"-/-":medox.interation_medoc}</p>
                </div>

                <div className="mb-4">
                    <h2 className="text-lg font-medium">Message de grossesse</h2>
                    <p className="text-sm text-gray-500">{medox.message_grosses_id === null?"-/-":medox.message_grosses_id}</p>
                </div>
            </div>
          );
    }
    

    return (
        <>
            
            <div className="bg-white p-3 rounded-md w-full">
                <h1 className="text-2xl border-b-2">Médicaments</h1>
                <p className="text-sm text-slate-500 mt-2">Liste des médicaments disponible</p>
            </div>

            
           
            {/* barre de recherche et filtre */}
            <div className="flex flex-wrap justify-between min-w-full bg-white p-3 rounded-md my-3">
                <div className="flex items-center border-2 hover:border-blue-500 hover:text-blue-500 focus-within:border-blue-500 rounded-md focus-within:text-blue-500 text-slate-400 my-1 md:w-auto w-full">
                    <input type="text" className="form-control-search rounded-md mt-0 text-slate-400 w-full " onChange={(e)=>setSearch(e.target.value)} placeholder="Rechecher de medicament" id="" />
                    <button className="px-2 ">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </button>
                </div>  

                <div className="flex justify-between my-1 md:w-auto w-full">
                    <button className="px-2 " title="Tri en croissant">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
                        </svg>
                    </button>
                    <button className="px-2 " title="Tri en décroissant">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
                        </svg>
                    </button>
                    <button className="px-2 " title="Templates d'export de médicaments">
                        <a href={process.env.PUBLIC_URL + '/templateXlsx/Database_Medicaments_medindex.xlsx'} download="DataBaseImportMedicament.xlsx" style={{textDecoration:"none"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                        </a>
                    </button>
                    <button className="px-2 " title="Importer mes medicament" onClick={()=>openModalInport()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                        </svg>
                    </button>
                    <button className="px-2 " title="Exporter les médicaments">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
                        </svg>
                    </button>
                    <button className="px-2 " title="Ajouter un medicament" onClick={()=>navigate("Add")}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </button>
                </div>              
            </div>
            {/* barre de recherche fin */}

            

            <div className={`${isLoad? "flex flex-col justify-center items-center h-2/3 w-full": "md:block mt-3"}`}>
                {
                    isLoad ?
                    <>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-blue-400 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                        <p className="mt-3">Chargement des médicaments ...</p>
                    </> :
                   
                    <>
                        {/* afficharge mode bureau */}
                        <div className="hidden md:block">
                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                <table className="w-full text-sm text-left">
                                    <thead className="bg-slate-400 text-slate-50 text-xs uppercase border-b border-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                #
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Nom commercial
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                D.C.I
                                            </th>
                                            {/* <th scope="col" className="px-6 py-3">
                                                Format
                                            </th> */}
                                            <th scope="col" className="px-6 py-3">
                                                Forme galenique
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Prix public
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        !isLoad && data.length > 0 ?
                                        data.filter((medicament:IMedicament)=>medicament.commercial_name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                                        .map((medicament:IMedicament,i:number) => {
                                            return (
                                            <tr key={i} className="bg-white border-b border-gray-200">
                                                <td className="px-6 py-4 d-flex">
                                                    {i + 1}
                                                </td>
                                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                    {medicament.commercial_name} 
                                                </th>
                                                <td className="px-6 py-4">
                                                    <small className="text-center">{medicament.dci}</small>
                                                </td>
                                                {/* <td className="px-6 py-4">
                                                    <img src={getIcons(medicament.Format)} alt="Ampoule" className="max-w-8 h-8 p-2 rounded-full bg-white shadow-md" />

                                                    <small className="text-center">{medicament.Format}</small>
                                                </td> */}
                                                <td className="px-6 py-4">
                                                    {medicament.format_dosage}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {medicament.prix_public} XAF
                                                </td>
                                                <td className="px-6 py-4">
                                                <button className='mb-2 text-blue-500 mx-2' title='Voir plus' onClick={()=>{openModalDetail(medicament)}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                    </svg>
                                                </button>
                                                <button className='mb-2 text-green-500 mx-2' onClick={() => {formUpdateMedicament(medicament)}} title='Modifier'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                </button>
                                                <button className='mb-2 text-red-500 mx-2' onClick={() => {openModalDelete(medicament)}} title='Supprimer '>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                </button>
                                                <button className='mb-2 text-yellow-500 mx-2' onClick={() => {navigate(medicament.id+"/annonce")}} title='Définir comme une annonce'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"/>
                                                    </svg>
                                                </button>
                                                </td>
                                            
                                            </tr>);
                                        
                                        }) :
                                        <tr>
                                            <th scope="row" colSpan={7} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                                Aucun enregistrement de médicament disponible
                                            </th>
                                        </tr>
                                    }
                                   
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* afficharge mode mobile */}
                        <div className="md:hidden block">
                        {
                            !isLoad && data.length > 0 ?
                            data.filter((medicament:IMedicament)=>medicament.commercial_name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                            .map((medicament:IMedicament,i:number) => {
                                return(
                                    <div key={i} className="flex flex-row rounded-md bg-white lg:mb-0 mb-4">
                                        <div className="bg-blue-500 rounded-tl-md rounded-bl-md p-3 flex flex-col items-center w-32">
                                            <img src={getIcons(medicament.Format)} alt="Ampoule" className="max-w-12 h-12 p-2 rounded-full bg-white shadow-md" />

                                            <h2 className="text-white text-center font-medium">{medicament.Format}</h2>
                                        </div>
                                        <div className="flex-auto p-2 bg-white rounded-tr-md rounded-br-md">
                                            <h2 className="font-medium text-sm">{medicament.commercial_name}</h2>
                                            <div className="flex justify-between">

                                                <div>
                                                    <small style={{fontSize:11}} className="text-slate-500">Prix céssion</small>
                                                    <div className="rounded-3xl border-2 border-blue-500 bg-blue-300 text-xs px-2">
                                                        <span className="text-white">{medicament.prix_cession} XAF</span>
                                                    </div>
                                                </div>
                                                
                                                <div>
                                                    <small style={{fontSize:11}} className="text-slate-500">Prix public</small>
                                                    <div className="rounded-3xl border-2 border-blue-500 bg-blue-300 text-xs px-2">
                                                        <span className="text-white">{medicament.prix_public} XAF</span>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="flex justify-end">
                                                <div className="hover:rounded-3xl px-2 cursor-pointer" onClick={()=>{openModalDetail(medicament)}}>
                                                    <span style={{fontSize:11}}>voir plus</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className=" w-2 inline-block" fill="none" viewBox="0 0 22 22" stroke="currentColor" strokeWidth={2}>
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                                    </svg>
                                                </div>
                                                
                                            </div>

                            
                                        </div>
                                        
                                    </div>
                                );
                            }) 
                            :
                            <tr>
                                <th scope="row" colSpan={7} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                    Aucun enregistrement de médicament disponible
                                </th>
                            </tr>
                            
                        }
                        </div>
                    </>
                            
                    
                }

                
                
               
                
            </div>

            <CustomModal Classe='md:w-2/5 w-5/6' config={config}/> 
           
        </>
    );
}

export default React.memo(AllMedicament);