import React, { useState,useRef, useEffect, useMemo } from 'react';
import SelectSearch from 'react-select-search';
import { FicheMedicamentModel } from '../../../model/ficheMedicamentModel';
import { MedicamentService } from '../../../services/medicamentService';
import { useNavigate, useLocation } from "react-router-dom";
import { FileService } from '../../../services/filesServices';

const AddMedicament = () => {
  const history = useNavigate();
  const [steps, setSteppe] = useState({step1:true,step2:false,step3:false});
  const searchInput:any = useRef();
  const[request,setRequest] = useState({} as any);
  const[data, setData] = useState({} as any);
  const[Disciplines, setOptionDiscipline] = useState([] as any);
  const[Classes, setOptionClasse] = useState([]);
  const[SousClasses, setOptionSousClasse] = useState([]);
  const[format, setformat] = useState([]);
  const[codeAct, setcodeAct] = useState([]);
  const[laboratoires, setLaboratoire] = useState([]);
  const[fabricants, setFabricants] = useState([]);
  const[disabledStatus, setDisabledStatus] = useState(0);
  const[load, setLoad] = useState(false);
  const[isValidDure, setIsValidDure] = useState(false);
  const[uniteAnnnonce, setUniteAnnnonce] = useState("");
  const[dureeA, setDureeA] = useState("");
  const [array, setArray] = useState([] as Array<string>);
  const[pictures, setPicture] = useState([]);
  const location = useLocation();

  useEffect(() => {
      console.log('is mount')
      getPropMedicament();
      console.log(request);
      handleValidateForm();
      // eslint-disable-next-line
  }, [request]);

  

  const saveMedicament = async (e: any) => {
    e.preventDefault();
    setLoad(true);

    var result;

    if(pictures.length > 0) {
      result = await FileService.uploadLotPictures(pictures, "annonce");
      console.log(result);

      if(result.status == 201) {
        let paths : Array<string> = result.data.paths;
        let urls = paths.join(",");
        setRequest({...request, urls: urls});
  
        var res = await MedicamentService.saveMedicament(request);
        console.log(res);
        setDisabledStatus(0);
        setLoad(false);
  
        if(res.status == 201) {
          setRequest({});
          history(-1);
          alert("Enregistré avec succès");
        } else {
          alert(res.data.message);
        }
  
      } else {
        alert(result.data.message);
      }

    } else {

      var res = await MedicamentService.saveMedicament(request);
        console.log(res);
        setDisabledStatus(0);
        setLoad(false);
  
        if(res.status == 201) {
          setRequest({});
          history(-1);
          alert("Enregistré avec succès");
        } else {
          alert(res.data.message);
        }

    }


    
  }

  const getPropMedicament = () =>{
    MedicamentService.getPropsMedicament().then(
        (response)=>{
            setData(response.data);
            let disciplines = response.data.disciplines.map((elt:any)=>{
              return elt;
            });
            let classes = response.data.classes.map((elt:any)=>{
              return elt;
            });
            let sousClasses = response.data.sousClasses.map((elt:any)=>{
              return elt;
            });
            let formats = response.data.formats.map((elt:any)=>{
              return elt;
            });
            let labos = response.data.laboratoires.map((elt:any)=>{
              return elt;
            });
            let fabric = response.data.fabricants.map((elt:any)=>{
              return elt;
            });
            let codeAtcs = response.data.codeATC.map((elt:any)=>{
              return elt;
            });
            setOptionDiscipline(disciplines);
            setOptionClasse(classes);
            setOptionSousClasse(sousClasses);
            setformat(formats);
            setcodeAct(codeAtcs);
            setLaboratoire(labos);
            setFabricants(fabric);
        }
    ).catch(
        (error: any)=>{
            console.log(error);
        }
    )
}

  function changeStep(e:any,type:string){
    e.preventDefault();
    let step = steps;
    let stepUpdate: any;
    switch (type) {
      case "next":
        if(step.step1 == true){
          stepUpdate = {step1:false,step2:true,step3:false}
        }
        if(step.step2 == true){
          stepUpdate = {step1:false,step2:false,step3:true}
        }
        break;
      case "prev":
        if(step.step2 == true){
          stepUpdate = {step1:true,step2:false,step3:false}
        }
        if(step.step3 == true){
          stepUpdate = {step1:false,step2:true,step3:false}
        }
        break;
      default:
        break;
    }
    

    setSteppe(stepUpdate);
  }


  const handleValidateForm = () => {
    /*if(request.nom_commercial != undefined && request.dci_principe_actif != undefined && request.forme_galenique_dosage != undefined &&
      request.conditionnement != undefined && request.icone_conditionnement != undefined &&
      request.laboratoire != undefined && request.prix_cession != undefined && request.prix_public != undefined 
    ) {*/
    if(request.nom_commercial != undefined ) {
        setDisabledStatus(3);
      
      /*if(request.disponibilite != undefined && request.indications != undefined && request.contre_indications != undefined &&
        request.posologie_et_mode_d_administration != undefined && request.effets_secondaires != undefined 
      ) {
          setDisabledStatus(2);
          setDisabledStatus(3);
          console.log(request);

        if(request.duree_annonce != undefined) {
          setDisabledStatus(3);
        } else {
          setDisabledStatus(2);
        }
      } else {
        setDisabledStatus(1);
      }  */
    } else {
      setDisabledStatus(0);
    }
  }

  async function handlerInput(e:any){
    const {name, value,files}:any = e.target;

    switch (name) {
      case "nom_commercial":
        setRequest({...request,nom_commercial:value});
        break;
      case "indications":
        setRequest({...request,indications:value});
        break;
      case "contre_indications":
        setRequest({...request,contre_indications:value});
        break;
      case "posologie_et_mode_d_administration":
        setRequest({...request,posologie_et_mode_d_administration:value});
        break;
      case "effets_secondaires":
        setRequest({...request,effets_secondaires:value});
        break;
      case "forme_galenique_dosage":
        setRequest({...request,forme_galenique_dosage:value});
        break;
      case "conditionnement":
        setRequest({...request,conditionnement:value});
        break;
      case "interactions_medicamenteuses":
        setRequest({...request,interactions_medisValidDureicamenteuses:value});
        break;
      case "grossesse_allaitement_et_fertilite":
        setRequest({...request,grossesse_allaitement_et_fertilite:value});
        break;
      case "discipline":
        setRequest({...request,discipline:value});
        break;
      case "classe":
        setRequest({...request,classe:value});
        break;
      case "sous_classe":
        setRequest({...request,sous_classe:value});
        break;
      case "icone_conditionnement":
        setRequest({...request,icone_conditionnement:value});
        break;
      case "message_grossesse":
        setRequest({...request,message_grossesse:value});
        break;
      case "icone_conduite":
        setRequest({...request,icone_conduite:value});
        break;
      case "code_atc":
        setRequest({...request,code_atc:value});
        break;
      case "laboratoire":
        setRequest({...request,laboratoire:value});
        break;
      case "dci_principe_actif":
        setRequest({...request,dci_principe_actif:value});
        break;
      case "icone_grossesse":
        //setRequest({...request,icone_grossesse:value});
        break;
      case "prix_cession":
        setRequest({...request,prix_cession:value});
        break;
      case "prix_public":
        setRequest({...request,prix_public:value});
        break;
      case "disponibilite":
        setRequest({...request,disponibilite:value});
        break;
      case "unite_annonce":
        console.log(value);
        if(value != null && value != "") {
          console.log(value);
          setIsValidDure(true);
          setRequest({...request, ...{unite_annonce: value, duree_annonce:0}});
        } else {
          setIsValidDure(false);
          setRequest({...request, ...{unite_annonce: null, duree_annonce:0}});
        }
        setDureeA("");
        console.log(request);
        break;
      case "duree_annonce":
        let duree;
        let intValue = parseInt(value);
        console.log(typeof intValue)
        if(value != null && value != "") {
          setDureeA(value);
          if(request.unite_annonce == 'heur') {
            duree = intValue
            console.log(intValue)
            setRequest({...request,duree_annonce: duree});
          } else if(request.unite_annonce == 'jour') {
            console.log("jour")
            duree = intValue * 24
            setRequest({...request,duree_annonce: duree});
          } else if(request.unite_annonce == 'semaine') {
            duree = intValue * 24 * 7
            setRequest({...request,duree_annonce: duree});
          }
          console.log(duree);
          
        } else {
          setRequest({...request,duree_annonce:value});
        }
        break;   
      case "annonce_status":
        setRequest({...request,annonce_status:value});
        break;      
      case "message_indisponibilte":
        setRequest({...request,message_indisponibilte:value});
        break;
      case "is_new":
        setRequest({...request,is_new:value});
        break;
      case "images":
        let images:any = [];
        let maxImage = (files.length > 5)? 5: files.length;
        if (files.length > 5) {
          alert("Seulement les 5 premieres images vont être importées ")
        } 
        for (let i = 0; i < maxImage; i++) {
          images.push(files[i]);
        };
        setPicture(images);
        break;  
      case "mot_labo":
        setRequest({...request,mot_labo:value});
        break;  
      default:
        throw new Error("input not allow");
    }

    console.log(request);
    
  }
  
  return (

    <>
  
      <div className="bg-white py-3 px-6 mb-3 rounded-md w-full">
          <h1 className="text-2xl border-b-2">Médicaments</h1>
          <p className="text-sm text-slate-500 mt-2">Ajouts des médicaments </p>
      </div>
     

      <form>
          <div className="bg-white rounded-md px-4 py-2">

            <div className="flex flex-wrap justify-center min-w-full bg-white  border-b-2">
              <div className={`flex flex-col mx-2 cursor-pointer items-center ${steps.step1? "border-b-2 border-blue-500 text-blue-500":"text-gray-500"}  py-2 `}>
                <h3 className='text-sm font-medium'>Etape 1</h3>
                <span className='text-xs font-light'>Informations principales</span>
              </div>

              <div className={`flex flex-col mx-2 cursor-pointer items-center ${steps.step2? "border-b-2 border-blue-500 text-blue-500":"text-gray-500"}  py-2 `}>
                <h3 className='text-sm font-medium'>Etape 2</h3>
                <span className='text-xs font-light'>Informations detaillées</span>
              </div>

              <div className={`flex flex-col mx-2 cursor-pointer items-center ${steps.step3? "border-b-2 border-blue-500 text-blue-500":"text-gray-500"}  py-2 `}>
                <h3 className='text-sm font-medium'>Etape 3</h3>
                <span className='text-xs font-light'>Information sur les classes</span>
              </div>
            </div>
            
            

            <div className={`${steps.step1? "md:grid md:grid-cols-3 md:gap-4":"hidden "} min-w-full bg-white p-3 rounded-md my-3` } id='step1'>

              <label className="block">
                <span className="form-label-o">
                  Nom commercial
                </span>
                <input type="text" name="nom_commercial" onChange = {(e)=>handlerInput(e)} required className="peer form-control" placeholder="Nom commerciale" />
                {/* <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  le nom commercial est obligatoires
                </p> */}
              </label>

              <label className="block">
                <span className="">
                  DCI ou principe actif
                </span>
                <input type="text" name="dci_principe_actif" onChange = {(e)=>handlerInput(e)} required className="peer form-control" placeholder="DCI ou principe actif" />
                {/* <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  le principe actif est obligatoires
                </p> */}
              </label>

              <label className="block">
                <span className="">
                  Forme Galénique/Dosage
                </span>
                <input type="text" name="forme_galenique_dosage" onChange = {(e)=>handlerInput(e)} required className="peer form-control" placeholder="Forme Galénique/Dosage" />
                {/* <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  la Forme Galénique/Dosage est obligatoires
                </p> */}
              </label>

              <label className="block">
                <span className="">
                  Conditionnement
                </span>
                <input type="text" name="conditionnement" onChange = {(e)=>handlerInput(e)} required className="peer form-control" placeholder="Conditionnement" />
                {/* <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  le conditionnement est obligatoires
                </p> */}
              </label>

              <label className="block">
                <span className="">
                  Icône conditionnement
                </span>
                <select name="icone_conditionnement" onChange = {(e)=>handlerInput(e)} required className="peer form-control" id="">
                  <option value="">select value</option>
                  {
                    format.map((elt:any,i:number)=>{
                      return (<option key={i} value={elt.name}>{elt.name}</option>);
                    })
                  }
                </select>
                {/* <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  l'icon du conditionnement est obligatoires
                </p> */}
              </label>

              <label className="block">
                <span className="form-label">
                  Code ATC
                </span>
                <input list="codeAtc" onChange = {(e)=>handlerInput(e)} id="ice-cream-choice" className="form-control" name="code_atc" placeholder='Entrer un codeAtc' />

                <datalist id="codeAtc">
                  {
                    codeAct.map((elt:any,i:number)=>{
                      return <option key={i} value={elt.code}/>
                    })
                  }
                </datalist>
              </label>

              <label className="block">
                <span className="">
                  Laboratoire
                </span>
                <select name="laboratoire" onChange = {(e)=>handlerInput(e)} required className="peer form-control" id="">
                  <option value="">Selectionnez un laboratoire</option>
                  {
                    fabricants.map((elt:any,i:number)=>{
                      return (<option key={i} value={elt.id}>{elt.name}</option>);
                    })
                  }
                </select>
                
                {/* <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  le laboratoire est obligatoire
                </p> */}
              </label>

              <label className="block">
                <span className="">
                  Prix céssion
                </span>
                <input type="number" onChange = {(e)=>handlerInput(e)} min={0} name="prix_cession" required className="peer form-control" placeholder="Prix céssion" />
                {/* <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  le Prix céssion est obligatoires
                </p> */}
              </label>

              <label className="block">
                <span className="">
                  Prix public
                </span>
                <input type="number" onChange = {(e)=>handlerInput(e)} min={0} name="prix_public" required className="peer form-control" placeholder="Prix public" />
                {/* <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  le Prix public est obligatoires
                </p> */}
              </label>
            </div>

            <div className={`${steps.step2? "md:grid md:grid-cols-3 md:gap-4 md:grid-flow-row-dense":"hidden "} min-w-full bg-white p-3 rounded-md my-3` } id='step2'>
              <label className="block">
                <span className="form-label">
                  Type d'icône de conduite
                </span>
                <select onChange = {(e)=>handlerInput(e)} name="icone_conduite" className="form-control" id="">
                  <option value="">select value</option>
                  <option value="Niveau 1">Niveau 1</option>
                  <option value="Niveau 2">Niveau 2</option>
                  <option value="Niveau 3">Niveau 3</option>
                </select>
              </label>

              <label className="block">
                <span className="form-label">
                  Type d'icône de grossesse
                </span>
                <select onChange = {(e)=>handlerInput(e)} name="icone_grossesse" className="form-control" id="">
                  <option value="">select value</option>
                  <option value="Icon 1">Icon 1</option>
                  <option value="Icon 2">Icon 2</option>
                </select>
              </label>

              <label className="block">
                <span className="">
                  Disponibilité
                </span>
                <select onChange = {(e)=>handlerInput(e)} name="disponibilite" className="peer form-control" id="">
                  <option selected value="">select value</option>
                  <option  value="oui">Oui</option>
                  <option value="non">Non</option>
                </select>
                {/* <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  la Disponibilité est obligatoires
                </p> */}
              </label>

              <label className="block">
                <span className="form-label">
                  Message grossesse
                </span>
                <textarea onChange = {(e)=>handlerInput(e)} name="message_grossesse" id="" className="form-control" cols={30} rows={4} placeholder="Message grossesse"></textarea>
              </label>

              <label className="block">
                <span className="form-label">
                  Message d'indisponibilité
                </span>
                <textarea onChange = {(e)=>handlerInput(e)} name="message_indisponibilte" id="" className="form-control" cols={30} rows={4} placeholder="Message d'indisponibilité"></textarea>
              </label>

              <label className="block">
                <span className="">
                  Indication
                </span>
                <textarea onChange = {(e)=>handlerInput(e)} name="indications" id="" required className="peer form-control" cols={30} rows={4} placeholder="Indication"></textarea>
                {/* <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  l'indication est obligatoires
                </p> */}
              </label>

              <label className="block">
                <span className="">
                  Contre Indication
                </span>
                <textarea onChange = {(e)=>handlerInput(e)} name="contre_indications" id="" className="form-control" cols={30} rows={4} placeholder="Contre Indication"></textarea>
              </label>

              <label className="block">
                <span className="">
                  Posologie
                </span>
                <textarea onChange = {(e)=>handlerInput(e)} name="posologie_et_mode_d_administration" id="" required className="peer form-control" cols={30} rows={4} placeholder="Posologie"></textarea>
                {/* <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  la posologie est obligatoires
                </p> */}
              </label>

              <label className="block">
                <span className="">
                  Effets secondaire
                </span>
                <textarea onChange = {(e)=>handlerInput(e)} name="effets_secondaires" id="" className="form-control" cols={30} rows={4} placeholder="Effets secondaire"></textarea>
              </label>

              <label className="block">
                <span className="form-label">
                  Interaction medicamenteuse
                </span>
                <textarea onChange = {(e)=>handlerInput(e)} name="interactions_medicamenteuses" id="" className="peer form-control" cols={30} rows={4} placeholder="Interaction medicamenteuse"></textarea>
              </label>

              <label className="block">
                <span className="form-label">
                  Grossesse allaitement fertilité
                </span>
                <textarea onChange = {(e)=>handlerInput(e)} name="grossesse_allaitement_et_fertilite" id="" className="peer form-control" cols={30} rows={4} placeholder="Grossesse allaitement fertilité"></textarea>
              </label>
            </div>

            <div className={`${steps.step3? "md:grid md:grid-cols-3 md:gap-4 md:grid-flow-row-dense":"hidden "} min-w-full bg-white p-3 rounded-md my-3` } id='step3'>
              <label className="block relative">
                <span className="form-label">
                  Discipline
                </span>

                <input onChange = {(e)=>handlerInput(e)} list="discipline" id="ice-cream-choice" className=" form-control" name="discipline" placeholder='Entrer un discipline' />

                <datalist id="discipline">
                  {
                    Disciplines.map((elt:any,i:number)=>{
                      return <option key={i} value={elt.name}/>
                    })
                  }
                </datalist>
              </label>

              <label className="block relative">
                <span className="form-label">
                  Classe
                </span>
                <input onChange = {(e)=>handlerInput(e)} list="classe" id="ice-cream-choice" className=" form-control" name="classe" placeholder='Entrer une classe' />

                <datalist id="classe">
                  {
                    Classes.map((elt:any,i:number)=>{
                      return <option key={i} value={elt.name}/>
                    })
                  }
                </datalist>
              </label>

              <label className="block relative">
                <span className="form-label">
                  Sous-Classe
                </span>
                <input onChange = {(e)=>handlerInput(e)} list="sousclasse" id="ice-cream-choice" className=" form-control" name="sous_classe" placeholder='Entrer une sousclasse' />

                <datalist id="sousclasse">
                  {
                    SousClasses.map((elt:any,i:number)=>{
                      return <option key={i} value={elt.name}/>
                    })
                  }
                </datalist>
              </label>

              <div className="mt-3 col-span-3 ">
                <h3 className='text-base mb-3'>Marquer comme une annonce</h3>

                <div className="md:grid md:grid-cols-3 md:gap-4 md:grid-flow-row-dense">
                  
                  <label className="block">
                    <span className="form-label-o">
                      Unité de l'annonce
                    </span>
                    <select onChange = {(e)=>handlerInput(e)} name="unite_annonce" className="form-control" id="">
                      <option selected value="">Selectionner une valeur</option>
                      <option value="heur">Heure</option>
                      <option value="jour">Jour</option>
                      <option value="semaine">Semaine</option>
                    </select>
                  </label>

                  <label className="block">
                    <span className="form-label-o">
                      Durée de l'annonces
                    </span>
                    <input onChange = {(e)=>handlerInput(e)} value={dureeA} readOnly={false} type="number" min={0} name="duree_annonce" required className="peer form-control" placeholder="Durée de l'annonces" />
                    {/* <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                      la durée de l'annonces est obligatoires
                    </p> */}
                  </label>

                  <label className="block">
                    <span className="form-label">
                      Nouvelle annonce ou pas
                    </span>
                    <select onChange = {(e)=>handlerInput(e)} name="is_new" className="form-control" id="">
                      <option selected value="">Selectionner une valeur</option>
                      <option value="Oui">Oui</option>
                      <option value="Non">Non</option>
                    </select>
                  </label>

                  <label className="block">
                    <span className="form-label">
                      Publication de annonce
                    </span>
                    <select onChange = {(e)=>handlerInput(e)} name="annonce_status" className="form-control" id="">
                      <option selected value="">Selectionner une valeur</option>
                      <option value="Oui">Oui</option>
                      <option value="Non">Non</option>
                    </select>
                  </label>

                  <label className="block">
                    <span className="form-label">
                      Mot du laboratoire
                    </span>
                    <input onChange = {(e)=>handlerInput(e)} type="text" min={0} name="mot_labo" required className="peer form-control" placeholder="Mot du laboratoire" />
                  </label>
                </div>
                
                <label className="block mt-2">
                    <span className="form-label">
                      Telecharger les images de l'annonce (max 5)
                    </span>
                    <input type="file" multiple={true} onChange= {(e)=>handlerInput(e)} name="images" id="" />
                </label>
              </div>            
            </div>
          </div>
          

          <div className="flex pb-3 justify-end  mt-3">
            
            {
              steps.step1 &&
              <button className='rounded-md bg-blue-500 px-2 text-white mx-3 w-32 hover:bg-blue-400' disabled = {disabledStatus > 0 ? false : true} onClick={(e)=>changeStep(e,"next")}>Suivant</button>
            }

            {
              steps.step2 &&
              <>
                <button className='rounded-md bg-blue-500 px-2 text-white mx-3 w-32 hover:bg-blue-400' onClick={(e)=>changeStep(e,"prev")}>Precedant</button>
                <button className='rounded-md bg-blue-500 px-2 text-white mx-3 w-32 hover:bg-blue-400' disabled = {disabledStatus > 1 ? false : true} onClick={(e)=>changeStep(e,"next")}>Suivant</button>
              </>
            }

            {
              steps.step3 &&
              <>
                <button className='rounded-md bg-blue-500 px-2 text-white mx-3 w-32 hover:bg-blue-400' onClick={(e)=>changeStep(e,"prev")}>Precedant</button>
                <button className='rounded-md bg-blue-500 px-2 text-white mx-3 w-32 hover:bg-blue-400' onClick = {(e) => saveMedicament(e)} disabled = {disabledStatus > 2 ? false : true} >
                  
                {
                  load &&
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block animate-spin mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                  </svg>
                }

                  Enregistrer</button>
              </>
            }
            
            
          </div>

      </form>
      
    </>
  )
}

export {AddMedicament}
