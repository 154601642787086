class UserModel{

    id: number | null;
    name: string;
    email: string;
    password: string;
    username: string;
    token: string | "";

    constructor(data?:any){
        this.id = data?.id;
        this.name = data?.noms_des_examens;
        this.email = data?.email;
        this.password = data?.password;
        this.username = data?.username;
        this.token = data?.token;
    }
}

export{UserModel}