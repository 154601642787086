import React, { useEffect, useState } from "react";
import CustomModal from "../../../components/customModal";
import { useNavigate } from "react-router-dom";
import IAnnonce from "../../../interfaces/IAnnonce";
import { MedicamentService } from "../../../services/medicamentService";

const ListAnnonce = () =>{
    const history = useNavigate();
    const[isOpen,setOpen]=useState(false);
    const[config,setConfig]=useState({ "display":"none","defaultType":"medicament",} as any);
    const[annonceData, setAnnonceData] = useState([]);
    const[isLoad,setLoader]=useState(false);
    const[search,setSearch]=useState("");

    useEffect(() => {
        console.log('is loader')
        getAnnonces();
        // eslint-disable-next-line
    }, []);

    function openModalDeleteAnnonce( annonce: IAnnonce){
        let configs = {
            "type":"confirmation",
            "title":"Attention",
            "action":"SUPRESSION",
            "msgConfirm": "Vous allez supprimer l'annonce \"" + annonce.medicament.commercial_name + "\"",
            "close": () => {setConfig({ "display":"none"});},
            "confirm": () => {deleteAnnonce(annonce.id)},
            "display":"",
        };

        setConfig(configs);
    }

    const deleteAnnonce = async (annonceId: any) => {
        var res = await MedicamentService.deleteAnnonce(annonceId);
        setConfig({ "display":"none"});
        
        if(res.status === 200) {
            alert('Supprimé avec succès');
            getAnnonces();
        } else {
            alert('Impossible de supprimer cette annonce. Une erreur s\'est produite ');
        }
    }

    //useMemo()
    function closeModal(){
        console.log(config);
        setConfig({ "display":"none"});
        let inputFile:any = document.querySelector("#fileXls");
        inputFile.value = "";
        setOpen(false);
    }

    function navigate(to:string){
        history(to);
    }
    
    const getAnnonces = () => {
        setLoader(true);
        MedicamentService.getAnnonces().then(
            (response)=>{
                setAnnonceData(response.data);
                setLoader(false);
            }
        ).catch(
            (error: any)=>{
                console.log(error);
                setAnnonceData([]);
                setLoader(true);
            }
        )
    }

    

    return (
        <>
            
            <div className="bg-white p-3 rounded-md w-full">
                <h1 className="text-2xl border-b-2">Annonce</h1>
                <p className="text-sm text-slate-500 mt-2">Liste des annonces disponibles</p>
            </div>
           
            {/* barre de recherche et filtre */}
            {/* <div className="flex flex-wrap justify-between min-w-full bg-white p-3 rounded-md my-3">
                <div className="flex items-center border-2 hover:border-blue-500 hover:text-blue-500 focus-within:border-blue-500 rounded-md focus-within:text-blue-500 text-slate-400 my-1 md:w-auto w-full">
                    <input type="text" className="form-control-search rounded-md mt-0 text-slate-400 w-full " onChange={(e)=>setSearch(e.target.value)} placeholder="Rechecher de medicament" id="" />
                    <button className="px-2 ">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </button>
                </div>  

                <div className="flex justify-between my-1 md:w-auto w-full">
                    
                    <button className="px-2 " title="Ajouter une annonce" onClick={()=>navigate("Add")}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </button>
                </div>              
            </div> */}
            {/* barre de recherche fin */}

            <div className={`${isLoad? "flex flex-col justify-center items-center h-2/3 w-full": "md:block mt-3"}`}>
                {
                    isLoad && 
                    <>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-blue-400 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                        <p className="mt-3">Chargement des annonces ...</p>
                    </>
                }
                
                

                {
                    !isLoad &&
                    <>
                        {
                             <>
                             {/* afficharge mode bureau */}
                             <div className=" md:block">
                                 <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                     <table className="w-full text-sm text-left">
                                         <thead className="bg-slate-400 text-slate-50 text-xs uppercase border-b border-gray-400">
                                             <tr>
                                                 <th scope="col" className="px-6 py-3">
                                                     #
                                                 </th>
                                                 <th scope="col" className="px-6 py-3">
                                                     Nom commercial
                                                 </th>
                                                 <th scope="col" className="px-6 py-3">
                                                     D.C.I
                                                 </th>
                                                 <th scope="col" className="px-6 py-3">
                                                     Prix public
                                                 </th>
                                                 <th scope="col" className="px-6 py-3">
                                                     Duree
                                                 </th>
                                                 <th scope="col" className="px-6 py-3">
                                                     Status
                                                 </th>
                                                 <th scope="col" className="px-6 py-3">
                                                     Actions
                                                 </th>
                                             </tr>
                                         </thead>
                                         <tbody>
                                         {
                                             !isLoad && annonceData.length > 0 &&
                                             annonceData.map((annonce:IAnnonce,i:number) => {
                                                 return (
                                                 <tr key={i} className="bg-white border-b border-gray-200">
                                                     <td className="px-6 py-4 d-flex">
                                                         {i + 1}
                                                     </td>
                                                     <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                         {annonce.medicament.commercial_name} 
                                                     </th>
                                                     <td className="px-6 py-4">
                                                         <small className="text-center">{annonce.medicament.dci}</small>
                                                     </td>
                                                     <td className="px-6 py-4">
                                                         {annonce.medicament.prix_public} XAF
                                                     </td>
                                                     <td className="px-6 py-4">
                                                         <small className="text-center">{annonce.duree}</small>
                                                     </td>
                                                     <td className="px-6 py-4">
                                                         <small className="text-center">{annonce.isActive == 1? "Actif": "Inactif"} </small>
                                                     </td>
                                                     <td className="px-6 py-4">
                                                     {/* <button className='mb-2 text-blue-500 mx-2' title='Voir plus' onClick={()=>{openModalDetail(annonce.medicament)}}>
                                                         <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                         <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                         <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                         </svg>
                                                     </button> */}
                                                   
                                                     <button className='mb-2 text-red-500 mx-2' onClick={() => {openModalDeleteAnnonce(annonce)}} title='Supprimer '>
                                                         <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                         <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                         </svg>
                                                     </button>
                                                 
                                                     </td>
                                                 
                                                 </tr>);
                                             
                                             })
                                         }
                                         {
                                             
                                             annonceData.length === 0 &&
                                             <tr>
                                                 <th scope="row" colSpan={7} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                                     Aucun enregistrement d'annonce disponible
                                                 </th>
                                             </tr>
                                             
                                         }
                                         
                                         </tbody>
                                     </table>
                                 </div>
                             </div>
                           
                         </>
                     
                        }
                       
                    </>
                   
                }
                
               
                
            </div>

            <CustomModal Classe='md:w-2/5 w-5/6' config={config}/> 
           
        </>
    );
}

export {ListAnnonce};