import React, { useState,useRef, useEffect, useMemo } from 'react';
import SelectSearch from 'react-select-search';
import { FicheMedicamentModel } from '../../../model/ficheMedicamentModel';
import { MedicamentService } from '../../../services/medicamentService';
import { useNavigate, useLocation } from "react-router-dom";
import { FileService } from '../../../services/filesServices';

const UpdateMedicament = () => {
  const history = useNavigate();
  const [steps, setSteppe] = useState({step1:true,step2:false,step3:false});
  const[request, setRequest] = useState({init: 0} as any);
  const[data, setData] = useState({} as any);
  const[Disciplines, setOptionDiscipline] = useState([] as any);
  const[Classes, setOptionClasse] = useState([]);
  const[SousClasses, setOptionSousClasse] = useState([]);
  const[format, setformat] = useState([]);
  const[codeAct, setcodeAct] = useState([]);
  const[laboratoires, setLaboratoire] = useState([]);
  const[fabricants, setFabricants] = useState([]);
  const[disabledStatus, setDisabledStatus] = useState(0);
  const[load, setLoad] = useState(false);
  const[countState, setStateCount] = useState(0);

  const { state } = useLocation();
  console.log(state);

  useEffect(() => {
    getPropMedicament();
    if(countState == 0) {
      initialData();
    }

    if(request.nom_commercial != null) {
      setDisabledStatus(3);
    } else {
      setDisabledStatus(0);
    }

    console.log(request);
      // eslint-disable-next-line
  }, [request]);

  const updateMedicament = async (e: any) => {
    e.preventDefault();
    setLoad(true);

    console.log(request);
    var res = await MedicamentService.updateMedicament(request);
    console.log(res);
    setDisabledStatus(0);
    setLoad(false);

    if(res.status == 200) {
      setRequest({});
      history(-1);
      alert("Modifié avec succès");
    } else {
      alert(res.data.message);
    }

  }

  const getPropMedicament = () =>{
    MedicamentService.getPropsMedicament().then(
        (response)=>{
            setData(response.data);
            let disciplines = response.data.disciplines.map((elt:any)=>{
              return elt;
            });
            let classes = response.data.classes.map((elt:any)=>{
              return elt;
            });
            let sousClasses = response.data.sousClasses.map((elt:any)=>{
              return elt;
            });
            let formats = response.data.formats.map((elt:any)=>{
              return elt;
            });
            let labos = response.data.laboratoires.map((elt:any)=>{
              return elt;
            });
            let fabric = response.data.fabricants.map((elt:any)=>{
              return elt;
            });
            let codeAtcs = response.data.codeATC.map((elt:any)=>{
              return elt;
            });
            setOptionDiscipline(disciplines);
            setOptionClasse(classes);
            setOptionSousClasse(sousClasses);
            setformat(formats);
            setcodeAct(codeAtcs);
            setLaboratoire(labos);
            setFabricants(fabric);
        }
    ).catch(
        (error: any)=>{
            console.log(error);
        }
    )
}

  function changeStep(e:any,type:string){
    e.preventDefault();
    let step = steps;
    let stepUpdate: any;
    switch (type) {
      case "next":
        if(step.step1 == true){
          stepUpdate = {step1:false,step2:true,step3:false}
        }
        if(step.step2 == true){
          stepUpdate = {step1:false,step2:false,step3:true}
        }
        break;
      case "prev":
        if(step.step2 == true){
          stepUpdate = {step1:true,step2:false,step3:false}
        }
        if(step.step3 == true){
          stepUpdate = {step1:false,step2:true,step3:false}
        }
        break;
      default:
        break;
    }
    

    setSteppe(stepUpdate);
  }

  const initialData = () => {
    let initData :any = state;
    setStateCount(1);
    if(initData == null) return;
    
    var medicament : any = {
      id: initData.id,
      nom_commercial: initData.commercial_name,
      indication: initData.indication,
      contre_indications: initData.contre_indication,
      posologie_et_mode_d_administration: initData.posologie,
      effets_secondaires: initData.effet_secondaire,
      forme_galenique_dosage: initData.format_dosage,
      conditionnement: initData.conditionnement,
      interactions_medicamenteuses: initData.interation_medoc,
      grossesse_allaitement_et_fertilite: initData.gaf,
      discipline: initData.Discipline,
      classe: initData.Classe,
      sous_classe: initData.sousClasse,
      icone_conditionnement: initData.format.name,
      message_grossesse: initData.message_grossesse,
      //icone_conduite: initData.conditionnement,
      code_atc: initData.CodeAtc,
      laboratoire: initData.fabricant_id,
      dci_principe_actif: initData.dci,
      prix_cession: initData.prix_cession,
      prix_public: initData.prix_public,
      //disponibilite: initData.disponibilite,
    }

    console.log(medicament);
    setRequest(medicament);
    console.log(request);
  }


  async function handlerInput(e:any){
    const {name, value,files}:any = e.target;

    switch (name) {
      case "nom_commercial":
        setRequest({...request,nom_commercial:value});
        break;
      case "indications":
        setRequest({...request,indications:value});
        break;
      case "contre_indications":
        setRequest({...request,contre_indications:value});
        break;
      case "posologie_et_mode_d_administration":
        setRequest({...request,posologie_et_mode_d_administration:value});
        break;
      case "effets_secondaires":
        setRequest({...request,effets_secondaires:value});
        break;
      case "forme_galenique_dosage":
        setRequest({...request,forme_galenique_dosage:value});
        break;
      case "conditionnement":
        setRequest({...request,conditionnement:value});
        break;
      case "interactions_medicamenteuses":
        setRequest({...request,interactions_medicamenteuses:value});
        break;
      case "grossesse_allaitement_et_fertilite":
        setRequest({...request,grossesse_allaitement_et_fertilite:value});
        break;
      case "discipline":
        setRequest({...request,discipline:value});
        break;
      case "classe":
        setRequest({...request,classe:value});
        break;
      case "sous_classe":
        setRequest({...request,sous_classe:value});
        break;
      case "icone_conditionnement":
        setRequest({...request,icone_conditionnement:value});
        break;
      
      case "message_grossesse":
        setRequest({...request,message_grossesse:value});
        break;
      case "icone_conduite":
        setRequest({...request,icone_conduite:value});
        break;
      case "code_atc":
        setRequest({...request,code_atc:value});
        break;
      case "laboratoire":
        setRequest({...request,laboratoire:value});
        break;
      case "dci_principe_actif":
        setRequest({...request,dci_principe_actif:value});
        break;
      case "icone_grossesse":
        setRequest({...request,icone_grossesse:value});
        break;
      case "prix_cession":
        setRequest({...request,prix_cession:value});
        break;
      case "prix_public":
        setRequest({...request,prix_public:value});
        break;
      case "disponibilite":
        setRequest({...request,disponibilite:value});
        break;
      case "duree_annonce":
        setRequest({...request,duree_annonce:value});
        break;   
      case "annonce_status":
        setRequest({...request,annonce_status:value});
        break;      
      case "message_indisponibilte":
        setRequest({...request,message_indisponibilte:value});
        break;
      case "is_new":
        setRequest({...request,is_new:value});
        break;
     
      default:
        throw new Error("input not allow");
    }
    
  }
  
  return (

    <>
  
      <div className="bg-white py-3 px-6 mb-3 rounded-md w-full">
          <h1 className="text-2xl border-b-2">Medicaments</h1>
          <p className="text-sm text-slate-500 mt-2">Modification des medicaments </p>
      </div>
     

      <form>
          <div className="bg-white rounded-md px-4 py-2">

            <div className="flex flex-wrap justify-center min-w-full bg-white  border-b-2">
              <div className={`flex flex-col mx-2 cursor-pointer items-center ${steps.step1? "border-b-2 border-blue-500 text-blue-500":"text-gray-500"}  py-2 `}>
                <h3 className='text-sm font-medium'>Etape 1</h3>
                <span className='text-xs font-light'>Informations principales</span>
              </div>

              <div className={`flex flex-col mx-2 cursor-pointer items-center ${steps.step2? "border-b-2 border-blue-500 text-blue-500":"text-gray-500"}  py-2 `}>
                <h3 className='text-sm font-medium'>Etape 2</h3>
                <span className='text-xs font-light'>Informations detaillées</span>
              </div>

              <div className={`flex flex-col mx-2 cursor-pointer items-center ${steps.step3? "border-b-2 border-blue-500 text-blue-500":"text-gray-500"}  py-2 `}>
                <h3 className='text-sm font-medium'>Etape 3</h3>
                <span className='text-xs font-light'>Information sur les classes</span>
              </div>
            </div>
            
            

            <div className={`${steps.step1? "md:grid md:grid-cols-3 md:gap-4":"hidden "} min-w-full bg-white p-3 rounded-md my-3` } id='step1'>

              <label className="block">
                <span className="form-label-o">
                  Nom commercial
                </span>
                <input type="text" value={request.nom_commercial} name="nom_commercial" onChange = {(e)=>handlerInput(e)} required className="peer form-control" placeholder="Nom commerciale" />
                <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  le nom commercial est obligatoires
                </p>
              </label>

              <label className="block">
                <span className="">
                  DCI ou principe actif
                </span>
                <input type="text" value={request.dci_principe_actif} name="dci_principe_actif" onChange = {(e)=>handlerInput(e)} required className="peer form-control" placeholder="DCI ou principe actif" />
                <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  le principe actif est obligatoires
                </p>
              </label>

              <label className="block">
                <span className="">
                  Forme Galénique/Dosage
                </span>
                <input type="text" value={request.forme_galenique_dosage} name="forme_galenique_dosage" onChange = {(e)=>handlerInput(e)} required className="peer form-control" placeholder="Forme Galénique/Dosage" />
                <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  la Forme Galénique/Dosage est obligatoires
                </p>
              </label>

              <label className="block">
                <span className="">
                  Conditionnement
                </span>
                <input type="text" value={request.conditionnement} name="conditionnement" onChange = {(e)=>handlerInput(e)} required className="peer form-control" placeholder="Conditionnement" />
                <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  le conditionnement est obligatoires
                </p>
              </label>

              <label className="block">
                <span className="">
                  Icône conditionnement
                </span>
                <select name="icone_conditionnement" value={request.icone_conditionnement} onChange = {(e)=>handlerInput(e)} required className="peer form-control" id="">
                  <option value="">select value</option>
                  {
                    format.map((elt:any,i:number)=>{
                      return (<option key={i} value={elt.name}>{elt.name}</option>);
                    })
                  }
                </select>
                <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  l'icon du conditionnement est obligatoires
                </p>
              </label>

              <label className="block">
                <span className="form-label">
                  Code ATC
                </span>
                <input list="codeAtc" value={request.code_atc} onChange = {(e)=>handlerInput(e)} id="ice-cream-choice" className="form-control" name="code_atc" placeholder='Entrer un codeAtc' />

                <datalist id="codeAtc">
                  {
                    codeAct.map((elt:any,i:number)=>{
                      return <option key={i} value={elt.code}/>
                    })
                  }
                </datalist>
              </label>

              <label className="block">
                <span className="">
                  Laboratoire
                </span>
                <select name="laboratoire" value={request.laboratoire} onChange = {(e)=>handlerInput(e)} required className="peer form-control" id="">
                  <option value="">Selectionnez un laboratoire</option>
                  {
                    fabricants.map((elt:any,i:number)=>{
                      return (<option key={i} value={elt.id}>{elt.name}</option>);
                    })
                  }
                </select>
                
                <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  le laboratoire est obligatoire
                </p>
              </label>

              <label className="block">
                <span className="">
                  Prix céssion
                </span>
                <input type="number" value={request.prix_cession} onChange = {(e)=>handlerInput(e)} min={0} name="prix_cession" required className="peer form-control" placeholder="Prix céssion" />
                <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  le Prix céssion est obligatoires
                </p>
              </label>

              <label className="block">
                <span className="">
                  Prix public
                </span>
                <input type="number" value={request.prix_public} onChange = {(e)=>handlerInput(e)} min={0} name="prix_public" required className="peer form-control" placeholder="Prix public" />
                <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  le Prix public est obligatoires
                </p>
              </label>
            </div>

            <div className={`${steps.step2? "md:grid md:grid-cols-3 md:gap-4 md:grid-flow-row-dense":"hidden "} min-w-full bg-white p-3 rounded-md my-3` } id='step2'>
              <label className="block">
                <span className="form-label">
                  Type d'icône de conduite
                </span>
                <select value={request.icone_conduite}  onChange = {(e)=>handlerInput(e)} name="icone_conduite" className="form-control" id="">
                  <option value="">select value</option>
                  <option value="Niveau 1">Niveau 1</option>
                  <option value="Niveau 2">Niveau 2</option>
                  <option value="Niveau 3">Niveau 3</option>
                </select>
              </label>

              <label className="block">
                <span className="form-label">
                  Type d'icône de grossesse
                </span>
                <select value={request.icone_grossesse} onChange = {(e)=>handlerInput(e)} name="icone_grossesse" className="form-control" id="">
                  <option value="">select value</option>
                  <option value="Icon 1">Icon 1</option>
                  <option value="Icon 2">Icon 2</option>
                </select>
              </label>

              <label className="block">
                <span className="">
                  Disponibilité
                </span>
                <select value={request.disponibilite} onChange = {(e)=>handlerInput(e)} name="disponibilite" className="peer form-control" id="">
                  <option selected value="">select value</option>
                  <option  value="oui">Oui</option>
                  <option value="non">Non</option>
                </select>
                <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  la Disponibilité est obligatoires
                </p>
              </label>

              <label className="block">
                <span className="form-label">
                  Message grossesse
                </span>
                <textarea value={request.message_grossesse} onChange = {(e)=>handlerInput(e)} name="message_grossesse" id="" className="form-control" cols={30} rows={4} placeholder="Message grossesse"></textarea>
              </label>

              <label className="block">
                <span className="form-label">
                  Message d'indisponibilité
                </span>
                <textarea value={request.message_indisponibilte} onChange = {(e)=>handlerInput(e)} name="message_indisponibilte" id="" className="form-control" cols={30} rows={4} placeholder="Message d'indisponibilité"></textarea>
              </label>

              <label className="block">
                <span className="">
                  Indication
                </span>
                <textarea value={request.indications} onChange = {(e)=>handlerInput(e)} name="indications" id="" required className="peer form-control" cols={30} rows={4} placeholder="Indication"></textarea>
                <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  l'indication est obligatoires
                </p>
              </label>

              <label className="block">
                <span className="">
                  Contre Indication
                </span>
                <textarea value={request.contre_indications} onChange = {(e)=>handlerInput(e)} name="contre_indications" id="" className="form-control" cols={30} rows={4} placeholder="Contre Indication"></textarea>
              </label>

              <label className="block">
                <span className="">
                  Posologie
                </span>
                <textarea value={request.posologie_et_mode_d_administration} onChange = {(e)=>handlerInput(e)} name="posologie_et_mode_d_administration" id="" required className="peer form-control" cols={30} rows={4} placeholder="Posologie"></textarea>
                <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                  la posologie est obligatoires
                </p>
              </label>

              <label className="block">
                <span className="">
                  Effets secondaire
                </span>
                <textarea value={request.effets_secondaires} onChange = {(e)=>handlerInput(e)} name="effets_secondaires" id="" className="form-control" cols={30} rows={4} placeholder="Effets secondaire"></textarea>
              </label>

              <label className="block">
                <span className="form-label">
                  Interaction medicamenteuse
                </span>
                <textarea value={request.interactions_medicamenteuses} onChange = {(e)=>handlerInput(e)} name="interactions_medicamenteuses" id="" className="peer form-control" cols={30} rows={4} placeholder="Interaction medicamenteuse"></textarea>
              </label>

              <label className="block">
                <span className="form-label">
                  Grossesse allaitement fertilité
                </span>
                <textarea value={request.grossesse_allaitement_et_fertilite} onChange = {(e)=>handlerInput(e)} name="grossesse_allaitement_et_fertilite" id="" className="peer form-control" cols={30} rows={4} placeholder="Grossesse allaitement fertilité"></textarea>
              </label>
            </div>

            <div className={`${steps.step3? "md:grid md:grid-cols-3 md:gap-4 md:grid-flow-row-dense":"hidden "} min-w-full bg-white p-3 rounded-md my-3` } id='step3'>
              <label className="block relative">
                <span className="form-label">
                  Discipline
                </span>

                <input value={request.discipline} onChange = {(e)=>handlerInput(e)} list="discipline" id="ice-cream-choice" className=" form-control" name="discipline" placeholder='Entrer un discipline' />

                <datalist id="discipline">
                  {
                    Disciplines.map((elt:any,i:number)=>{
                      return <option key={i} value={elt.name}/>
                    })
                  }
                </datalist>
              </label>

              <label className="block relative">
                <span className="form-label">
                  Classe
                </span>
                <input value={request.classe} onChange = {(e)=>handlerInput(e)} list="classe" id="ice-cream-choice" className=" form-control" name="classe" placeholder='Entrer une classe' />

                <datalist id="classe">
                  {
                    Classes.map((elt:any,i:number)=>{
                      return <option key={i} value={elt.name}/>
                    })
                  }
                </datalist>
              </label>

              <label className="block relative">
                <span className="form-label">
                  Sous-Classe
                </span>
                <input value={request.sous_classe} onChange = {(e)=>handlerInput(e)} list="sousclasse" id="ice-cream-choice" className=" form-control" name="sous_classe" placeholder='Entrer une sousclasse' />

                <datalist id="sousclasse">
                  {
                    SousClasses.map((elt:any,i:number)=>{
                      return <option key={i} value={elt.name}/>
                    })
                  }
                </datalist>
              </label>
            
            </div>
          </div>
          

          <div className="flex pb-3 justify-end  mt-3">
            
            {
              steps.step1 &&
              <button className='rounded-md bg-blue-500 px-2 text-white mx-3 w-32 hover:bg-blue-400' disabled = {disabledStatus > 0 ? false : true} onClick={(e)=>changeStep(e,"next")}>Suivant</button>
            }

            {
              steps.step2 &&
              <>
                <button className='rounded-md bg-blue-500 px-2 text-white mx-3 w-32 hover:bg-blue-400' onClick={(e)=>changeStep(e,"prev")}>Precedant</button>
                <button className='rounded-md bg-blue-500 px-2 text-white mx-3 w-32 hover:bg-blue-400' disabled = {disabledStatus > 1 ? false : true} onClick={(e)=>changeStep(e,"next")}>Suivant</button>
              </>
            }

            {
              steps.step3 &&
              <>
                <button className='rounded-md bg-blue-500 px-2 text-white mx-3 w-32 hover:bg-blue-400' onClick={(e)=>changeStep(e,"prev")}>Precedant</button>
                <button className='rounded-md bg-blue-500 px-2 text-white mx-3 w-32 hover:bg-blue-400' onClick = {(e) => updateMedicament(e)} disabled = {disabledStatus > 2 ? false : true} >
                  
                {
                  load &&
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block animate-spin mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                  </svg>
                }

                  Enregistrer</button>
              </>
            }
            
            
          </div>

      </form>
      
    </>
  )
}

export {UpdateMedicament}
