import React, { useEffect, useRef } from 'react'
import SelectSearch from 'react-select-search';
import { useState } from 'react';
import { LaboratoireService } from '../../../services/laboratoireService';
import { FicheLaboratoireModel } from '../../../model/ficheLaboratoireModel';
import { useNavigate, useParams } from 'react-router-dom';
import { FileService } from '../../../services/filesServices';
import { PersonnelService } from '../../../services/personnelServices';

function AddPersonnel() {

  const searchInput:any = useRef();
  const[request,setRequest] = useState({isActif:0} as any);
  const[data, setData] = useState([]);
  const[pictures, setPicture] = useState([]);
  const history = useNavigate();
  const[load, setLoad] = useState(false);
  const[disabledStatus, setDisabledStatus] = useState(false as boolean);
  const professions = [
    'Médecin Généraliste',
    'Médecin spécialiste',
    'Résident',
    'Pharmacien',
    'Chirugien-dentiste',
    'Sage-femme',
    'Infirmier',
    'Technicien de laboratoire',
    'Délégué médical',
    'Etudiant en Médecine',
    'Etudiant en Pharmacie',
    'Etudiant en Chirurgie dentaire',
    'Etudiant en soins infirmiers',
  ];

  const specialites = [
    'Allergologie',
    'Anatomie pathologique',
    'Anesthésie - Réanimation',
    'Biologie clinique',
    'Cardiologie',
    'Chirurgie',
    'Dermatologie',
    'Endocrinologie',
    'Santé publique',
    'Gériatrie',
    'Gynécologie et obstétrique',
    'Hépato-Gastro-entérologie',
    'Infectiologie',
    'Médecine interne',
    'Médecine du sport',
    'Médecine du travail',
    'Médecine d’urgence',
    'Néphrologie',
    'Neurologie',
    'Oncologie',
    'Ophtalmologie',
    'ORL',
    'Pédiatrie',
    'Pneumologie',
    'Psychiatrie',
    'Radiologie',
    'Urologie',
  ];

  useEffect(() => {
    handleValidateForm()
    console.log(request);
    // eslint-disable-next-line
  }, [request]);


  function handlerInput(e:any){
      const {name, value,files}:any = e.target;

      switch (name) {
        case "nom":
          setRequest({...request,nom:value});
          break;
        case "prenom":
          setRequest({...request,prenom:value});
          break;
        case "email":
          setRequest({...request,email:value});
          break;
        case "telephone":
          setRequest({...request,telephone:value});
          break;
        case "profession":
          setRequest({...request,profession:value,specialite:""});
          break;
        case "specialite":
          setRequest({...request,specialite:value});
          break;
        case "pictures":
          // implement upload multiple image base64
          let images:any = [];
          let maxImage = (files.length > 2)? 2: files.length;
          if (files.length > 2) {
            alert("Seulement les 2 premieres images vont être importées ")
          } 
          for (let i = 0; i < maxImage; i++) {
            images.push(files[i]);
          };
          setPicture(images);
          setRequest({...request, meta :"upload"});
        break;
        default:
          throw new Error("input not allow");
      }
    }

  const handlerSaveData = async (e:any) => {
    e.preventDefault();
    console.log("save");
    if(load) return;

    setLoad(true);
    var result;

      result = await FileService.uploadLotPictures(pictures, "professionnel_sante");
      console.log(result);

      if(result.status == 201) {
        let paths : Array<string> = result.data.paths;
        let urls = paths.join(";");
        console.log({...request, identite: urls});
        var res = await PersonnelService.savePersonnel({...request, identite: urls});
        console.log(res);
        setDisabledStatus(false);
        setLoad(false);
  
        if(res.status == 200) {
          setRequest({});
          history(-1);
          alert("Enregistré avec succès");
        } else {
          alert(res.data.message);
        }
  
      } else {
        alert(result.data.message);
      }
  }
  

  const handleValidateForm = () => {
    console.log(pictures);
    console.log(request);
    if(request.nom != undefined && request.prenom != undefined && request.email != undefined &&
      request.profession != undefined) {
      console.log("valid");
      setDisabledStatus(true);
    } else {
      console.log("Invalid");
      setDisabledStatus(false);
    }
  }
    

  return (
    <>
        <div className="bg-white py-3 px-6 rounded-md w-full">
          <h1 className="text-2xl border-b-2">Personnel</h1>
          <p className="text-sm text-slate-500 mt-2"> "Ajouts d'un personnel" </p>
        </div>

        <form onSubmit={(e:any)=>handlerSaveData(e)} method='POST'>
                    
          <div className="md:grid md:grid-cols-3 md:gap-4 min-w-full bg-white p-3 rounded-md my-3">
            <label className="block">
              <span className="form-label-o">
                Nom
              </span>
              <input type="text" name="nom" value={request.nom} required className="peer form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Nom" />
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                le nom du personnel est obligatoires
              </p>
            </label>

            <label className="block">
              <span className="form-label-o">
                Prénom
              </span>
              <input type="text" name="prenom" value={request.prenom} required className="peer form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Prénom" />
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                le prénom du personnel est obligatoires
              </p>
            </label>

            <label className="block">
              <span className="form-label-o">
                Email
              </span>
              <input type="text" value={request.email} name="email" required className="peer form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Email" />
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                l'email est obligatoires
              </p>
            </label>

            <label className="block">
              <span className="form-label-o">
                Adresse téléphonique 
              </span>
              <input type="number" name="telephone" value={request.telephone} required className="form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Téléphone" />
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                le telephone est obligatoires
              </p>
            </label>

            <label className="block">
              <span className="form-label-o">
              Profession
              </span>
              <input onChange = {(e)=>handlerInput(e)} list="profession" className=" form-control" name="profession" value={request.profession} placeholder="Profession" />
              <datalist id="profession">
                {
                  professions.map((elt:any,i:number)=>{
                    return <option key={i} value={elt}/>
                  })
                }
              </datalist>
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                la profession est obligatoires
              </p>
            </label>

            <label className="block">
              <span className="form-label">
              Specialite
              </span>
              <input onChange = {(e)=>handlerInput(e)} list="specialite" className=" form-control" name="specialite" value={request.specialite} placeholder="Specialite" disabled={(request.profession == "Médecin spécialiste" || request.profession == "Résident") ? false : true }  />
              <datalist id="specialite">
                {
                  specialites.map((elt:any,i:number)=>{
                    return <option key={i} value={elt}/>
                  })
                }
              </datalist>
            </label>

            <label className="block">
              <span className="form-label">
                Piece d'identite recto verso <small>( 2 images )</small>
              </span>
              <input type="file" name="pictures" accept=".jpeg,.jpg,.png" multiple className="form-control" onChange={(e:any)=>handlerInput(e)} placeholder="selectionez les images" />
            </label>
           
          </div>

          <div className="flex pb-3 justify-end ">
              <button className='rounded-md bg-blue-500 px-2 text-white mx-3 w-32 hover:bg-blue-400' disabled = {!disabledStatus} >
              {
                load &&
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block animate-spin mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
              }
                 Enregistrer </button>           
          </div>

      </form>
    </>
  )
}

export {AddPersonnel}
