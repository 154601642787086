import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../components/customModal';
import { PersonnelService } from './../../../services/personnelServices';
import IPersonnel from '../../../interfaces/IPersonnel';
import { NotificationService } from '../../../services/notificationService';
import uuid from 'react-uuid';
let baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://api.medindexapps.com/public';

function AllPersonnel() {

  const DELETE : string = "SUPRESSION";
  const ACTIVATE : string = "ACTIVATE";
  const DEACTIVATE : string = "DEACTIVATE";

  const history = useNavigate();
  const[data, setData] = useState([]);
  const[isLoad,setLoader] = useState(false);
  const[config,setConfig] = useState({ "display":"none"} as any);
  const[toogleChange, setToggele] = useState<boolean>(false);
  const[search,setSearch] = useState("");
  const[load, setLoad] = useState(false);
  const[categorie,setCategorie] = useState("categorie b");

  function openModAllert(personnel: any, msg: string, action: string) {
    setCategorie(personnel.typeComptes);
    let configs = {
        "type":"confirmation",
        "title":"Attention",
        "action": action,
        "msgConfirm": msg,
        "categorie": "",
        "close": () => {closeModal()},
        "confirm": (e:any) => {
          action === ACTIVATE?activatePersonnel(personnel): action === DEACTIVATE ? deactivatePersonnel(personnel): action === DELETE ? deleteData(personnel) : closeModal()},
        "display":"",
    };
    setConfig(configs);
  }

  function imageModal(carte: string){
    let configs = {
      "type":"",
      "title": "",
      "action":"IMAGE",
      "content": <img className="mr-2" style={{height: "500px", width: "800px"}} src ={baseUrl + carte} alt="Profile image"/>,
      "close": ()=> {closeModal()},
      "display":"",
    };
    setConfig(configs);
  }

  const deleteData = async (personnel:IPersonnel) => {
    setLoad(true);
    var res = await PersonnelService.deleteProfessionnal(personnel.id);
    setLoad(false);
    closeModal();
    getPersonnel();
    if(res.status === 200) {
      alert('Supprimé avec succès');
    } else {
      alert('Impossible de supprimer ce personnel une erreur c\'est produite');
    }
  }

  const deactivatePersonnel = async (personnel:IPersonnel) => {
    closeModal();
    setLoad(true);
    var res = await PersonnelService.deactiveProfessionnal(personnel.id);
    setLoad(false);
    getPersonnel();
    if(res.status === 200) {
      sendNotification('désactivation de compte','Mr/Mme/Mlle '+personnel.nom+" "+personnel.prenom+" votre compte a été désactivé. Pour plus d'informations, bien vouloir nous contacter à support@medindexgroup.com",personnel.TokenFire,"DESACTIVATION" );
      alert('Désactivé avec succès');
    } else {
      alert('Impossible de désactiver ce personnel une erreur c\'est produite');
    }
  }

  const activatePersonnel = async (personnel:IPersonnel) => {
    closeModal();
    setLoad(true);
    var res = await PersonnelService.activeProfessionnal(personnel.id,personnel.typeCompte);
    setLoad(false);
    getPersonnel();
    if(res.status === 200) {
      sendNotification('Activation de compte','Mr/Mme/Mlle '+personnel.nom+" "+personnel.prenom+" votre compte a été activé avec succès",personnel.TokenFire,"ACTIVATION",personnel.typeCompte);
      alert('Activé avec succès');
    } else {
      alert('Impossible d\'activer ce personnel une erreur c\'est produite');
    }
  }

  const sendNotification = (title:string, msg:string, userToken:string,type:string,categorie?:string) =>{
    let formData={
        to:userToken,
        notification:{
            title:title,
            body:msg
        },
        data:{
            title:title,
            body:msg,
            type:type,
            typeCompte: categorie,
            uuid: uuid(),
        },
    };

    NotificationService.sendNotification(formData).then(
      (response)=>{
          let data = null;
          if(response.status === 200){
              data = {
                  notification: JSON.stringify(formData.notification),
                  data:JSON.stringify(formData.data),
                  type:formData.data.type,
                  status: true,
                  genre: "Personnel",
                  channel : userToken
              };
          }else{
              data = {
                  notification: JSON.stringify(formData.notification),
                  data:JSON.stringify(formData.data),
                  type:formData.data.type,
                  status: false,
                  genre: "Personnel",
                  channel : userToken
              };
          }
          NotificationService.saveNotification(data).then(
              (resp)=>{
                  if(resp.status != 200){
                      alert("une erreur c'est produite lors de l'enregistrement");
                  }
              }
          )
      }
  ).catch(
      (error)=>{
        console.log(error);
      }
  )
  }

  //useMemo()

  function closeModal(){
      console.log(config);
      setConfig({ "display":"none"});
  }

  function navigate(to:string){
    history(to);
  }

  useEffect(() => {
    console.log('is loader')
    getPersonnel();
    
  }, []);

  const getPersonnel = async () => {
    setLoader(true);
    var res = await PersonnelService.getProfessionnal();
    if(res.status === 200) {
      setData(res.data);
    }
    setLoader(false);
  }

  return (
    <>
      <div className="bg-white p-3 rounded-md w-full">
          <h1 className="text-2xl border-b-2">Personnel</h1>
          <p className="text-sm text-slate-500 mt-2">Listes des Personnels disponibles</p>
      </div>

      {/* barre de recherche et filtre */}
      <div className="flex flex-wrap justify-between min-w-full bg-white p-3 rounded-md my-3">
          <div className="flex items-center border-2 hover:border-blue-500 hover:text-blue-500 focus-within:border-blue-500 rounded-md focus-within:text-blue-500 text-slate-400 my-1 md:w-auto w-full">
              <input type="text" className="form-control-search rounded-md mt-0 text-slate-400 w-full "  onChange={(e)=>setSearch(e.target.value)} placeholder="Rechecher d'un personnel" id="" />
              <button className="px-2 ">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
              </button>
          </div>  

          <div className="flex justify-between my-1 md:w-auto w-full">
              <button className="px-2 " title="Tri en croissant">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
                  </svg>
              </button>
              <button className="px-2 " title="Tri en décroissant">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
                  </svg>
              </button>
              {/* <button className="px-2 " title="Ajouter un medicament" onClick={()=>navigate("add")}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button> */}
          </div>              
        </div>
        {/* barre de recherche fin */}

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">

          <table className="w-full text-sm text-left">
              <thead className="bg-slate-400 text-slate-50 text-xs uppercase border-b border-gray-400">
                  <tr>
                      <th scope="col" className="px-6 py-3">
                          Carte
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Nom complet
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Email
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Téléphone
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Profession
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Spécialité
                      </th>
                      <th scope="col" className="px-6 py-3">
                         Catégorie
                      </th>
                      <th scope="col" className="px-6 py-3">
                         
                      </th>
                  </tr>
              </thead>
              <tbody>
                {
                  !isLoad && data.length > 0 &&
                  data.filter((personnel: IPersonnel)=>personnel.nom.toLocaleLowerCase().includes(search.toLocaleLowerCase())).map((personnel: IPersonnel)=> {
                   
                    let cartes: Array<string> = []; 
                    if(personnel.cartes != null && personnel.cartes.split(";").length > 0){
                     cartes = personnel.cartes.split(";");
                    }
                    
                    return (<tr className="bg-white border-b border-gray-200">
                      <td className="px-6 py-4 d-flex">
                        {
                          cartes.length > 0?
                          cartes.map((carte: string) => {
                            return <>
                              <img className="inline object-cover w-16 h-16 mr-2" onClick={() => imageModal(carte)} src = {baseUrl + carte} alt="Profile image"/> 
                            </>
                          }):
                          "/"
                        }
                      </td>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                        {personnel.nom + " " + personnel.prenom} 
                      </th>
                      <td className="px-6 py-4">
                        {personnel.email}
                      </td>
                      <td className="px-6 py-4">
                        {personnel.telephone}
                      </td>
                      <td className="px-6 py-4">
                      {personnel.profession}
                      </td>
                      <td className="px-6 py-4">
                      {personnel.specialite}
                      </td>
                      <td className="px-6 py-4">
                        <select name="" className="form-control" value={personnel.typeCompte} id="" onChange={(e)=>{personnel.typeCompte=e.target.value}}>
                            <option value="categorie a" selected={personnel.typeCompte == "categorie a"? true:false}>Categorie A</option>
                            <option value="categorie b" selected={personnel.typeCompte == "categorie b"? true:false}>Categorie B</option>
                            <option value="categorie c" selected={personnel.typeCompte == "categorie c"? true:false}>Categorie C</option>
                        </select>
                      </td>
                      <td className="px-6 py-4 text-right">
                        {personnel.isActif === 0 && <button type="button" id={personnel.id.toString()} onClick={() => !load && openModAllert(personnel, "Vous allez activer ce personnel!", ACTIVATE)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                          {
                            load &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block animate-spin mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                          }
                          {load ? "Activation" : "Activer"}</button>}
                        {personnel.isActif === 1 && <button type="button" onClick={() => !load && openModAllert(personnel, "Vous allez désactiver ce personnel!", DEACTIVATE)} className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900">
                          {
                            load &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block animate-spin mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                          }
                          {load ? "Désactivation" : "Désactiver"}</button>}

                        <button type="button" onClick={() => !load && openModAllert(personnel, "Vous allez supprimer ce personnel!", DELETE)} className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                          Supprimer
                          </button>
                      </td>
                    </tr>);
                    
                  })
                }
                {
                  
                  data.length === 0 &&
                  <tr>
                        <th scope="row" colSpan={7} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                          Aucun enregistrement de personnel disponible
                        </th>
                  </tr>
                  
                }
                
              </tbody>
          </table>
          
        </div>
        <CustomModal Classe='' config={config}/>   

        
    </>
  )
}

export {AllPersonnel}
