import React from 'react'

function AddNotification() {
  return (
    <div>
      add Notification
    </div>
  )
}

export {AddNotification}
