import { ApiProvider } from "../providers/apiProvider";


export const FileService = {
    uploadLotPictures,
    uploadPicture
};

async function uploadLotPictures(pictures: any[], pathDirectory: string){
    let data = pictures;
    let formData = new FormData();
    data.forEach((elt : any,i:number) => {
        formData.append(`files[]`,elt);
    });
    formData.append('package_location', pathDirectory);
    console.log(formData);

    let res = await ApiProvider.upload("upload-files",formData);
    return res;
}

async function uploadPicture(picture: any){
    let data = picture;
    let formData = new FormData();
    formData.append(`file`,picture);
    console.log(formData);

    let res = await ApiProvider.upload("upload-file",formData);
    return res;
}