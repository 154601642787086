import React from 'react';
import './App.css';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import Dashboard from './Dashboard/views/Dashboard';
import Login from './Dashboard/views/login';
import { AllMedicament, AddMedicament, UpdateMedicament } from './Dashboard/views/medicaments';
import { AllLaboratoires,AddLaboratoire,AddExamen,AllExamen } from './Dashboard/views/laboratoires';
import { Statistique } from './Dashboard/views/stats';
import { AddPharmacie,AllPharmacies } from './Dashboard/views/pharmacies';
import { AllPersonnel } from "./Dashboard/views/personnels/AllPersonnel";
import { Pharmaco } from './Dashboard/views/pharmacoVigilence/Pharmaco';
import { AddNotification,AllNotification } from './Dashboard/views/notifications';
import PrivateAuth from './routes/privateAdmin';
import PublicAdmin from './routes/publicAdmin';
import { AddPersonnel } from './Dashboard/views/personnels/AddPersonnel';
import { AddAnonce } from './Dashboard/views/medicaments/AddAnonce';
import { ListAnnonce } from './Dashboard/views/Annonce/list-annonce';


function App() {

  const getToken = () =>{
    let tokens:any = localStorage.getItem("token");
   return tokens
  }
  
  return (
    <BrowserRouter>
      <Routes>
      
        <Route path="/Dashboard" element={ <PrivateAuth token={getToken()}> <Dashboard /></PrivateAuth> }>
          <Route index element={ <Statistique />} />
          <Route path="Medicament"  element={ <AllMedicament />} />
          <Route path="Medicament/Add" element={ <AddMedicament />} />
          <Route path="Medicament/update" element={ <UpdateMedicament />} />
          <Route path="Medicament/:medicamentId/annonce" element={ <AddAnonce />} />
          <Route path="Annonce"  element={ <ListAnnonce />} />
          <Route path="Annonce/Add"  element={ <AddAnonce />} />
          <Route path="Pharmacie" element={ <AllPharmacies />} />
          <Route path="Pharmacie/Add" element={ <AddPharmacie />} />
          <Route path="Pharmacie/Edit/:pharmacieId" element={ <AddPharmacie />} />
          <Route path="Laboratoire" element={ <AllLaboratoires />} />
          <Route path="Laboratoire/Add" element={ <AddLaboratoire />} />
          <Route path="Laboratoire/Edit/:laboratoireId" element={ <AddLaboratoire />} />
          <Route path="Laboratoire/:laboratoireId/Examen" element={ <AllExamen />} />
          <Route path="Laboratoire/:laboratoireId/Examen/Add" element={ <AddExamen />} />
          <Route path="Laboratoire/Edit/:laboratoireId" element={ <AddLaboratoire />} />
          <Route path="Notifications" element={ <AllNotification />} />
          <Route path="Notifications/Add" element={ <AddNotification />} />
          <Route path="Pharmaco-vigilance" element={ <Pharmaco />} />
          <Route path="Personnel" element={ <AllPersonnel />} />
          <Route path="Personnel/add" element={ <AddPersonnel />} />
        </Route>
        <Route path="/Auth" element={<PublicAdmin token={getToken()}> <Login /></PublicAdmin> } />
        <Route
        path="*"
        element={<Navigate to="/Auth" />}
        />
      </Routes>
  </BrowserRouter>
  );
}

export default App;
