import React, { useState } from 'react';
import { NotificationService } from './../services/notificationService';

const SlideModal = ({config}:any) => {

    const[load, setLoad] = useState<boolean>(false);
    const[notification, setNotification] = useState({} as any);
    const[error,setError] = useState({} as any);

    const closeModal = config.close;
    const onreload = config.success;
    
    const handleSave =()=>{
        if (checkNullOrEmpty(notification.title) || checkNullOrEmpty(notification.body) || checkNullOrEmpty(notification.type)) {
            setError({isError: true,msg:"veuillez remplir tout les champs oblifatoire"});
        }else{
            setError({isError: false,msg:""});
            console.log(notification);
            let formData={
                to:"/topics/all",
                notification:{
                    title:notification.title,
                    body:notification.body
                },
                data:{
                    title:notification.title,
                    body:notification.body,
                    type:notification.type
                },
            };
            NotificationService.sendNotification(formData).then(
                (response)=>{
                    let data = null;
                    if(response.status === 200){
                        data = {
                            notification: JSON.stringify(formData.notification),
                            data:JSON.stringify(formData.data),
                            type:formData.data.type,
                            status: true,
                            genre: "all",
                            channel: formData.to,
                        };
                    }else{
                        data = {
                            notification: JSON.stringify(formData.notification),
                            data:JSON.stringify(formData.data),
                            type:formData.data.type,
                            status: false,
                            genre: "all",
                            channel: formData.to,
                        };
                    }
                    NotificationService.saveNotification(data).then(
                        (resp)=>{
                            if(resp.status === 200){
                                setLoad(false);
                                onreload();
                                closeModal();
                            }else{
                                setLoad(false);
                                alert("une erreur c'est produite lors de l'enregistrement");
                            }
                        }
                    )
                }
            ).catch(
                (error)=>{
                    console.log(error);
                  }
            )
        }
    }

    function checkNullOrEmpty(val:any) {
        return (val === null || val === "" || val === undefined)?true:false;
    }

    const handleChnage = (e:any) =>{
        const {name, value} = e.target;

        switch (name) {
            case "title":
                setNotification({...notification,title:value});
                break;
            case "body":
                setNotification({...notification,body:value});
                break;
            case "type":
                setNotification({...notification,type:value});
                break;
            default:
                throw new Error("invalid input");
        }
    }
    
  return (
    <div className={`absolute inset-0 flex justify-end items-center bg-modal ${config.display}`} style={{zIndex: 1001}}>
      <div className='bg-white h-full md:w-1/3 w-full'>
            <div className='md:flex items-center justify-between border-b-2 border-slate-300 pb-3 px-5 py-5 sticky top-0 bg-slate-100 mb-'>
                <h1 className='mb-3'>{config.title}</h1>

                <button className='my-2' id="close-modal" onClick={config.close}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                </button>
            </div>

            {
                (config.action === "SEND_NOTIFICATION") &&
                <div className="px-5 py-4">
                    {
                        error.isError && 
                        <div className='shadow-xl drop-shadow-md p-2 mb-3'>
                            <small className='text-red-400  ' >{error.msg}</small>
                        </div>
                        
                    }
                    <label className="block">
                        <span className="form-label-o">
                            Titre de la notifcation
                        </span>
                        <input type="text" name="title" required className="peer form-control" placeholder="Entre le titre" onChange={(e)=>handleChnage(e)}/>
                        <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                            le Titre est obligatoire
                        </p>
                    </label>

                    <label className="block">
                        <span className="form-label-o">
                            Contenus
                        </span>
                        <textarea name="body" id="" required className="peer form-control" cols={30} placeholder="Entre le contenu" onChange={(e)=>handleChnage(e)}></textarea>
                        <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                            le Contenu est obligatoire
                        </p>
                    </label>

                    <label className="block">
                        <span className="form-label-o">
                            Type de notification
                        </span>
                        <select name="type" required onChange={(e)=>handleChnage(e)} className="peer form-control">
                            <option value="">Selectionner un type</option>
                            <option value="Event">Evénement</option>
                            <option value="Update">Mise a jour</option>
                        </select>
                        <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                            le Choix du type d'evenement est obligatoire
                        </p>
                    </label>
                </div>
            }

            {
                config.type === "popup" &&
                <div className='flex justify-between px-5'>
                    <button  className={`rounded-md ${load ? "bg-blue-400":"bg-blue-800"} text-white px-3 py-1 my-2 text-center`} onClick={handleSave} disabled={load} >
                        {
                            load &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block animate-spin mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                        }
                        Envoyer
                    </button>
                    <button className='rounded-md bg-rose-800 text-white px-3 py-1 my-2' onClick={config.close} id="close-modal">Fermer</button>
                </div>
            }
      </div>
    </div>
  )
}

export {SlideModal}
