import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { SlideModal } from './../../../components/slideModal';
import { NotificationService } from './../../../services/notificationService';
import uuid from 'react-uuid';

function AllNotification() {

    const [config,setConfig]=useState({ "display":"none"} as any);
    const [toogleChange, setToggele] = useState<boolean>(false);
    const [data, setData] = useState([] as any);
    const [isLoad,setLoader]=useState(false);

    useEffect(() => {
        console.log('is loader')
        getNotifications();
        // eslint-disable-next-line
    }, [toogleChange]);

    function createNotification(){
        let configs = {
            "type":"popup",
            "title":"Envoi de notification",
            "action":"SEND_NOTIFICATION",
            "close": ()=>{closeModal()},
            "success": ()=>{reloadMedicament()},
            "display":"",
        };

        setConfig(configs);
    }

    function closeModal(){
        console.log(config);
        setConfig({ "display":"none"});
    }

    function reloadMedicament(){
        setToggele((prev:boolean) => prev = !prev)
    }

    function getNotifications(){
        NotificationService.getNotification().then(
            (response)=>{
                setData(response.data);
                setLoader(false);
            }
        ).catch(
            (error: any)=>{
                console.log(error);
                setData([]);
                setLoader(true);
            }
        )
    }

    const sendNotification = (notification:any) =>{
        let formData={
            to:notification.channel,
            notification:{
                title: JSON.parse(notification.notification).title,
                body:JSON.parse(notification.notification).body,
            },
            data:{
                title: JSON.parse(notification.data).title,
                body: JSON.parse(notification.data).body,
                type: JSON.parse(notification.data).type,
                uuid: uuid(),
            },
        };
    
        NotificationService.sendNotification(formData).then(
          (response)=>{
              let data = null;
              if(response.status === 200){
                  data = {
                      id: notification.id,
                      notification: JSON.stringify(formData.notification),
                      data:JSON.stringify(formData.data),
                      type:formData.data.type,
                      status: true,
                      genre: (notification.channel === "/topics/all")?"all":"Personnel",
                      channel : notification.channel
                  };
              }else{
                  data = {
                      id: notification.id,
                      notification: JSON.stringify(formData.notification),
                      data:JSON.stringify(formData.data),
                      type:formData.data.type,
                      status: false,
                      genre: (notification.channel === "/topics/all")?"all":"Personnel",
                      channel : notification.channel
                  };
              }
              NotificationService.updateNotification(data).then(
                  (resp)=>{
                      if(resp.status != 200){
                          alert("une erreur c'est produite lors de l'enregistrement");
                      }
                  }
              )
          }
      ).catch(
          (error)=>{
            console.log(error);
          }
      )
      }

  return (
    <>
        <div className="bg-white p-3 rounded-md w-full">
            <h1 className="text-2xl border-b-2">Notifications </h1>
            <p className="text-sm text-slate-500 mt-2">Envois de notifications </p>
        </div>
        
        {/* barre de recherche et filtre */}
        <div className="flex flex-wrap justify-between min-w-full bg-white p-3 rounded-md my-3">
            <div className="flex items-center border-2 hover:border-blue-500 hover:text-blue-500 focus-within:border-blue-500 rounded-md focus-within:text-blue-500 text-slate-400 my-1 md:w-auto w-full">
                {/* <input type="text" className="form-control-search rounded-md mt-0 text-slate-400 w-full "  placeholder="Rechecher sur les pharmco" id="" /> */}
                {/* <button className="px-2 ">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </button> */}
            </div>  

            <div className="flex justify-between my-1 md:w-auto w-full">
                <button className="px-2 " title="Tri en croissant">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
                    </svg>
                </button>
                <button className="px-2 " title="Tri en décroissant">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
                    </svg>
                </button>
                <button className="px-2 " title="Ajouter un medicament" onClick={()=>{createNotification()}}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </button>
            </div>              
        </div>
        {/* barre de recherche fin */}

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">

          <table className="w-full text-sm text-left">
              <thead className="bg-slate-400 text-slate-50 text-xs uppercase border-b border-gray-400">
                  <tr>
                      <th scope="col" className="px-6 py-3">
                          N°
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Titre
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Message
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Type de notification
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Cible
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Status
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Date
                      </th>
                      <th scope="col" className="px-6 py-3">
                         
                      </th>
                  </tr>
              </thead>
              <tbody>
                {
                    isLoad && 
                    
                    <tr className="bg-white border-b border-gray-200">
                        <td colSpan={7} className="flex flex-col justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-blue-400 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                            <p className="mt-3">Chargement des notifications ...</p>
                        </td>
                    </tr>
                }
                {
                    !isLoad &&
                    data.map((notif:any,i:number)=>{
                        return(
                            <tr key={i} className="bg-white border-b border-gray-200">
                                <td className="px-6 py-4 d-flex">
                                    {i+1}
                                </td>
                                <td scope="row" className="px-6 py-4 font-semibold text-gray-900 whitespace-nowrap">
                                    {JSON.parse(notif.notification).title}
                                </td>
                                <td className="px-6 py-4">
                                    {JSON.parse(notif.notification).body}
                                </td >
                                <td className="px-6 py-4">
                                    {JSON.parse(notif.data).type}
                                </td>
                                <td className="px-6 py-4">
                                    {notif.genre}
                                </td>
                                <td className="px-6 py-4">
                                    <small>
                                        {notif.status === 1?"Envoyé":"echec"}
                                    </small>
                                </td>
                                <td className="px-6 py-4">
                                    {moment(notif.created_at).format("YYYY/MM/DD  hh:mm")}
                                </td>
                                <td className="px-6 py-4">
                            
                                    <button className='mb-2 text-green-500 mx-2'  title='Reessayer' onClick={()=>sendNotification(notif)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                        </svg>
                                    </button>
                                    
                                </td>
                            </tr>
                        );
                    })
                    
                }
                
              </tbody>
          </table>
          
        </div>
        <SlideModal config={config} />
    </>
  )
}

export {AllNotification}
