import { FicheExamenModel } from "../model/ficheExamenModel";
import { ApiProvider } from "../providers/apiProvider";


export const ExamenService ={
    saveExamen,
    getExaments,
    saveLotExamen,
    // updateExame,
    // deleteExamen,
};

async function saveExamen(laboratoireId:any,examen:FicheExamenModel){
    let data = examen;
    let res = await ApiProvider.post("saveExamen/"+laboratoireId,data);
    return res;
}

async function saveLotExamen(laboratoireId:any,examens:FicheExamenModel[]){
    let data ={examens: examens} ;
    let res = await ApiProvider.post("saveLotExamen/"+laboratoireId,data);
    return res;
}

async function getExaments(laboratoireId:any){
    let res = await ApiProvider.get("all-examen-laboratoire/"+laboratoireId);
    return res;
}



// async function updateExame(laboratoireId:any,examens:FicheExamenModel){
//     let data = examens;
//     let res = await ApiProvider.post("updateLaboratoire",data);
//     return res;
// }

// async function deleteExamen(laboratoireId:any){
//     let res = await ApiProvider.get("deleteLaboratoire/"+laboratoireId);
//     return res;
// }