import React, { useState} from 'react';
import * as XLSX from 'xlsx';
import { FicheMedicamentModel } from "../model/ficheMedicamentModel";
import { FichePharmacieModel } from '../model/fichePharmacieModel';
import { MedicamentService } from "../services/medicamentService";
import { PharmacieService } from '../services/pharmacieService';
import moment from 'moment';
import { FichePharmacieGardeModel } from '../model/fichePharmacieGardeModel';
import { FicheExamenModel } from '../model/ficheExamenModel';
import { ExamenService } from '../services/examenService';
//import Toast from './Toast';


const CustomModal = ({Classe,config}:any) => {
    console.log('je suis dans profile');
    const[datafils, setData] = useState<any[]>([]);
    const[load, setLoad] = useState<boolean>(false);
    const[header, setHeader] = useState<any[]>([]);
    const[type, setType] = useState<string>(config?.defaultType);
    //const[propsList, setPropsList] = useState([] as any);

    const handleFileUpload = (e:any) => {
        //setOpenLoading({display:true,message:"Importation du fichier en cour ..."});
        
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt:any) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws);
        processData(data);
        };
        reader.readAsBinaryString(file);    
        //setOpenLoading({display:false,message:""});
    }

    // process CSV data
    const processData = (dataString:any) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers: String[] = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        const chars:any = {',':'','-':'_',' ':'_','.':'','/':'_','"':'',"'":'_'};
    
        const list:any = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            // console.log(row);
            if (headers && row.length === headers.length) {
                const obj:any = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] === '"')
                        d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] === '"')
                        d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        let head:string = headers[j].replaceAll(/[,-\s./"']/g, m => chars[m]).toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                        if(head.substr(head.length - 1) === "_"){
                            head = head.slice(0, -1);
                        }
                        obj[head] = d;
                    }
                }
                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                list.push(obj);
                }
            }
        }
        console.log(list);
        console.log(headers);
        setData([...list]);  
        setHeader(headers.map(elt => elt.replaceAll(/[,-\s./"']/g, m => chars[m]).toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))); 
    }

    function checkNullOrEmpty(val:any) {
        return (val === null || val === "" || val === undefined)?true:false;
    }

    const closeModal = config.close;

    const onreload = config.success;
  
    const handleSave = () => {
        setLoad(true);
        // console.log(datafils);
        if(datafils.length > 0){

            switch (type) {
                case "Pharmacie":

                    let fichePharmacies = Array<FichePharmacieModel>();
                    datafils.forEach((elt:FichePharmacieModel)=>{
                        if(elt.noms_de_la_pharmacie !== null && elt.noms_de_la_pharmacie !== ""){
                            fichePharmacies.push(new FichePharmacieModel(elt));
                        }
                        
                    });
                    let firstLinePharmacie = fichePharmacies[0];
                    //console.log(firstLinePharmacie);
                    if(checkNullOrEmpty(firstLinePharmacie.noms_de_la_pharmacie)||checkNullOrEmpty(firstLinePharmacie.villes)){
                        alert("format  du fichier ne correspond pas ");
                        // setOpenLoading({display:false,message:""});
                        setLoad(false);
                    } else {
                        console.log(fichePharmacies);
                        PharmacieService.saveLotPharmacie(fichePharmacies).then(
                            (response)=>{
                                if (response.status === 200) {
                                    //console.log(response);
                                    setLoad(false);
                                    onreload();
                                    closeModal();
                                }else{
                                    setLoad(false);
                                    alert("une erreur c'est produite lors de l'enregistrement");
                                }
                                // setOpenLoading({display:false,message:""});
                            }
                        )
                        .catch(
                            (error)=>{
                                //console.log(error);
                                setLoad(false);
                                closeModal();
                                throw new Error(error);
                                // setOpenLoading({display:false,message:""});
                            }
                        );
                    }
                    break;
                case "Pharmacies de gardes":

                    let fichePharmaciesGarde = Array<FichePharmacieGardeModel>();
                    fichePharmaciesGarde = formatingPharmacieGarde();
                    PharmacieService.saveLotPharmacieGarde(fichePharmaciesGarde).then(
                        (response)=>{
                            if (response.status === 200) {
                                setLoad(false);
                                onreload();
                                closeModal();
                            }else{
                                setLoad(false);
                                alert("une erreur c'est produite lors de l'enregistrement");
                            }
                        }
                    ).catch(
                        (error)=>{
                            console.log(error);
                            setLoad(false)
                            closeModal()
                            // setOpenLoading({display:false,message:""});
                        }
                    );
                    
                    break;
                case "examen":
                    let ficheExamens = Array<FicheExamenModel>();
                    datafils.forEach((elt:FicheExamenModel)=>{
                        ficheExamens.push(new FicheExamenModel(elt));
                    });

                    ExamenService.saveLotExamen(config.other.laboratoireId,ficheExamens).then(
                        (response)=>{
                            if (response.status === 200) {
                                console.log(response);
                                setLoad(false);
                                onreload();
                                closeModal();
                            }else{
                                setLoad(false);
                                alert("une erreur c'est produite lors de l'enregistrement");
                            }
                        }
                    ).catch(
                        (error)=>{
                            console.log(error);
                            setLoad(false)
                            closeModal()
                            // setOpenLoading({display:false,message:""});
                        }
                    )

                    closeModal()
                    
                    break;
                
                default:
                    let ficheMedicaments = Array<FicheMedicamentModel>();
                    datafils.forEach((elt:FicheMedicamentModel)=>{
                        ficheMedicaments.push(elt);
                    });
                    let firstLine = ficheMedicaments[0];
                    console.log(firstLine);
                    
                    if(checkNullOrEmpty(firstLine.prix_cession) || checkNullOrEmpty(firstLine.prix_public) || checkNullOrEmpty(firstLine.nom_commercial)){
                        alert("format  du fichier ne correspond pas ");
                        // setOpenLoading({display:false,message:""});
                        setLoad(false)
                    } else {
                        
                        MedicamentService.saveLotMedicament(ficheMedicaments).then(
                            (response)=>{
                                console.log(response);
                                setLoad(false);
                                onreload();
                                closeModal();
                                // setOpenLoading({display:false,message:""});
                            }
                        )
                        .catch(
                            (error)=>{
                                console.log(error);
                                setLoad(false)
                                closeModal()
                                // setOpenLoading({display:false,message:""});
                            }
                        )
                    }
                    break;           
            }
        }else{
            console.log(config.close);
            setLoad(false)
            closeModal();
        }
    }

    function formatingPharmacieGarde(){
        let headerPharmacieVille = header.filter(elt=> elt.includes('pharmacies'));
        let pharmaciesArray: any[] = [];
        let pharmaciesGarde: any[] = [];
        datafils.forEach((elt:any) => {
            let keysObj:any[] =  Object.keys(elt);
            // console.log(keysObj);
            // console.log("======================");
            // console.log(headerPharmacieVille);
            let dateGarde = elt.jour_de_garde;
            console.log(dateGarde);
            headerPharmacieVille.forEach((key:string)=>{
                let isExist = keysObj.includes(key);
                let ville = key.replace("pharmacies_",'');
                // console.log(moment(dateGarde).format("YYYY-MM-DD"));
                if(isExist !== null && isExist !== false){
                    let pharmaciesHastag:string = elt[key];
                    if(pharmaciesHastag !== null && pharmaciesHastag !== ""){
                        pharmaciesArray = pharmaciesHastag.split("#");
                        //console.log(pharmaciesArray.slice(1));
                        pharmaciesArray.slice(1).forEach(pharmacie=>{
                            let garde = {
                                ville: ville,
                                nom: pharmacie,
                                date_debut: moment(dateGarde).format("YYYY-MM-DD"),
                                date_fin : moment(dateGarde).add(1,"days").format("YYYY-MM-DD")
                            };

                            pharmaciesGarde.push(garde)
                        })
                    }
                }
            })
        });
        return pharmaciesGarde;
    }

  return (
    <div className={`absolute inset-0 flex justify-center items-center bg-modal ${config.display}`} id="view-modal" style={{zIndex: 1001}}>
        <div className={`bg-white p-3 rounded-md ${Classe}`} >
            {
                config.title != null  &&
                <div className='flex justify-between border-b-2'>
                    <h3 className='text-lg font-medium'>{config.title}</h3>
                    <button className='my-2' id="close-modal" onClick={config.close}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </button>
                </div>
            }
            
            {
                (config.action === "IMPORT_MEDICAMENT" || config.action === "IMPORT_PHARMACIE" || config.action === "IMPORT_EXAMEN") &&
                <div className="px-2 py-4 s">
                    <input type="file"
                            id="fileXls"
                            accept=".csv,.xlsx,.xls"
                            onChange={handleFileUpload} />
                    {
                        config.action === "IMPORT_PHARMACIE" &&
                        <>
                            <label className="block">
                                <span className="form-label">
                                Type d'import 
                                </span>


                                <select name="" className="form-control" id="" onChange={(e)=>{setType(e.target.value)}}>
                                    <option value="Pharmacies">Pharmacies</option>
                                    <option value="Pharmacies de gardes">Pharmacies de gardes</option>
                                </select>
                            </label>
                        </>
                    }

                </div>
            }
            {
                config.action === "SUPRESSION" &&
                <div className="px-2 py-4 ">
                    {config.msgConfirm}
                </div>
            }

            {
                config.action === "ACTIVATE" &&
                <div className="px-2 py-4 ">
                    
                   {config.categorie}

                    {config.msgConfirm}
                </div>
            }

            {
                config.action === "DEACTIVATE" &&
                <div className="px-2 py-4 ">
                    {config.msgConfirm}
                </div>
            }

            {
                config.action === "DETAIL" &&
                <div className="py-2">
                    {config.content}
                </div>
            }

            {
                config.action === "IMAGE" &&
                <div className="py-2">
                    {config.content}
                </div>
            }

            <div className='border-t-2'>
                {
                    config.type === "alert" &&
                    <div className='flex justify-end'>
                        <button className='rounded-md bg-rose-800 text-white px-3 py-1 my-2' id="close-modal">Fermer</button>
                    </div>
                }
                {
                    config.type === "dialog" &&
                    <div className='flex justify-center'>
                        <button className='rounded-md bg-blue-700 text-white px-3 py-1 my-2' id="close-modal">Ok</button>
                    </div>
                }
                {
                    config.type === "popup" &&
                    <div className='flex justify-between'>
                        <button  className={`rounded-md ${load ? "bg-blue-400":"bg-blue-800"} text-white px-3 py-1 my-2 text-center`} onClick={handleSave} disabled={load} >
                            {
                                load &&
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block animate-spin mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>
                            }
                            Valider
                        </button>
                        <button className='rounded-md bg-rose-800 text-white px-3 py-1 my-2' onClick={config.close} id="close-modal">Fermer</button>
                    </div>
                }

                {
                    config.type === "confirmation" &&
                    <div className='flex justify-between'>
                        <button  className={`rounded-md bg-blue-800 text-white px-3 py-1 my-2 text-center`} onClick={config.confirm} >
                            Confirmer
                        </button>
                        <button className='rounded-md bg-rose-800 text-white px-3 py-1 my-2' onClick={config.close} id="close-modal">Fermer</button>
                    </div>
                }

                
            </div>
        </div>
        {/* <Toast 
            toastList={propsList}
            position="bottom-right"
            autoDelete={true}
            dismissTime={10}
        />    */}
    </div>
  )
}

export default CustomModal;
