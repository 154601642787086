import React, { useEffect, useRef, useState } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import { PharmacieService } from '../../../services/pharmacieService';

const AddPharmacie = () => {
  const searchInput:any = useRef();
  const[request,setRequest] = useState({isOpen:"1"} as any);
  const[data, setData] = useState([]);
  const[ville, setVille] = useState({} as any);
  const history = useNavigate();

  const {pharmacieId} = useParams();

  useEffect(() => {
    console.log('is loader')
    getPropVilles();
    // eslint-disable-next-line
    if (pharmacieId != undefined) {
      getPharmacie(pharmacieId);
    }
  }, []);

const getPropVilles = () =>{
  PharmacieService.getVilles().then(
      (response)=>{
        let villes = response.data.map((elt:any)=>{
          return { name: elt.name, target:"ville", value: elt.id };
        });
          setData(villes);
      }
  ).catch(
      (error: any)=>{
          console.log(error);
      }
  )
}

  const options:any = [
    {
      type: "group",
      name: "Atlanta",
      items: [
        { name: "Workshop One", value: "1" },
        { name: "Workshop Two", value: "2" }
      ]
    },
    {
      type: "group",
      name: "Charleston",
      items: [
        { name: "Workshop Three", value: "3" },
        { name: "Workshop Four", value: "4" },
        { name: "Workshop Five", value: "5" }
      ]
    },
    {
      type: "group",
      name: "Inactive",
      items: [{ name: "Inactive Workshop", value: "100" }]
    }
  ];

  const handleChange = (...args: any[]) => {
    // searchInput.current.querySelector("input").value = "";
    console.log("ARGS:", args);
    setVille({ville_id:args[0]})
  };

  const handlerFilter = (items: any[]) => {
    return (searchValue: string) => {
      if (searchValue.length === 0) {
        return items;
      }
      
      const updatedItems = items.filter((item: { name: string; }) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      });
      return updatedItems;
    };
  };

  function handlerInput(e:any){
    const {name, value}:any = e.target;

    switch (name) {
      case "name":
        setRequest({...request,name:value});
        break;
      case "emplacement":
        setRequest({...request,emplacement:value});
        break;
      case "contact1":
        setRequest({...request,contact1:value});
        break;
      case "contact2":
        setRequest({...request,contact2:value});
        break;
      case "coordonnee":
        setRequest({...request,coordonnee:value});
        break;
      case "start_open":
        setRequest({...request,start_open:value});
        break;
      case "end_open":
        setRequest({...request,end_open:value});
        break;
      case "isOpen":
        setRequest({...request,isOpen:value});
        break;
      default:
        throw new Error("input not allow");
    }
  }

  const handlerSaveData = (e:any) => {
    e.preventDefault();
    let data = {...request,...ville};
    //let pharmacie: FicheLaboratoireModel = new FicheLaboratoireModel(data);
    if(pharmacieId != undefined){
      PharmacieService.updatePharmacie(data).then(
        (rep)=>{
          if(rep.status === 200){
            history("/Dashboard/Pharmacie",{ replace: true })
          }else{
            alert("une erreur c'est produite")
          }
        }
      ).catch(
        (error)=>{
          alert("une erreur popre au systeme");
        }
      )
    }else{
      PharmacieService.savePharmacie(data).then(
        (rep)=>{
          if(rep.status === 200){
            history("/Dashboard/Pharmacie",{ replace: true })
          }else{
            alert("une erreur c'est produite")
          }
        }
      ).catch(
        (error)=>{
          alert("une erreur popre au systeme");
        }
      )
    }  
  }

  function getPharmacie(pharmacieId:any){
    PharmacieService.getPharmacie(pharmacieId).then(
      (response)=>{
          console.log(response.data);
          setRequest(response.data);
          setVille({ville_id:response.data.ville_id});
      }
    ).catch(
        (error: any)=>{
            console.log(error)
        }
    )
  }

  return (
    <>
        <div className="bg-white py-3 px-6 rounded-md w-full">
          <h1 className="text-2xl border-b-2">Pharmacies</h1>
          <p className="text-sm text-slate-500 mt-2"> {pharmacieId != undefined? "Edition d'une Pharmacie":"Ajouts des Pharmacies" }  </p>
        </div>

        <form onSubmit={(e:any)=>handlerSaveData(e)} method='POST'>
                    
          <div className="md:grid md:grid-cols-3 md:gap-4 min-w-full bg-white p-3 rounded-md my-3">
            <label className="block">
              <span className="form-label-o">
                Nom de la pharmacie
              </span>
              <input type="text" value={request.name} name="name" required className="peer form-control"  onChange={(e:any)=>handlerInput(e)} placeholder="Nom de la pharmacie" />
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                le nom de la pharmacie est obligatoires
              </p>
            </label>

            <label className="block relative">
              <span className="form-label-o">
                Ville
              </span>
              <SelectSearch
                ref={searchInput}
                options={data}
                filterOptions={handlerFilter}
                value={ville?.ville_id}
                className="form-control"
                placeholder="Select ville"
                search
                onChange={handleChange}
              />
            </label>

            <label className="block">
              <span className="form-label-o">
                Emplacement
              </span>
              <input type="text" value={request.emplacement } name="emplacement" required className="peer form-control"  onChange={(e:any)=>handlerInput(e)} placeholder="Emplacement" />
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                l'emplacement est obligatoires
              </p>
            </label>

            <label className="block">
              <span className="form-label">
                Coordonnée geographique
              </span>
              <input type="text" value={request.coordonnee} name="coordonnee"  className="form-control"  onChange={(e:any)=>handlerInput(e)} placeholder="latitude,longitude (10.56 , 5.5648) " />
            </label>

            <label className="block">
              <span className="form-label-o">
                Adresse téléphonique 1 
              </span>
              <input type="text" value={request.contact1} name="contact1" required className="peer form-control"  onChange={(e:any)=>handlerInput(e)} placeholder="Téléphone 1" />
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                l'adresse téléphonique 1 est obligatoires
              </p>
            </label>

            <label className="block">
              <span className="form-label">
                Adresse téléphonique 2 
              </span>
              <input type="text" value={request.contact2} name="contact2" className="form-control"  onChange={(e:any)=>handlerInput(e)} placeholder="Téléphone 2" />
            </label>

            <label className="block">
              <span className="form-label">
                Ouverture
              </span>
              <select name="isOpen" className="form-control"  onChange={(e:any)=>handlerInput(e)}>
                <option selected={pharmacieId != undefined && request.isOpen === 1?true :false} value={1}>Ouvert 24/24</option>
                <option selected={pharmacieId != undefined && request.isOpen === 0?true :false} value={0}>Autres</option>
              </select>
            </label>

            <label className="block">
              <span className={`${request.isOpen === "1"? "form-label":"form-label-o" }`}>
                Heure d'ouverture 
              </span>
              <input type="time" value={request.start_open} name="start_open" className={`form-control ${request.isOpen === "1"? "bg-slate-200":"peer" }`} required={request.isOpen === "1"? false:true} disabled={request.isOpen === "1"? true:false}  onChange={(e:any)=>handlerInput(e)} />
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                l'heure d'ouverture est obligatoires
              </p>
            </label>

            <label className="block">
              <span className={`${request.isOpen === "1"? "form-label":"form-label-o" }`}>
                Heur de fermeture 
              </span>
              <input type="time" value={request.end_open} name="end_open" className={`form-control ${request.isOpen === "1"? "bg-slate-200":"peer" }`} required={request.isOpen === "1"? false:true} disabled={request.isOpen === "1"? true:false} onChange={(e:any)=>handlerInput(e)} />
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                l'heure de fermeture est obligatoires
              </p>
            </label>

           
          </div>

          <div className="flex pb-3 justify-end ">
              <button className='rounded-md bg-blue-500 px-2 text-white mx-3 w-32 hover:bg-blue-400'>{pharmacieId != undefined? "Modifier":"Enregistrer" }</button>           
          </div>

      </form>
    </>
  )
}

export {AddPharmacie}
