import React, { useEffect, useState } from "react";
import CustomModal from "../../../components/customModal";
import { useNavigate } from "react-router-dom";
import { PharmacoService } from "../../../services/pharmacoService";

function Pharmaco() {

    const history = useNavigate();
    const[isOpen, setOpen] = useState(false);
    const[config, setConfig] = useState({ "display":"none","defaultType":"medicament",} as any);
    const[toogleChange, setToggele] = useState<boolean>(false);
    const[data, setData] = useState([]);
    const[isLoad,setLoader]=useState(false);
    const[search,setSearch]=useState("");

    useEffect(() => {
        console.log('is loader')
        getPharmacos();
        // eslint-disable-next-line
    }, []);

    const getPharmacos = async () => {
        setLoader(true);
        var response = await PharmacoService.getParmacos();
        setLoader(false);
        if (response.status == 200) {
            setData(response.data);
        } else {
            alert(response.data);
        }
    }

    function openModalDetail(pharmaco: any){
        let configs = {
            "type":"",
            "title":"Detail",
            "action":"DETAIL",
            "content":viewDetail(pharmaco),
            "close": ()=>{closeModal()},
            "display":"",
        };
        setConfig(configs);
    }

    function openModalDelete( pharmaco: any){
        let configs = {
            "type":"confirmation",
            "title":"Attention",
            "action":"SUPRESSION",
            "msgConfirm": "Vous allez supprimer cette pharmaco",
            "close": () => {setConfig({ "display":"none"});},
            "confirm": () => {},
            "display":"",
        };

        setConfig(configs);
    }

    function closeModal(){
        console.log(config);
        setConfig({ "display":"none"});
        let inputFile:any = document.querySelector("#fileXls");
        inputFile.value = "";
        setOpen(false);
    }

    // function navigate(to:string){
    //     history(to);
    // }

    function viewDetail(pharmaco: any) {
        let antecedants : Array<string> = JSON.parse(pharmaco.antecedant);
        let vaccination_time : string;
        let hour : number = pharmaco.onset_time_vaccine / 60;
        let undesirable_products = JSON.parse(pharmaco.undesirable_products);
        console.log(undesirable_products);
        // if(hour > 1) {
        //     hour = hour.toFixed(1);
        //     let minute = pharmaco.onset_time_vaccine % 60;

        //     if(hour % 24 > 1) {
        //         let day = hour % 24;
        //     }
        // }
        return (
            <div className="p-2 max-h-80 md:max-h-100 overflow-y-auto">
                <div className="md:grid md:grid-cols-3 md:gap-4 mb-2">
                    <div className="mb-4">
                        <h2 className="text-lg font-medium">Nom du patient</h2>
                        <p className="text-sm text-gray-500">{pharmaco.name}</p>
                    </div>

                    <div className="mb-4">
                        <h2 className="text-lg font-medium">Prénom du patient</h2>
                        <p className="text-sm text-gray-500">{pharmaco.first_name}</p>
                    </div>

                    <div className="mb-4">
                        <h2 className="text-lg font-medium">Âge</h2>
                        <p className="text-sm text-gray-500">{pharmaco.birthday}</p>
                    </div>
                </div>
                
                <div className="md:grid md:grid-cols-3 md:gap-4 mb-2">
                    <div className="mb-2">
                        <h2 className="text-lg font-medium">Sexe</h2>
                        <p className="text-sm text-gray-500">{pharmaco.gender == "h" ? "Homme" : "Femme"}</p>
                    </div>

                    <div className="mb-2">
                        <h2 className="text-lg font-medium">Antécédant</h2>
                        <ul>
                            {
                                antecedants.map((antecedant : string, i:number) => {
                                    return <li> <p className="text-sm text-gray-500">{antecedant}</p> </li>; 
                                })
                            }
                        </ul>
                        
                    </div>

                    <div className="mb-2">
                        <h2 className="text-lg font-medium">Poids et taille du patient</h2>
                        <p className="text-sm text-gray-500">{pharmaco.weight + "kg / " + (pharmaco.cut ?? "---" ) + "m"}</p>
                    </div>
                   
                </div>

                <div className="md:grid md:grid-cols-3 md:gap-4 mb-2">
                    <div className="mb-4">
                        <h2 className="text-lg font-medium">Nouveau née</h2>
                        <ul>
                            {
                                JSON.parse(pharmaco.new_born).map((new_born : string, i:number) => {
                                    return <li> <p className="text-sm text-gray-500">{new_born}</p> </li>; 
                                })
                            }
                        </ul>
                    </div>

                    <div className="mb-2">
                        <h2 className="text-lg font-medium">Gravité</h2>
                        <p className="text-sm text-gray-500">{pharmaco.gravity === null?"-/-":pharmaco.gravity}</p>
                    </div>

                    <div className="mb-2">
                        <h2 className="text-lg font-medium">Evolution</h2>
                        <p className="text-sm text-gray-500">{pharmaco.evolution === null?"-/-":pharmaco.evolution}</p>
                    </div>
                   
                </div>

                <div className="md:grid md:grid-cols-3 md:gap-4 mb-2">
                   
                    <div className="mb-4">
                        <h2 className="text-lg font-medium"> Description évènement </h2>
                        <p className="text-sm text-gray-500">{pharmaco.description_event  === null? "-/-":pharmaco.description_event }</p>
                    </div>

                    <div className="mb-4">
                        <h2 className="text-lg font-medium">Date évènement</h2>
                        <p className="text-sm text-gray-500">{pharmaco.date_event === null?"-/-":pharmaco.date_event}</p>
                    </div>

                </div>

                <div className="md:grid md:grid-cols-3 md:gap-4 mb-2">
                    <div className="mb-4">
                        <h2 className="text-lg font-medium">Temps de vaccination</h2>
                        <p className="text-sm text-gray-500">{pharmaco.onset_time_vaccine === null?"-/-":pharmaco.onset_time_vaccine}</p>
                    </div>

                    <div className="mb-4">
                        <h2 className="text-lg font-medium">ville du notificateur</h2>
                        <p className="text-sm text-gray-500">{pharmaco.notificateur_city === null?"-/-":pharmaco.notificateur_city}</p>
                    </div>

                    <div className="mb-4">
                        <h2 className="text-lg font-medium">Formation sanitaire du notificateur</h2>
                        <p className="text-sm text-gray-500">{pharmaco.notificateur_formation_sanitaire === null?"-/-":pharmaco.notificateur_formation_sanitaire}</p>
                    </div>
                </div>

                {undesirable_products && <div className="mb-2">
                            <h2 className="text-lg font-medium">Produits indésirables</h2>
                            <div className="md:grid md:grid-cols-2 md:gap-4 mb-2">
                                {
                                    undesirable_products?.map((undesirable_product : any, i:number) => {
                                        return <div className="mb-4" style={{marginTop: "10px"}}>
                                            <p className="text-sm text-gray">Produit {i+1}</p>
                                                <div style={{height: "5px"}}></div>
                                                <ul>
                                                    <li> <p className="text-sm text-gray-500"> Nature du produit: <span style={{color: "black"}}> {undesirable_product.productType} </span> </p> </li>
                                                    <li> <p className="text-sm text-gray-500"> Nom du produit: <span style={{color: "black"}}>{undesirable_product.name} </span> </p> </li>
                                                    {undesirable_product.fabricant != null && <li> <p className="text-sm text-gray-500"> Nom du fabricant: <span style={{color: "black"}}> {undesirable_product.fabricant} </span> </p> </li>}
                                                    {undesirable_product.expireDate != null && <li> <p className="text-sm text-gray-500"> Date de péremption: <span style={{color: "black"}}>{undesirable_product.expireDate} </span> </p> </li>}
                                                    {undesirable_product.placeAcquisition != null && <li> <p className="text-sm text-gray-500"> Lieu d'acquisition: <span style={{color: "black"}}> {undesirable_product.placeAcquisition} </span> </p> </li>}
                                                    {undesirable_product.administrativeRoute != null && <li> <p className="text-sm text-gray-500"> voie d'administration: <span style={{color: "black"}}> {undesirable_product.administrativeRoute} </span> </p> </li>}
                                                    {undesirable_product.posologie != null && <li> <p className="text-sm text-gray-500"> Posologie: <span style={{color: "black"}}> {undesirable_product.posologie} </span> </p> </li>}
                                                    {undesirable_product.indication != null && <li> <p className="text-sm text-gray-500"> Indication: <span style={{color: "black"}}>{undesirable_product.indication}</span> </p> </li>}
                                                    {undesirable_product.startDateTreatement != null && <li> <p className="text-sm text-gray-500"> Début du traitement: <span style={{color: "black"}}> {undesirable_product.startDateTreatement} </span> </p> </li>}
                                                    {undesirable_product.endDateTreatement != null && <li> <p className="text-sm text-gray-500"> Fin du traitement: <span style={{color: "black"}}> {undesirable_product.endDateTreatement} </span> </p> </li>}
                                                    {undesirable_product.disableReaction != null && <li> <p className="text-sm text-gray-500"> La reaction a t'elle disparue? : <span style={{color: "black"}}> {undesirable_product.disableReaction == 1 ? "Oui" : "Non"} </span> </p> </li>}
                                                    {undesirable_product.productIsIntroduct != null && <li> <p className="text-sm text-gray-500"> Le produit a-t-il été réintroduit? : <span style={{color: "black"}}> {undesirable_product.productIsIntroduct == 1 ? "Oui" : "Non"} </span> </p> </li>}
                                                    {undesirable_product.partiePlanteUtilise != null && <li> <p className="text-sm text-gray-500"> Partie de la plate utilisé: <span style={{color: "black"}}> {undesirable_product.partiePlanteUtilise} </span> </p> </li>}
                                                    {undesirable_product.siteInjection != null && <li> <p className="text-sm text-gray-500"> Lieu de vaccination: <span style={{color: "black"}}> {undesirable_product.siteInjection} </span> </p> </li>}
                                                </ul>
                                        
                                        </div>; 
                                    })
                                }
                            </div>
                            {/* <p className="text-sm text-gray-500">{pharmaco.undesirable_products === null?"-/-":pharmaco.undesirable_products}</p> */}
                        </div>
                }
                
            </div>
          );
    }
    

  return (
    <>
        <div className="bg-white p-3 rounded-md w-full">
            <h1 className="text-2xl border-b-2">Pharmaco vigilance</h1>
            <p className="text-sm text-slate-500 mt-2">Remarque observé sur des medicaments</p>
        </div>
        
        {/* barre de recherche et filtre */}
        {/* <div className="flex flex-wrap justify-between min-w-full bg-white p-3 rounded-md my-3">
            <div className="flex pharmacos-center border-2 hover:border-blue-500 hover:text-blue-500 focus-within:border-blue-500 rounded-md focus-within:text-blue-500 text-slate-400 my-1 md:w-auto w-full">
                <input type="text" className="form-control-search rounded-md mt-0 text-slate-400 w-full "  placeholder="Rechecher sur les pharmco" id="" />
                <button className="px-2 ">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </button>
            </div>  

            <div className="flex justify-between my-1 md:w-auto w-full">
                <button className="px-2 " title="Tri en croissant">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
                    </svg>
                </button>
                <button className="px-2 " title="Tri en décroissant">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
                    </svg>
                </button>
            </div>              
        </div> */}

        {/* barre de recherche fin */}

        <div className="hidden md:block">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left">
                    <thead className="bg-slate-400 text-slate-50 text-xs uppercase border-b border-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                #
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Nom Complet du patient
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Âge du patient
                            </th>
                            <th scope="col" className="px-6 py-3">
                               Sexe du patient
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Nom du personnel
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        !isLoad && data.length > 0 &&
                        data.map((pharmaco : any, i:number) => {
                            return (
                            <tr key={i} className="bg-white border-b border-gray-200">
                                <td className="px-6 py-4 d-flex">
                                    {i + 1}
                                </td>
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {pharmaco.name + " " + pharmaco.first_name}
                                </th>
                                <td className="px-6 py-4">
                                    <small className="text-center">{pharmaco.birthday}</small>
                                </td>
                                <td className="px-6 py-4">
                                    {pharmaco.gender == "h" ? "Homme" : "Femme"}
                                </td>
                                <td className="px-6 py-4">
                                    {pharmaco.nom + " " + pharmaco.prenom}
                                </td>
                                <td className="px-6 py-4">
                                    <button className='mb-2 text-blue-500 mx-2' title='Voir plus' onClick={()=>{openModalDetail(pharmaco)}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                        </svg>
                                    </button>
                                   
                                </td>
                            
                            </tr>);
                        
                        })
                    }
                    {
                        
                        data.length === 0 &&
                        <tr>
                            <th scope="row" colSpan={7} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                Aucun enregistrement de personnel disponible
                            </th>
                        </tr>
                        
                    }
                    
                    </tbody>
                </table>
            </div>
        </div>
        <CustomModal Classe='md:w-1.2/2 h-1.2/2' config={config}/> 
    </>
  )
}

export {Pharmaco}
