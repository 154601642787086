class FicheExamenModel{

    ExamentId: number | null;
    name: string;
    prix: string;
    prelevement: string;
    exigence: string;
    volume: string;
    condition: string;
    jour_ouvrable: string;
    delais_rendus: string;
   

    constructor(data?:any){
        this.ExamentId = data?.ExamentId;
        this.name = data?.noms_des_examens;
        this.prix = data?.prix;
        this.prelevement = data?.prelevement;
        this.exigence = data?.exigences_particulieres ;
        this.volume = data?.volume_minimal;
        this.condition = data?.conditions_de_transport;
        this.jour_ouvrable = data?.jours_de_reception;
        this.delais_rendus = data?.delai_de_rendu;
    }
}

export{FicheExamenModel}