import React from 'react';
import { Navigate} from 'react-router-dom';

const PrivateAuth=({token,children }:any)=>{
    if (!token) {
        return <Navigate to="/Auth" replace />;
    }
    return children;
}

export default PrivateAuth;
