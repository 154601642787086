import { ApiProvider } from "../providers/apiProvider";
import { NotificationProvider } from "../providers/notificationProvider";

export const NotificationService ={
    saveNotification,
    sendNotification,
    getNotification,
    updateNotification
};

async function sendNotification(data:any){
    let res = await NotificationProvider.send("send",data);
    return res;
}

async function saveNotification(data:any){
    let res = await ApiProvider.post("saveNotifications",data);
    return res;
}

async function updateNotification(data:any){
    let res = await ApiProvider.post("updateNotifications",data);
    return res;
}

async function getNotification(){
    let res = await ApiProvider.get("all-notifications");
    return res;
}
