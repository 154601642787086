import React, { useEffect, useState } from 'react';
import CustomModal from "../../../components/customModal";
import { useNavigate } from "react-router-dom";
import { PharmacieService } from './../../../services/pharmacieService';
import IPharmacie from './../../../interfaces/IPharmacies';
import PHARMACIE from './../../../assets/img/pharmacies/pharmacie.png';
import PHARMACIEGARDE from './../../../assets/img/pharmacies/garde.png';
// import OPEN from './../../../assets/img/pharmacies/open.png'

const AllPharmacies = () => {

  const history = useNavigate();
  const[config,setConfig]=useState({ "display":"none","defaultType":"Pharmacie",} as any);
  const[toogleChange, setToggele] = useState<boolean>(false);
  const[data, setData] = useState([]);
  const[dataGarde, setDataGarde] = useState([]);
  const[isLoad,setLoader]=useState(false);
  const[search,setSearch]=useState("");
  const[tab,setTab]=useState("toute");
  const TOUTE = "toute";
  const GARDE = "garde";

  useEffect(() => {
        console.log('is loader')
        getPharmacies();
        getPharmaciesGardes();
        // eslint-disable-next-line
  }, [toogleChange]);

  function openModalInport(){
    let configs = {
        "type":"popup",
        "title":"Importion des pharmacies",
        "action":"IMPORT_PHARMACIE",
        "close": ()=>{closeModal()},
        "success": ()=>{reloadMedicament()},
        "display":"",
        "defaultType":"Pharmacie",
    };

    setConfig(configs);
  }

  function openModalDeletePharma(pharmacie:IPharmacie){
    let configs = {
        "type":"confirmation",
        "title":"Attention",
        "action":"SUPRESSION",
        "msgConfirm":"Cette pharmacie va etre definitivement supprimé !",
        "close": ()=>{closeModal()},
        "confirm": ()=>{deleteData(pharmacie.id)},
        "display":"",
    };
    setConfig(configs);
  }

  function openModalDeletePharmaGarde(pharmacie:IPharmacie){
    let configs = {
        "type":"confirmation",
        "title":"Attention",
        "action":"SUPRESSION",
        "msgConfirm":"Cette pharmacie de garde va etre definitivement supprimé !",
        "close": ()=>{closeModal()},
        "confirm": ()=>{deleteGardeData(pharmacie.pharmacieGardeId)},
        "display":"",
    };
    setConfig(configs);
  }

  const deleteData = (pharmacie:any) => {
    PharmacieService.deletePharmacie(pharmacie).then(
      (response)=>{
        if(response.status === 200){
          setToggele((prev:boolean) => prev = !prev)
        }else{
          alert('Impossible de supprimer cette pharmacie une erreur c\'est produite');
        }
      }
    ).catch(
      (error)=>{
        alert('erreur systeme');
      }
    )
    closeModal()
  }

  const deleteGardeData = (pharmacie:any) => {
    PharmacieService.deletePharmacieGarde(pharmacie).then(
      (response)=>{
        if(response.status === 200){
          setToggele((prev:boolean) => prev = !prev)
        }else{
          alert('Impossible de supprimer cette pharmacie une erreur c\'est produite');
        }
      }
    ).catch(
      (error)=>{
        alert('erreur systeme');
      }
    )
    closeModal()
  }

  function openModalDetail(pharmacie:IPharmacie){
    let configs = {
        "type":"",
        "title":"Detail de la pharmacie : "+pharmacie.name,
        "action":"DETAIL",
        "content":viewDetail(pharmacie),
        "close": ()=>{closeModal()},
        "display":"",
    };

    setConfig(configs);
  }

  function reloadMedicament(){
    setToggele((prev:boolean) => prev = !prev)
  }
  //useMemo()
  function closeModal(){
      console.log(config);
      let type = config.type;
      setConfig({ "display":"none"});
      if(type === "popup"){
        let inputFile:any = document.querySelector("#fileXls");
        inputFile.value = "";
      }
  }

  function navigate(to:string){
      history(to);
  }

  const getPharmacies = () =>{
      setLoader(true);
      PharmacieService.getPharmacies().then(
          (response)=>{
              setData(response.data);
              setLoader(false);
          }
      ).catch(
          (error: any)=>{
              console.log(error);
              setLoader(true);
          }
      )
  }

  const getPharmaciesGardes = () =>{
    setLoader(true);
    PharmacieService.getPharmaciesGardes().then(
        (response)=>{
            setDataGarde(response.data);
            setLoader(false);
        }
    ).catch(
        (error: any)=>{
            console.log(error);
            setLoader(true);
        }
    )
  }

  function viewDetail(pharmacie:IPharmacie){
    return (
      <>
        <div className="grid grid-cols-2 gap-2 ">
          <div className='flex border-2 p-2'>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <div>
              <h2 className='font-bold'>{pharmacie.ville}</h2>
              <p className='text-sm'>{pharmacie.emplacement}</p>
            </div>
          </div>

          <div className='flex border-2 p-2 '>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>
            <div>
              <h2 className='font-bold'>Contact</h2>
              <p className='text-sm'>{pharmacie.contact1} - {pharmacie.contact2}</p>
            </div>
          </div>

          {(pharmacie.coordonnee !== null) && 
            <div className='flex border-2 p-2 '>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
              </svg>
              <div>
                <h2 className='font-bold'>Coordonnée</h2>
                <a className='text-sm' href={`https://www.google.com/maps/place/${pharmacie.name}/@${pharmacie.coordonnee}z`} target={'_blank'}>Localisation gps</a>
              </div>
            </div>
          }
          

          <div className='flex border-2 p-2 '>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <div>
              <h2 className='font-bold'>Ouverture</h2>
              <p className='text-sm'>{pharmacie.isOpen === 1? "24h/24":pharmacie.start_open+"-"+pharmacie.end_open}</p>
            </div>
          </div>
        </div>
      </>
    )
  }

  function checkDate(val:string){
    const dateVal = new Date(val+'07:30');
    if((dateVal.getTime())> Date.now()){
      return true;
    }else{
      return false;
    }
  }

  
  
  return (
    <>
      <div className="bg-white p-3 rounded-md w-full">
          <h1 className="text-2xl border-b-2">Pharmacies</h1>
          <p className="text-sm text-slate-500 mt-2">Liste des pharmacies disponible</p>
      </div>
      {/* barre de recherche et filtre */}
      <div className="flex flex-wrap justify-between min-w-full bg-white p-3 rounded-md my-3">
                <div className="flex items-center border-2 hover:border-blue-500 hover:text-blue-500 focus-within:border-blue-500 rounded-md focus-within:text-blue-500 text-slate-400 my-1 md:w-auto w-full">
                    <input type="text" className="form-control-search rounded-md mt-0 text-slate-400 w-full " onChange={(e)=>setSearch(e.target.value)} placeholder="Recheche d'une pharmacie" id="" />
                    <button className="px-2 ">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </button>
                </div>  

                <div className="flex justify-between my-1 md:w-auto w-full">
                    <button className="px-2 " title="Tri en croissant">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
                        </svg>
                    </button>
                    <button className="px-2 " title="Tri en décroissant">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
                        </svg>
                    </button>
                    <button className="px-2 " title="Templates d'export des pharmacies">
                        <a href={process.env.PUBLIC_URL + '/templateXlsx/Database_pharmacie_medindex.xlsx'} download="DataBaseImportPharmacie.xlsx" style={{textDecoration:"none"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                        </a>
                    </button>
                    <button className="px-2 " title="Importer des pharmacies" onClick={()=>openModalInport()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                        </svg>
                    </button>
                    <button className="px-2 " title="Exporter des pharmacies">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
                        </svg>
                    </button>
                    <button className="px-2 " title="Ajouter une pharmacie" onClick={()=>navigate("Add")}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </button>
                </div>  

                           
            </div>

            <div className="flex">
                  <div className={`tab shadow-2xl mx-2 shadow-slate-50 p-2 border-2 rounded-full cursor-pointer md:text-base text-xs hover:bg-blue-500 hover:text-white ${tab === TOUTE? "bg-blue-500 text-white":""}`} onClick={()=>setTab(TOUTE)}>
                    Toutes les pharmacies
                  </div>
                  <div className={`tab shadow-2xl mx-2 shadow-slate-50 p-2 border-2 rounded-full cursor-pointer md:text-base text-xs hover:bg-blue-500 hover:text-white ${tab === GARDE? "bg-blue-500 text-white":""}`} onClick={()=>setTab(GARDE)}>
                    Pharmacies de garde
                  </div>
            </div> 
            {/* barre de recherche fin */}

            <div className={`${isLoad? "flex flex-col justify-center items-center h-2/3 w-full": "md:block mt-3"}`}>
                {
                    isLoad && 
                    <>
                        
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-blue-400 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                       
                        
                        <p className="mt-3">Chargement des pharmacies ...</p>
                    </>
                    
                }

                {
                  !isLoad &&
                  <>
                    {
                      (data.length > 0 && tab === TOUTE) &&
                      <>
                        {/* afficharge mode bureau */}
                        <div className="hidden md:block">
                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                <table className="w-full text-sm text-left">
                                    <thead className="bg-slate-400 text-slate-50 text-xs uppercase border-b border-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                #
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Nom 
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Ville
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Emplacement
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Numero de telephone
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        !isLoad && data.length > 0 &&
                                        data.filter((pharmacie:IPharmacie)=>pharmacie.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                                        .map((pharmacie:IPharmacie,i:number) => {
                                            return (
                                            <tr key={i} className="bg-white border-b border-gray-200">
                                                <td className="px-6 py-4 d-flex">
                                                    {i + 1}
                                                </td>
                                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                    {pharmacie.name} 
                                                </th>
                                                <td className="px-6 py-4">
                                                    {pharmacie.ville}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {pharmacie.emplacement}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {pharmacie.contact1} {pharmacie.contact2 === null || pharmacie.contact2 === ""?"":" - "+pharmacie.contact2} 
                                                </td>
                                                <td className="px-6 py-4">
                                                <button className='mb-2 text-blue-500 mx-2' title='Voir plus' onClick={()=>{openModalDetail(pharmacie)}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                    </svg>
                                                </button>
                                                <button className='mb-2 text-green-500 mx-2' onClick={()=>{navigate("Edit/"+pharmacie.id)}} title='Modifier'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                </button>
                                                <button className='mb-2 text-red-500 mx-2' onClick={()=>{openModalDeletePharma(pharmacie)}} title='Supprimer '>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                </button>
                                                </td>
                                            
                                            </tr>);
                                        
                                        })
                                    }
                                    {
                                        
                                        data.length === 0 &&
                                        <tr>
                                            <th scope="row" colSpan={7} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                                Aucun enregistrement de personnel disponible
                                            </th>
                                        </tr>
                                        
                                    }
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* afficharge mode mobile */}
                        <div className="md:hidden block">
                        {
                            !isLoad && data.length > 0 &&
                            data.filter((pharmacie:IPharmacie)=>pharmacie.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                            .map((pharmacie:IPharmacie,i:number) => {
                                return(
                                    <div key={i} className="flex flex-row rounded-md bg-white lg:mb-0 mb-4">
                                        <div className="bg-blue-500 rounded-tl-md rounded-bl-md p-3 flex flex-col items-center w-32">
                                            <img src={PHARMACIE} alt="Ampoule" className="max-w-12 h-12 p-2 rounded-full bg-white shadow-md" />

                                            <h2 className="text-white text-center font-medium">{pharmacie.ville}</h2>
                                        </div>
                                        <div className="flex-auto p-2 bg-white rounded-tr-md rounded-br-md mt-2">
                                            <h2 className="font-medium text-sm">{pharmacie.name}</h2>
                                            <div className="flex justify-between">
                                                <div className="rounded-3xl border-2 border-blue-500 bg-blue-300 text-xs px-2">
                                                    <span className="text-white"> 
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                                        </svg>
                                                        {pharmacie.contact1}
                                                    </span>
                                                </div>
                                              
                                                {
                                                    (pharmacie.contact2 !== "" || pharmacie.contact2 !== null) &&
                                                    <div className="rounded-3xl border-2 border-blue-500 bg-blue-300 text-xs px-2">
                                                        <span className="text-white"> 
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                                            </svg>
                                                            {pharmacie.contact2}
                                                        </span>
                                                    </div>
                                                }
                                            

                                            </div>

                                            <div className="flex justify-end mt-2">
                                                <button className='mb-2 text-blue-500 mx-2' title='Detail de la pharmacie' onClick={()=>{openModalDetail(pharmacie)}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                    </svg></button>
                                                <button className='mb-2 text-green-500 mx-2' onClick={()=>{navigate("Edit/"+pharmacie.id)}} title='Modifier la pharmacie'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                </button>
                                                <button className='mb-2 text-red-500 mx-2' onClick={()=>{openModalDeletePharma(pharmacie)}} title='Supprimer la pharmacie'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                </button>
                                            </div>

                            
                                        </div>
                                        
                                    </div>
                                );
                            })
                        }
                        </div>
                      </>
                    }

                    {
                      (data.length > 0 && tab === GARDE) &&
                      <>
                        {/* afficharge mode bureau */}
                        <div className="hidden md:block">
                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                <table className="w-full text-sm text-left">
                                    <thead className="bg-slate-400 text-slate-50 text-xs uppercase border-b border-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                #
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Nom 
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Ville
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Emplacement
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Numero de telephone
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Garde
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        !isLoad && dataGarde.length > 0 &&
                                        dataGarde.filter((pharmacie:IPharmacie)=>pharmacie.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                                        .map((pharmacie:IPharmacie,i:number) => {
                                          if (!checkDate(pharmacie.start_garde) === true && checkDate(pharmacie.end_garde) === true) {
                                            return (
                                              <tr key={i} className="bg-white border-b border-gray-200">
                                                  <td className="px-6 py-4 d-flex">
                                                      {i + 1}
                                                  </td>
                                                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                      {pharmacie.name} 
                                                  </th>
                                                  <td className="px-6 py-4">
                                                      {pharmacie.ville}
                                                  </td>
                                                  <td className="px-6 py-4">
                                                      {pharmacie.emplacement}
                                                  </td>
                                                  <td className="px-6 py-4">
                                                  {pharmacie.contact1} {pharmacie.contact2 === null || pharmacie.contact2 === ""?"":" - "+pharmacie.contact2} 
                                                  </td>
                                                  <td className="px-6 py-4">
                                                      {
                                                        (checkDate(pharmacie.start_garde) && checkDate(pharmacie.end_garde)) &&
                                                        <div className="px-2 bg-green-500 mt-2 text-white text-xs rounded-full"> 
                                                          <small>Avenir</small>
                                                        </div>
                                                      }
  
                                                      {
                                                        (!checkDate(pharmacie.start_garde) && !checkDate(pharmacie.end_garde)) &&
                                                        <div className="px-2 bg-red-500 mt-2 text-white text-xs rounded-full"> 
                                                          <small>Passé</small>
                                                        </div>
                                                      }
  
                                                      {
                                                        (!checkDate(pharmacie.start_garde) && checkDate(pharmacie.end_garde)) &&
                                                        <div className="px-2 bg-gray-500 mt-2 text-white text-xs rounded-full"> 
                                                          <small>En cour</small>
                                                        </div>
                                                      }
                                                  </td>
                                                  <td className="px-6 py-4">
                                                  <button className='mb-2 text-blue-500 mx-2' title='Voir plus' onClick={()=>{openModalDetail(pharmacie)}}>
                                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                      </svg>
                                                  </button>
                                                  {/* <button className='mb-2 text-green-500 mx-2' onClick={()=>{}} title='Modifier'>
                                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                      <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                      </svg>
                                                  </button> */}
                                                  <button className='mb-2 text-red-500 mx-2' onClick={()=>{openModalDeletePharmaGarde(pharmacie)}} title='Supprimer '>
                                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                      <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                      </svg>
                                                  </button>
                                                  </td>
                                              
                                              </tr>);
                                          }
                                           
                                        
                                        })
                                    }
                                    {
                                        
                                        dataGarde.length === 0 &&
                                        <tr>
                                            <th scope="row" colSpan={7} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                                Aucun enregistrement de personnel disponible
                                            </th>
                                        </tr>
                                        
                                    }
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* afficharge mode mobile */}
                        <div className="md:hidden block">
                        {
                            !isLoad && dataGarde.length > 0 &&
                            dataGarde.filter((pharmacie:IPharmacie)=>pharmacie.name.toLocaleLowerCase().includes(search.toLocaleLowerCase() ))
                            .map((pharmacie:IPharmacie,i:number) => {
                              if (!checkDate(pharmacie.start_garde) == true && checkDate(pharmacie.end_garde) === true) {
                                return(
                                  <div key={i} className="flex flex-row rounded-md bg-white lg:mb-0 mb-4">
                                      <div className="bg-blue-500 rounded-tl-md rounded-bl-md p-3 flex flex-col items-center w-32">
                                          <img src={PHARMACIEGARDE} alt="Ampoule" className="max-w-12 h-12 p-2 rounded-full bg-white shadow-md" />

                                          <h2 className="text-white text-center font-medium">{pharmacie.ville}</h2>

                                          {
                                            (checkDate(pharmacie.start_garde) && checkDate(pharmacie.end_garde)) &&
                                            <div className="px-2 bg-green-500 mt-2 text-white text-xs rounded-full"> 
                                              <small>Avenir</small>
                                            </div>
                                          }

                                          {
                                            (!checkDate(pharmacie.start_garde) && !checkDate(pharmacie.end_garde)) &&
                                            <div className="px-2 bg-red-500 mt-2 text-white text-xs rounded-full"> 
                                              <small>Passé</small>
                                            </div>
                                          }

                                          {
                                            (!checkDate(pharmacie.start_garde) && checkDate(pharmacie.end_garde)) &&
                                            <div className="px-2 bg-gray-500 mt-2 text-white text-xs rounded-full"> 
                                              <small>En cour</small>
                                            </div>
                                          }
                                          
                                      </div>
                                      <div className="flex-auto p-2 bg-white rounded-tr-md rounded-br-md mt-2">
                                          <h2 className="font-medium text-sm">{pharmacie.name}</h2>
                                          <div className="flex justify-between">
                                              <div className="rounded-3xl border-2 border-blue-500 bg-blue-300 text-xs px-2">
                                                  <span className="text-white"> 
                                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                          <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                                      </svg>
                                                      {pharmacie.contact1}
                                                  </span>
                                              </div>
                                            
                                              {
                                                
                                                  (pharmacie.contact2 !== "" && pharmacie.contact2 !== null ) &&
                                                  <div className="rounded-3xl border-2 border-blue-500 bg-blue-300 text-xs px-2">
                                                      <span className="text-white"> 
                                                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                              <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                                          </svg>
                                                          {pharmacie.contact2}
                                                      </span>
                                                  </div>
                                              }
                                          

                                          </div>

                                          <div className="flex justify-end mt-2">
                                              <button className='mb-2 text-blue-500 mx-2' title='Detail de la pharmacie' onClick={()=>{openModalDetail(pharmacie)}}>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                  </svg></button>
                                              {/* <button className='mb-2 text-green-500 mx-2' onClick={()=>{}} title='Modifier la pharmacie'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                  </svg>
                                              </button> */}
                                              <button className='mb-2 text-red-500 mx-2' onClick={()=>{openModalDeletePharmaGarde(pharmacie)}} title='Supprimer la pharmacie'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                  </svg>
                                              </button>
                                          </div>

                          
                                      </div>
                                      
                                  </div>
                                );
                              }
                                
                            })
                        }
                        </div>
                      </>
                    }
                  </>
                }
            </div>

            <CustomModal Classe='w-2/5' config={config}/> 
    </>
  )
}

export {AllPharmacies}
