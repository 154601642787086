import axios, { AxiosInstance, AxiosResponse,AxiosError } from 'axios';

export const ApiProvider = {
    post,
    put,
    remove,
    get,
    patch,
    upload
};

function getWs(){
    let ws;
    ws = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_WS : process.env.REACT_APP_PROD_WS;

    console.log(process.env.REACT_APP_DEV_WS);
    return ws;
}

function getHeaders(multipart?: string) {
    return {
      Accept: "application/json",
      "Content-Type": multipart || "application/json",
      'Authorization': getToken()
    };
}

function getHeaderNotification(){
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    'Authorization': "key="+process.env.REACT_APP_FIREBASE_KEY
  };
}

function getToken(): string{
    let token:any = localStorage.getItem("token");
    if(token !== "" &&  token !== null && token !== undefined ){
      return "Bearer "+token
    }
      return "";
}

async function getInstance(multipart?: string,isNotification?:boolean): Promise<AxiosInstance> {
    return new Promise(async (resolve, reject) => {
      const headers = isNotification? getHeaderNotification(): getHeaders(multipart);
      resolve(
        axios.create({
          timeout: 60000,
          headers: headers,
          // withCredentials: true,
          baseURL: getWs(),
        })
      );
    });
}

function handleResponse(res: AxiosResponse) {
    // //console.log("Request response data ==> ", res.data);
    //console.log(res);
    return res;
}

function handleError(err: AxiosError) {
    // //console.log("<== Request Error ==> ");
    console.log(err);
    let msg = err.response?.statusText
    let data = err.response?.data;
    let status = err.response?.status;
    // let message = "Une erreur est survenu lors de l'opération. Merci de reéssayer !";
    
    return {status, data, msg };
}

function getPayload(req: any, data?: any) {
    return data || req.body || req;
}

async function post(req: any, data?: any) {
    const axiosInstance = await getInstance();
    return axiosInstance
      .post(req, getPayload(req, data))
      .then((res: any) => handleResponse(res))
      .catch((err: any) => handleError(err));
}

async function upload(req: any, data?: any) {
  const axiosInstance = await getInstance("multipart/form-data");
  return axiosInstance
    .post(req, getPayload(req, data))
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

async function get(req: any, data?: any) {
    const axiosInstance = await getInstance();
    return axiosInstance
      .get(req, getPayload(req, data))
      .then((res: any) => handleResponse(res))
      .catch((err: any) => handleError(err));
}
  
async function put(req: any, data?: any) {
    const axiosInstance = await getInstance();
    return axiosInstance
      .put(req, getPayload(req, data))
      .then((res: any) => handleResponse(res))
      .catch((err: any) => handleError(err));
}
  
  async function patch(req: any, data?: any) {
    const axiosInstance = await getInstance();
    return axiosInstance
      .patch(req, getPayload(req, data))
      .then((res: any) => handleResponse(res))
      .catch((err: any) => handleError(err));
  }
  
  async function remove(req: any, data?: any) {
    const axiosInstance = await getInstance();
    return axiosInstance
      .delete(req, getPayload(req, data))
      .then((res: any) => handleResponse(res))
      .catch((err: any) => handleError(err));
  }


  