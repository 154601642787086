import React, { useEffect, useRef } from 'react'
import SelectSearch from 'react-select-search';
import { useState } from 'react';
import { LaboratoireService } from './../../../services/laboratoireService';
import { FicheLaboratoireModel } from '../../../model/ficheLaboratoireModel';
import { useNavigate, useParams } from 'react-router-dom';

function AddLaboratoire() {

  const searchInput:any = useRef();
  const[request,setRequest] = useState({allwayOpen:0} as any);
  const[data, setData] = useState([]);
  const[ville, setVille] = useState({} as any);
  const history = useNavigate();
  const[pictures, setPicture] = useState([]);
  const[logo, setlogo] = useState();

  const {laboratoireId} = useParams();
  console.log(laboratoireId);

  useEffect(() => {
    console.log('is loader')
    getPropVilles();
    if(laboratoireId != undefined) {
      getLaboratoire();
    }
    // eslint-disable-next-line
  }, []);


  function handlerInput(e:any){
      const {name, value,files}:any = e.target;

      switch (name) {
        case "name":
          setRequest({...request,name:value});
          break;
        case "emplacement":
          setRequest({...request,emplacement:value});
          break;
        case "contact":
          setRequest({...request,contact:value});
          break;
        case "siteWeb":
          setRequest({...request,siteWeb:value});
          break;
        case "emplaceDetail":
          setRequest({...request,emplaceDetail:value});
          break;
        case "ouverture":
          setRequest({...request,ouverture:value});
          break;
        case "fermeture":
          setRequest({...request,fermeture:value});
          break;
        case "urlLogo":
          // impleùent upload image base64
          setlogo(files[0]);
          // let logo = new FileReader();
          // console.log(files);
          // logo.readAsDataURL(files[0]);
          // console.log(logo);
          // logo.onload = (e:any) => {
          //   setRequest({...request,urlLogo:e.target.result});
          // }
          //setRequest({...request,urlLogo:value});
          break;
        case "pictures":
          // implement upload multiple image base64
          let images:any = [];
          let maxImage = (files.length > 5)? 5: files.length;
          if (files.length > 5) {
            alert("Seulement les 4 premieres images vont être importées ")
          } 
          for (let i = 0; i < maxImage; i++) {
            images.push(files[i]);
          };
          setPicture(images);
          break;
        case "allwayOpen":
          setRequest({...request,allwayOpen:value});
          break;
        default:
          throw new Error("input not allow");
      }
    }

  const getPropVilles = () =>{
      LaboratoireService.getVilles().then(
          (response)=>{
            let villes = response.data.map((elt:any)=>{
              return { name: elt.name, target:"ville", value: elt.id };
            });
              setData(villes);
          }
      ).catch(
          (error: any)=>{
              console.log(error);
          }
      )
  }
  
  const handleChange = (...args: any[]) => {
    // searchInput.current.querySelector("input").value = "";
    console.log("ARGS:", args);
    setVille({ville_id:args[0]})
  };

  const handlerFilter = (items: any[]) => {
    return (searchValue: string) => {
      if (searchValue.length === 0) {
        return items;
      }
      
      const updatedItems = items.filter((item: { name: string; }) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      });
      return updatedItems;
    };
  };

  const handlerSaveData = (e:any) => {
    e.preventDefault();
    //let data = {...request,...ville};
    //let pharmacie: FicheLaboratoireModel = new FicheLaboratoireModel(data);
    if(laboratoireId != undefined){
      LaboratoireService.uploadPicture(logo).then(
        (resp)=>{
          if(resp.status === 200){
              
              LaboratoireService.uploadLotPictures(pictures).then(
                (response)=>{
                  if (response.status === 200) {
                    let urls = ""
                    for (let i = 0; i < response.data.length; i++) {
                      if(urls === ""){
                        urls = urls + response.data[i];
                      }else{
                        urls = urls+ ";" + response.data[i];
                      }
                    } 
                    let data = {...request,...ville, pictures:(urls != null && urls != "")?urls:request.pictures, urlLogo:(resp.data != null && resp.data != "")?resp.data:request.urlLogo,id:laboratoireId};
                    let laboratoire: FicheLaboratoireModel = new FicheLaboratoireModel(data);
                    LaboratoireService.updateLaboratoire(laboratoire).then(
                      (rep)=>{
                        if(rep.status === 200){
                          history("/Dashboard/Laboratoire",{ replace: true })
                        }else{
                          alert("une erreur c'est produite")
                        }
                      }
                    )
                  }
                }
              ).catch(
                (error)=>{
                  console.log(error);
                }
              )
          }
        }
      ).catch((error)=>{
        console.log(error);
      })
    }else{
      LaboratoireService.uploadPicture(logo).then(
        (resp)=>{
          if(resp.status === 200){
              
              LaboratoireService.uploadLotPictures(pictures).then(
                (response)=>{
                  if (response.status === 200) {
                    let urls = ""
                    for (let i = 0; i < response.data.length; i++) {
                      if(urls === ""){
                        urls = urls + response.data[i];
                      }else{
                        urls = urls+ ";" + response.data[i];
                      }
                    }
                    setRequest({...request,pictures:urls});
                    let data = {...request,...ville, pictures:urls, urlLogo:resp.data};
                    let laboratoire: FicheLaboratoireModel = new FicheLaboratoireModel(data);
                    LaboratoireService.saveLaboratoire(laboratoire).then(
                      (rep)=>{
                        if(rep.status === 200){
                          history("/Dashboard/Laboratoire",{ replace: true })
                        }else{
                          alert("une erreur c'est produite")
                        }
                      }
                    )
                  }
                }
              ).catch(
                (error)=>{
                  console.log(error);
                }
              )
          }
        }
      ).catch((error)=>{
        console.log(error);
      })
    }
    
   
  
  }

  function getLaboratoire(){
    LaboratoireService.findLaboratoire(laboratoireId).then(
      (response)=>{
          console.log(response.data);
          setRequest(response.data);
      }
    ).catch(
        (error: any)=>{
            console.log(error)
        }
    )
  }


    
  return (
    <>
        <div className="bg-white py-3 px-6 rounded-md w-full">
          <h1 className="text-2xl border-b-2">Laboratoire</h1>
          <p className="text-sm text-slate-500 mt-2"> {laboratoireId != undefined? "Edition d'un Laboratoire":"Ajouts d'un Laboratoire" } </p>
        </div>

        <form onSubmit={(e:any)=>handlerSaveData(e)} method='POST'>
                    
          <div className="md:grid md:grid-cols-3 md:gap-4 min-w-full bg-white p-3 rounded-md my-3">
            <label className="block">
              <span className="form-label-o">
                Nom du laboratoire
              </span>
              <input type="text" name="name" value={request.name} required className="peer form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Nom du laboratoire" />
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                le nom du laboratoire est obligatoires
              </p>
            </label>

            <label className="block relative">
              <span className="form-label-o">
                Ville
              </span>
              <SelectSearch
                ref={searchInput}
                options={data}
                filterOptions={handlerFilter}
                value={ville?.ville_id || request?.ville_id}
                className="form-control"
                placeholder="Select ville"
                search
                onChange={handleChange}
              />
            </label>

            <label className="block">
              <span className="form-label-o">
                Emplacement
              </span>
              <input type="text" value={request.emplacement} name="emplacement" required className="peer form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Emplacement" />
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                l'emplacement est obligatoires
              </p>
            </label>

            <label className="block">
              <span className="form-label-o">
                Adresse téléphonique 
              </span>
              <input type="number" name="contact" value={request.contact} required className="peer form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Téléphone" />
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                l'adresse téléphonique  est obligatoires
              </p>
            </label>

            <label className="block">
              <span className="form-label">
                Site web
              </span>
              <input type="texte" name="siteWeb" value={request.siteWeb} className="form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Site web" />
            </label>

            <label className="block">
                <span className="form-label">
                    Toujours ouvert ?
                </span>
                <select name="allwayOpen" className="form-control" onChange={(e:any)=>handlerInput(e)} id="">
                    <option selected={laboratoireId != undefined && request.allwayOpen === 0?true :false} value={0}>Non</option>
                    <option selected={laboratoireId != undefined && request.allwayOpen === 1?true :false} value={1}>Oui</option>
                </select>
            </label>

            <label className="block">
              <span className="form-label">
                Heur d'ouverture
              </span>
              <input disabled={request.allwayOpen === 1?true:false} required={request.allwayOpen === 1?false:true} type="time" name="ouverture" value={request.ouverture} className="form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Heur d'ouverture" />
            </label>

            <label className="block">
              <span className="form-label">
                Heur de fermeture
              </span>
              <input type="time" disabled={request.allwayOpen === 1?true:false} required={request.allwayOpen === 1?false:true} name="fermeture" value={request.fermeture } className="form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Heur de fermeture" />
            </label>

            <label className="block">
              <span className="form-label">
                Emplacement detaillé
              </span>
              <textarea name="emplaceDetail" id="" className="form-control" onChange={(e:any)=>handlerInput(e)} cols={10} rows={1} placeholder='Emplacement detaillé'>{request.emplaceDetail}</textarea>
            </label>

            <label className="block">
              <span className="form-label">
                Logo
              </span>
              <input type="file" name="urlLogo" accept=".jpeg,.jpg,.png" className="form-control" onChange={(e:any)=>handlerInput(e)} placeholder="selection du logo" />
            </label>

            <label className="block">
              <span className="form-label">
                Quelque image du laboratoire <small>( max 5 images )</small>
              </span>
              <input type="file" name="pictures" accept=".jpeg,.jpg,.png" multiple className="form-control" onChange={(e:any)=>handlerInput(e)} placeholder="selection du logo" />
            </label>
           
          </div>

          <div className="flex pb-3 justify-end ">
              <button className='rounded-md bg-blue-500 px-2 text-white mx-3 w-32 hover:bg-blue-400' >{laboratoireId != undefined? "Modifier":"Enregistrer" }</button>           
          </div>

      </form>
    </>
  )
}

export {AddLaboratoire}
