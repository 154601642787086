import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { ExamenService } from '../../../../services/examenService';
import { FicheExamenModel } from '../../../../model/ficheExamenModel';

function AddExamen() {
  const[request,setRequest] = useState({allwayOpen:0} as any);
  const {laboratoireId} = useParams();
  const history = useNavigate();

  function handlerInput(e:any){
    const {name, value,files}:any = e.target;

    switch (name) {
      case "noms_des_examens":
        setRequest({...request,noms_des_examens:value});
        break;
      case "prix":
        setRequest({...request,prix:value});
        break;
      case "prelevement":
        setRequest({...request,prelevement:value});
        break;
      case "exigences_particulieres":
        setRequest({...request,exigences_particulieres:value});
        break;
      case "volume_minimal":
        setRequest({...request,volume_minimal:value});
        break;
      case "conditions_de_transport":
        setRequest({...request,conditions_de_transport:value});
        break;
      case "jours_de_reception":
        setRequest({...request,jours_de_reception:value});
        break;
      case "delai_de_rendu":
        setRequest({...request,delai_de_rendu:value});
        break;
      default:
        throw new Error("input not allow");
    }
  }

  const handlerSaveData = (e:any) => {
    e.preventDefault();
    //let data = {...request,...ville};
    let examen: FicheExamenModel = new FicheExamenModel(request);
    ExamenService.saveExamen(laboratoireId,request).then(
      (response)=>{
        if(response.status === 200){
          history("/Dashboard/Laboratoire/"+laboratoireId+"/Examen",{ replace: true })
        }else{
          alert("une erreur c'est produite")
        }
      }
    ).catch(
      (error)=>{
        console.log(error);
      }
    )
    
  
  }

  return (
    <>
        <div className="bg-white py-3 px-6 rounded-md w-full">
          <h1 className="text-2xl border-b-2">Examen</h1>
          <p className="text-sm text-slate-500 mt-2"> Ajouts d'un Examen</p>
        </div>

        <form onSubmit={(e:any)=>handlerSaveData(e)} method='POST'>
                    
          <div className="md:grid md:grid-cols-3 md:gap-4 min-w-full bg-white p-3 rounded-md my-3">
            <label className="block">
              <span className="form-label-o">
                Nom de l'examen
              </span>
              <input type="text" name="noms_des_examens" value={request.noms_des_examens} required className="peer form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Nom de l'examen" />
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                le nom de l'examen est obligatoires
              </p>
            </label>

            <label className="block">
              <span className="form-label-o">
                Prix
              </span>
              <input type="number" min={0} value={request.prix} name="prix" required className="peer form-control" onChange={(e:any)=>handlerInput(e)} placeholder="prix de l'examen" />
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                le prix est obligatoires
              </p>
            </label>

            <label className="block">
              <span className="form-label-o">
                Prélèvement
              </span>
              <input type="text" name="prelevement" value={request.prelevement} required className="peer form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Prélèvement" />
              <p className="mt-1 mx-1 invisible peer-invalid:visible text-pink-600 text-xs">
                Prélèvement téléphonique  est obligatoires
              </p>
            </label>

            <label className="block">
              <span className="form-label">
                exigences particulieres
              </span>
              <input type="text" name="exigences_particulieres" value={request.exigences_particulieres} className="form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Exigence particuliere" />
            </label>

            <label className="block">
              <span className="form-label">
                volume minimal
              </span>
              <input type="number" name="volume_minimal" value={request.volume_minimal} className="form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Volume" />
            </label>

            <label className="block">
              <span className="form-label">
                conditions de transport
              </span>
              <input type="text" name="conditions_de_transport" value={request.conditions_de_transport} className="form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Condition" />
            </label>

            <label className="block">
              <span className="form-label">
                Jour ouvrable
              </span>
              <input type="text" name="jours_de_reception" value={request.jours_de_reception } className="form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Jour ouvrable" />
            </label>

            <label className="block">
              <span className="form-label">
                Delais de rendus
              </span>
              <input type="text" name="delai_de_rendu" value={request.delai_de_rendu } className="form-control" onChange={(e:any)=>handlerInput(e)} placeholder="Delais de rendus" />
            </label>
           
          </div>

          <div className="flex pb-3 justify-end ">
              <button className='rounded-md bg-blue-500 px-2 text-white mx-3 w-32 hover:bg-blue-400' >{laboratoireId != undefined? "Modifier":"Enregistrer" }</button>           
          </div>

      </form>
    </>
  )
}

export {AddExamen};
