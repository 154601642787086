import { FichePharmacieModel } from "../model/fichePharmacieModel";
import { FichePharmacieGardeModel } from "../model/fichePharmacieGardeModel";
import { ApiProvider } from "../providers/apiProvider";


export const PharmacieService = {
    saveLotPharmacie,
    getPharmacies,
    saveLotPharmacieGarde,
    getVilles,
    savePharmacie,
    deletePharmacie,
    deletePharmacieGarde,
    getPharmaciesGardes,
    getPharmacie,
    updatePharmacie
};

async function saveLotPharmacie(pharmacies:FichePharmacieModel[]){
    let data = {pharmacies: pharmacies};
    let res = await ApiProvider.post("saveLotPharmacie",data);
    return res;
}

async function saveLotPharmacieGarde(pharmacies:FichePharmacieGardeModel[]){
    let data = {pharmaciesGarde: pharmacies};
    let res = await ApiProvider.post("saveLotPharmacieGarde",data);
    return res;
}

async function savePharmacie(pharmacies:any){
    let data = pharmacies;
    let res = await ApiProvider.post("savePharmacie",data);
    return res;
}

async function updatePharmacie(pharmacies:any){
    let data = pharmacies;
    let res = await ApiProvider.post("updatePharmacie",data);
    return res;
}

async function getPharmacies(){
    let res = await ApiProvider.get("all-pharma");
    return res;
}

async function getPharmaciesGardes(){
    let res = await ApiProvider.get("all-pharma-garde");
    return res;
}

async function getVilles(){
    let res = await ApiProvider.get("getVille");
    return res;
}

async function deletePharmacie(pharmacieId:any){
    let res = await ApiProvider.get("deletePharmacie/"+pharmacieId);
    return res;
}

async function deletePharmacieGarde(pharmacieGardeId:any){
    console.log(pharmacieGardeId);
    let res = await ApiProvider.get("deletePharmacieGarde/"+pharmacieGardeId);
    return res;
}

async function getPharmacie(pharmacieId:any){
    let res = await ApiProvider.get("find-pharmacie/"+pharmacieId);
    return res;
}