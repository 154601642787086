import React from 'react'

const Statistique = () => {
  return (
    <div>
      Pages des statistiques
    </div>
  )
}

export {Statistique};
