import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import CustomModal from '../../../../components/customModal';
import { ExamenService } from '../../../../services/examenService';
import IExamens from './../../../../interfaces/IExamens';


function AllExamen() {
    const history = useNavigate();
    const[data, setData] = useState([]as any);
    const[isLoad,setLoader]=useState(false);
    const[search,setSearch]=useState("");
    const[config,setConfig]=useState({ "display":"none","defaultType":"examen",} as any);
    const[toogleChange, setToggele] = useState<boolean>(false);

    const {laboratoireId} = useParams();
    console.log(laboratoireId);
    
    function navigate(to:string){
        history(to);
    }

    function openModalInport(){
        let configs = {
            "type":"popup",
            "title":"Importion des examens",
            "action":"IMPORT_EXAMEN",
            "close": ()=>{closeModal()},
            "success": ()=>{reloadMedicament()},
            "display":"",
            "defaultType":"examen",
            "other":{laboratoireId:laboratoireId }
        };
        setConfig(configs);
    }
    
    function reloadMedicament(){
        setToggele((prev:boolean) => prev = !prev)
    }

    function closeModal(){
        console.log(config);
        setConfig({ "display":"none"});
    }

    useEffect(() => {
        console.log('is loader')
        console.log(laboratoireId);
        getExamens();
        // eslint-disable-next-line
    }, [toogleChange]);

    const getExamens = () =>{
        setLoader(true);
        ExamenService.getExaments(laboratoireId).then(
            (response)=>{
                setData(response.data);
                setLoader(false);
            }
        ).catch(
            (error: any)=>{
                console.log(error);
                setLoader(true);
            }
        )
      }
  return (
    <>
       <div className="bg-white p-3 rounded-md w-full">
          <h1 className="text-2xl border-b-2">Examen</h1>
          <p className="text-sm text-slate-500 mt-2">Listes des Examen disponibles</p>
      </div>

      {/* barre de recherche et filtre */}
      <div className="flex flex-wrap justify-between min-w-full bg-white p-3 rounded-md my-3">
          <div className="flex items-center border-2 hover:border-blue-500 hover:text-blue-500 focus-within:border-blue-500 rounded-md focus-within:text-blue-500 text-slate-400 my-1 md:w-auto w-full">
              <input type="text" className="form-control-search rounded-md mt-0 text-slate-400 w-full " onChange={(e)=>setSearch(e.target.value)}  placeholder="Rechecher d'un examen" id="" />
              <button className="px-2 ">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
              </button>
          </div>  

          <div className="flex justify-between my-1 md:w-auto w-full">
              <button className="px-2 " title="Tri en croissant">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
                  </svg>
              </button>
              <button className="px-2 " title="Tri en décroissant">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
                  </svg>
              </button>
              <button className="px-2 " title="Importer une liste d'examen" onClick={()=>openModalInport()}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                  </svg>
              </button>
              <button className="px-2 " title="Ajouter un medicament" onClick={()=>navigate("Add")}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
              </button>
          </div>              
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">

          <table className="w-full text-sm text-left">
              <thead className="bg-slate-400 text-slate-50 text-xs uppercase border-b border-gray-400">
                  <tr>
                      <th scope="col" className="px-6 py-3">
                          Laboratoire
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Nom des Examens
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Prix
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Prélevement   
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Exigence
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Volume
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Condition
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Jour ouvrable
                      </th>
                      <th scope="col" className="px-6 py-3">
                          Delais de rendus
                      </th>
                      <th scope="col" className="px-6 py-3">
                         
                      </th>
                  </tr>
              </thead>
              <tbody>
               {
                    isLoad ?
                    <tr>
                        <th scope="row" colSpan={9} className="px-6 py-4 w-full font-medium text-gray-900 whitespace-nowrap text-center  ">
                            
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-blue-400 animate-spin inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                            
                            <p className="mt-3">Chargement des examens ...</p>
                        </th>
                    </tr>   :

                    data.length == 0 ?
                    <tr>
                        <th scope="row" colSpan={9} className="px-6 py-4 w-full font-medium text-gray-900 whitespace-nowrap text-center  ">
                            
                            {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-blue-400 animate-spin inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg> */}
                            
                            <p className="mt-3">Aucun examen pour ce laboratoire</p>
                        </th>
                    </tr>  
                    :

                    data.filter((Examen: IExamens)=>Examen.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())).map((Examen: IExamens)=> {
                        return (<tr className="bg-white border-b border-gray-200 w-full">
                        <td className="px-6 py-4">
                            {Examen.laboratoire}
                        </td>
                        <td className="px-6 py-4 d-flex">
                            {Examen.name}
                        </td>
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                            {Examen.prix} 
                        </th>
                        <td className="px-6 py-4">
                            {(Examen.prelevement)? Examen.prelevement:"/"}
                        </td>
                        <td className="px-6 py-4">
                            {(Examen.exigence)? Examen.exigence:"/"}
                            
                        </td>
                        <td className="px-6 py-4">
                            {(Examen.volume)? Examen.volume:"/"}
                            
                        </td>
                        <td className="px-6 py-4">
                            {(Examen.condition)? Examen.condition:"/"}
                           
                        </td>
                        <td className="px-6 py-4">
                            {(Examen.jour_ouvrable)? Examen.jour_ouvrable:"/"}
                            
                        </td>
                        <td className="px-6 py-4">
                            {(Examen.delais_rendus)? Examen.delais_rendus:"/"}
                        </td>
                        <td className="px-6 py-4 text-right">
                            <button type="button" onClick={() =>{}} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Modifier</button>
                            <button type="button" onClick={() =>{}} className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Supprimer</button>
                        </td>
                        </tr>);

                    
                  })
                  
                }
                
              </tbody>
          </table>
          
        </div>
        <CustomModal Classe='lg:w-1/4 w-3/4' config={config}/>   
    </>
  )
}

export {AllExamen};
