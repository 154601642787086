

class FicheLaboratoireModel{

    LaboratoireId: number | null;
    name: string;
    emplacement: string;
    contact: string;
    siteWeb : string;
    urlLogo: string;
    emplaceDetail: string;
    allwayOpen: number;
    ouverture: string;
    fermeture: string;
    pictures: string;
    ville_id: number;
   

    constructor(data?:any){
        this.LaboratoireId = data?.id;
        this.name = data?.name;
        this.emplacement = data?.emplacement;
        this.contact = data?.contact;
        this.siteWeb = data?.siteWeb ;
        this.urlLogo = data?.urlLogo;
        this.emplaceDetail = data?.emplaceDetail;
        this.allwayOpen = data?.allwayOpen;
        this.ouverture = data?.ouverture;
        this.fermeture = data?.fermeture;
        this.pictures = data?.pictures;
        this.ville_id = data?.ville_id;
    }
}

export{FicheLaboratoireModel}