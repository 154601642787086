import { FicheLaboratoireModel } from "../model/ficheLaboratoireModel";
import { ApiProvider } from "../providers/apiProvider";


export const LaboratoireService ={
    saveLaboratoire,
    getVilles,
    getLaboratoires,
    uploadLotPictures,
    uploadPicture,
    findLaboratoire,
    updateLaboratoire,
    deleteLaboratoire
};

async function saveLaboratoire(laboratoire:FicheLaboratoireModel){
    let data = laboratoire;
    let res = await ApiProvider.post("saveLaboratoire",data);
    return res;
}

async function uploadLotPictures(pictures: any[]){
    let data = pictures;
    let formData = new FormData();
    data.forEach((elt : any,i:number) => {
        formData.append(`files[]`,elt);
    });
    let res = await ApiProvider.upload("saveLotPictures",formData);
    return res;
}

async function uploadPicture(picture: any){
    let data = picture;
    let formData = new FormData();
    formData.append(`file`,picture);
    
    let res = await ApiProvider.upload("savePicture",formData);
    return res;
}

async function getLaboratoires(){
    let res = await ApiProvider.get("all-laboratoire");
    return res;
}

async function getVilles(){
    let res = await ApiProvider.get("getVille");
    return res;
}

async function findLaboratoire(laboratoireId:any){
    let res = await ApiProvider.get("getFindLaboratoire/"+laboratoireId);
    return res;
}

async function updateLaboratoire(laboratoire:FicheLaboratoireModel){
    let data = laboratoire;
    let res = await ApiProvider.post("updateLaboratoire",data);
    return res;
}

async function deleteLaboratoire(laboratoireId:any){
    let res = await ApiProvider.get("deleteLaboratoire/"+laboratoireId);
    return res;
}