import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { UserModel } from '../../model/userModel'
import { AuthService } from '../../services/authService'
import { Aside } from './Layouts/Aside'
import { Navbar } from './Layouts/Navbar'
import { useState } from 'react';

const Dashboard = () => {
  const [userData, setUserData] = useState({} as any);

  const history = useNavigate();
  useEffect(() => {
    console.log('Dashboard is loader')
    getUser();
    // eslint-disable-next-line
  }, []);

  const getUser = () =>{
    AuthService.getUser().then(
      (response)=>{
        if(response.status === 200){
          let user:UserModel = new UserModel(response.data.data);
          setUserData(user);
          localStorage.setItem("userData",JSON.stringify(user));
        }
        else{
          localStorage.removeItem("userData");
          localStorage.removeItem("token");
          window.location.reload();
        }
      }
    )
  }


  return (
    <div className='text-slate-700 text-base font-medium bg-slate-100 md:h-screen min-w-full md:flex box-border'>
        <Aside username={userData.username}></Aside>
        <main className='flex-auto md:border-2 md:border-slate-300 md:h-screen md:overflow-auto '>
            <Navbar username={userData.username}></Navbar>
            <div className="py-5 px-5 h-5/6">
                <Outlet ></Outlet>
            </div>
        </main>
        
    </div>
  )
}

export default Dashboard
