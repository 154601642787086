import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import logo from '../../../assets/img/logo.png';
import { AuthService } from '../../../services/authService';

const Navbar = ({username}:any)  => {
  console.log(username);
  const logout = ()=> {
    AuthService.logout().then(
      (response)=>{
        if(response.status === 200){
          window.location.reload();
        }
      }
    )
  }
  return (
    <div className='hidden md:flex items-center justify-end border-b-2 border-slate-300 pb-3 px-5 py-5 sticky top-0 bg-slate-100' style={{zIndex: 1000}}>
        <Link to="" className='flex items-center mx-2' style={{textDecoration: "none"}}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            {/* <img src={logo} className='mx-2' width={40} alt="logo MedIndex" /> */}
            <span>{username}</span>
        </Link>  
        <Link to="" style={{textDecoration: "none"}} onClick={()=>logout()}>
            déconnexion
        </Link>
    </div>
  )
}

export {Navbar};
