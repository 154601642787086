import { FicheMedicamentModel } from "../model/ficheMedicamentModel";
import { ApiProvider } from "../providers/apiProvider";


export const MedicamentService ={
    saveLotMedicament,
    getMedicaments,
    getPropsMedicament,
    saveMedicament,
    deleteMedicament,
    updateMedicament,
    find,
    saveAnnonce,
    getAnnonces,
    deleteAnnonce
};

async function saveLotMedicament(medicaments:FicheMedicamentModel[]){
    let data = {medicaments: medicaments};
    let res = await ApiProvider.post("saveLotMedicament",data);
    return res;
}

async function saveMedicament(medicament:any){
    let res = await ApiProvider.post("saveMedicament",medicament);
    return res;
}

async function saveAnnonce(Annonce: any){
    let res = await ApiProvider.post("save-annonce",Annonce);
    return res;
}

async function updateMedicament(medicament:any) {
    let res = await ApiProvider.post("update-medicament",medicament);
    return res;
}

async function getMedicaments(){
    let res = await ApiProvider.get("all-medicament");
    return res;
}

async function getPropsMedicament(){
    let res = await ApiProvider.get("prop-medicament");
    return res;
}

async function find(id:any){
    let res = await ApiProvider.get("find-medicament/" + id);
    return res;
}

async function deleteMedicament(medicamentId: any) {
    let res = await ApiProvider.get("delete-medicament/" + medicamentId);
    return res;
}

async function getAnnonces() {
    let res = await ApiProvider.get("annonces");
    return res;
}

async function deleteAnnonce(annonceId: any) {
    let res = await ApiProvider.get("delete-annonce/" + annonceId);
    return res;
}