class FichePharmacieModel{

    noms_de_la_pharmacie: string;
    emplacements: string;
    coordonnee : string;
    contact_1: string;
    contact_2: string;
    always_open: string;
    villes: string;
    regions:string;
    start_open: string;
    end_open:string;

    constructor(data?:any){
        this.noms_de_la_pharmacie = data?.noms_de_la_pharmacie;
        this.emplacements = data?.emplacements;
        this.coordonnee = (data?.longitude === "" || data?.latitude === "")? "": data?.latitude+","+data?.longitude;
        this.contact_1 = data?.contact_1;
        this.contact_2 = data?.contact_2;
        this.always_open = data?.always_open;
        this.villes = data?.villes;
        this.regions = data?.regions
        this.start_open = data?.heur_d_ouverture
        this.end_open = data?.heur_de_fermeture
    }
}

export{FichePharmacieModel}