import React from 'react';
import {Link} from "react-router-dom";
import logo from '../../../assets/img/logo.png';
import {useLocation} from "react-router-dom"
import { AuthService } from '../../../services/authService';

const Aside = ({username}:any) => {

  function toogleMenu(){
    let menu = document.querySelector("#menu");
    if(menu?.classList.contains('hidden')){
        menu.classList.remove('hidden')
    }else{
        menu?.classList.add('hidden')
    }
  }

  const location = useLocation();

  const logout = ()=> {
    AuthService.logout().then(
      (response)=>{
        if(response.status === 200){
          window.location.reload();
        }
      }
    )
  }

  return (
    <div className='md:h-screen inset-y-0 bg-slate-200 p-4 sticky top-0' style={{zIndex: 1000}}>
      <nav>
          <div className='md:mb-6 md:border-b-2 md:border-slate-100 md:pb-4 flex justify-between'>
            <div className="px-4 cursor-pointer md:hidden" onClick={()=>toogleMenu()}>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h8m-8 6h16" />
                </svg>
            </div>
            <Link to="" style={{textDecoration: "none"}} className="flex flex-row ">
                <img src={logo} className="mx-2 w-6 h-6 md:w-10 md:h-10 " alt="logo MedIndex " />
                <h2 className=" text-xl md:text-2xl mx-2"> Medindex </h2>
            </Link>          
          </div>
          <ul className='hidden md:block md:border-none md:mt-0 p-5 border-t-2 mt-3 border-slate-100 ' id='menu'>
              <li className={`link-menu${location.pathname == "/Dashboard"? "-active":""} my-3`}>
                <Link to="/" style={{textDecoration: "none"}} className="flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                    <span>Accueil</span>
                </Link>  
              </li>

              <li className={`link-menu${(location.pathname == "/Dashboard/Medicament" ||location.pathname == "/Dashboard/Medicament/Add" )? "-active":""} my-3`}>
                <Link to="Medicament" style={{textDecoration: "none"}} className="flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <span>Medicament</span>
                </Link>  
              </li>

              <li className={`link-menu${(location.pathname == "/Dashboard/Annonce" ||location.pathname == "/Dashboard/Annonce/Add" )? "-active":""} my-3`}>
                <Link to="Annonce" style={{textDecoration: "none"}} className="flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <span>Annonce</span>
                </Link>  
              </li>

              <li className={`link-menu${(location.pathname == "/Dashboard/Pharmacie" ||location.pathname == "/Dashboard/Pharmacie/Add" )? "-active":""} my-3`}>
                <Link to="Pharmacie" style={{textDecoration: "none"}} className="flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
                    </svg>

                    <span>Pharmacie</span>
                </Link>  
              </li>

              <li className={`link-menu${(location.pathname == "/Dashboard/Laboratoire" ||location.pathname == "/Dashboard/Laboratoire/Add" )? "-active":""} my-3`}>
                <Link to="Laboratoire" style={{textDecoration: "none"}} className="flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                    </svg>

                    <span>Laboratoire</span>
                </Link>  
              </li>

              <li className={`link-menu${(location.pathname == "/Dashboard/Pharmaco-vigilance")? "-active":""} my-3`}>
                <Link to="Pharmaco-vigilance" style={{textDecoration: "none"}} className="flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>

                    <span>Pharmaco&nbsp;vigilance</span>
                </Link>  
              </li>

              <li className={`link-menu${(location.pathname == "/Dashboard/Notifications")? "-active":""} my-3`}>
                <Link to="Notifications" style={{textDecoration: "none"}} className="flex flex-row">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                  </svg>

                    <span>Notifications</span>
                </Link>  
              </li>

              <li className={`link-menu${(location.pathname == "/Dashboard/Personnel" )? "-active":""} my-3`}>
                <Link to="Personnel" style={{textDecoration: "none"}} className="flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>

                    <span>Personnel</span>
                </Link>  
              </li>

              <li className='md:hidden flex justify-between'>
                <span>{username}</span>

                <Link to="" style={{textDecoration: "none"}} onClick={()=>logout()}>
                    déconnexion
                </Link>
              </li>
          </ul>
      </nav>
    </div>
  )
}

export {Aside}
